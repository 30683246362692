import React, {useCallback} from "react";

import {useThreeSixtyProps, useThreeSixtyTranslation,} from "../App/ThreeSixtyContext";
import {DomeThumbnailContainer} from "./DomeThumbnailContainer";
import useDeepCompareEffect from "use-deep-compare-effect";
import {SidebarContainer} from "./SidebarContainer";
import {AddButton} from "./AddButton";
import {addDomes} from "../App/service";
import {useDragDropHandlers} from "./useDragDropHandlers";


export const Domes = ({domes}) => {
    const t = useThreeSixtyTranslation();
    const [domesInternal, setDomesInternal] = React.useState([]);
    const {onMediaSelect, data, onChange, adminMode} = useThreeSixtyProps()

    const handleChange = newDomes => {
        onChange({
            ...data,
            domes: newDomes
        })
    }

    const {
        moveHandler,
        dropHandler,
        resetHandler
    } = useDragDropHandlers({
        internalState: domesInternal,
        setInternalState: setDomesInternal,
        currentState: domes,
        onChange: handleChange
    })

    const handleAddImage = () => {
        const highestDomeId = Math.max(...data?.domes.map(d => d.id))
        if (onMediaSelect) {
            onMediaSelect(
                "image",
                (images) => {
                    const newDomes = images.map(({dmsId, alt}, i) => ({
                        id: highestDomeId ? highestDomeId + 1 + i : 1,
                        order: highestDomeId ? highestDomeId + 1 + i : 1,
                        image: dmsId,
                        viewDirection: {
                            x: 0,
                            y: 0,
                            z: 9.9,
                        },
                        title: alt,
                        hotspots: [],
                    }));
                    onChange(addDomes(data, newDomes));
                },
                "multiple"
            );
        } else {
            onChange(
                addDomes(data, [
                    {
                        id: highestDomeId ? highestDomeId + 1 : 1,
                        order: highestDomeId ? highestDomeId + 1 : 1,
                        image: "013AitwxjPKA",
                        viewDirection: {
                            x: 0,
                            y: 0,
                            z: 9.9,
                        },
                        hotspots: [],
                    },
                ])
            );
            console.warn("onMediaSelect is undefined");
        }
    }

    useDeepCompareEffect(() => {
        setDomesInternal(domes.sort((a, b) => a.order - b.order));
    }, [domes]);

    const renderDome = useCallback(
        (dome, idx) => {
            return (
                <DomeThumbnailContainer
                    index={idx}
                    moveCard={moveHandler}
                    dropCard={dropHandler}
                    key={dome.id}
                    totalDomes={domesInternal.length}
                    dome={dome}
                    resetCards={resetHandler}
                    adminMode={adminMode}
                />
            );
        },
        [domesInternal]
    );

    return (
        <SidebarContainer>
            {domesInternal.map((dome, idx) => renderDome(dome, idx))}
            <AddButton
                onClick={handleAddImage}
                show={adminMode}
            >
                {t("addDome", "Ny 360 Bilde")}
            </AddButton>
        </SidebarContainer>
    );
};
