import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Checkbox, FormControlLabel, FormGroup, IconButton, Stack,
  Typography,
    Tooltip
} from "@mui/material";
import { updateDome } from "../../App/service";
import {
  useThreeSixtyProps,
  useThreeSixtyTranslation,
} from "../../App/ThreeSixtyContext";
import { TextField } from "../../components/TextField";
import {HelpOutline, ExpandMore} from "@mui/icons-material";

export const DomeSettings = ({ dome, data }) => {
  const { title, forceViewDirection } = dome;
  const [isExpanded, setIsExpanded] = useState(true)
  const t = useThreeSixtyTranslation()
  const { onChange } = useThreeSixtyProps();

  const onExpand = (event, isExpanded) => {
    setIsExpanded(isExpanded);
  };

  const update = (key, value) => {
    onChange(
      updateDome(data, dome.id, {
        [key]: value,
      })
    );
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={isExpanded}
      onChange={onExpand}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t("domeSettingsTitle", "Bildeinstillinger")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            id="dome-title-field"
            label={t("domeTitle", "Tittel")}
            variant="filled"
            value={title}
            onChange={(value) => update("title", value)}
            sx={{ margin: "10px 0", width: "100%" }}
          />
        </Box>
        {/*<Stack*/}
        {/*    direction={"row"}*/}
        {/*    justifyContent={"flex-start"}*/}
        {/*    alignItems={"center"}*/}
        {/*>*/}
        {/*  <FormGroup>*/}
        {/*    <FormControlLabel*/}
        {/*        control={*/}
        {/*          <Checkbox*/}
        {/*              checked={forceViewDirection || false}*/}
        {/*              onChange={(e) => update("forceViewDirection", e.target.checked)}*/}
        {/*              inputProps={{ "aria-label": "controlled" }}*/}
        {/*          />*/}
        {/*        }*/}
        {/*        label={t('overrideViewDirectionCheckboxLabel', "Overstyr synsvinkel med Startretning")}*/}
        {/*    />*/}
        {/*  </FormGroup>*/}
        {/*  <Tooltip*/}
        {/*      title={t(*/}
        {/*          'overrideViewDirectionTooltip',*/}
        {/*          "Med dette valget aktivert vil brukeren se mot Startretning ved ankomst, uavhengig av synsvinkelen vedkommende hadde i forrige bilde. Nyttig for eksempel hvis du ønsker å gi brukeren opplevelse av å sitte i en stol."*/}
        {/*      )}*/}
        {/*      placement={"bottom-start"}*/}
        {/*      arrow={true}*/}
        {/*  >*/}
        {/*    <IconButton*/}
        {/*        size={"small"}*/}
        {/*    >*/}
        {/*      <HelpOutline />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*</Stack>*/}
      </AccordionDetails>
    </Accordion>
  )
}
