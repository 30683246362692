const vertexShader = `
varying vec2 vUv;

    void main() {
      mat4 scalePosition = mat4(vec4(1.0,0.0,0.0,0.0), vec4(0.0,1.0,0.0,0.0), vec4(0.0,0.0,-1.0,0.0), vec4(0.0,0.0,0.0,1.0));
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * scalePosition * vec4( position, 1.0 );
    }`;

const fragmentShader = `
varying vec2 vUv;
uniform sampler2D fromTex;
uniform sampler2D toTex;
uniform float mixFactor;

void main() {
    vec4 _texture = texture2D(fromTex, vUv);
    vec4 _texture2 = texture2D(toTex, vUv);
    vec4 finalTexture = mix(_texture, _texture2, mixFactor);
    gl_FragColor = finalTexture;
}`;



export { vertexShader, fragmentShader };
