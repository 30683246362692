import React, { useState } from "react";
import { IconButton, Slider, Stack } from "@mui/material";
import { VolumeDown, VolumeUp } from "@mui/icons-material";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  volumeControlWrapper: {
    position: "relative",
  },
  volumeControls: {
    position: "absolute",
    height: "165px",
    backgroundColor: "#ffffff",
    bottom: "40px",
    width: "40px",
  },
});

export const VolumeControls = ({ value, onChange }) => {
  const [showVolume, setShowVolume] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.volumeControlWrapper}>
      <IconButton
        onClick={() => setShowVolume(!showVolume)}
        sx={{ width: "32px", height: "32px" }}
      >
        <VolumeUp />
      </IconButton>

      {showVolume && (
        <div className={classes.volumeControls}>
          <Stack
            spacing={2}
            direction="column"
            sx={{
              mb: 1,
              display: "flex",
              flexDirection: "column",
              color: "#000000",
            }}
            alignItems="center"
          >
            <VolumeUp />
            <Slider
              sx={{ height: 80 }}
              aria-label="Volume"
              value={value}
              orientation="vertical"
              onChange={(e, value) => onChange(value)}
              min={0}
              max={1}
              step={0.01}
            />
            <VolumeDown />
          </Stack>
        </div>
      )}
    </div>
  );
};
