import React from "react";
import { Slider } from "@mui/material";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  slider: {
    display: "flex",
    position: "relative",
    width: "100%",
    padding: "0 10px",
    marginLeft: "18px",
  },
  currentTime: {
    position: "absolute",
    left: "-5px",
    top: "20px",
    fontSize: "10px",
  },
  timeLeft: {
    position: "absolute",
    right: "10px",
    top: "20px",
    fontSize: "10px",
  },
});

const formatTime = (time) => {
    if (!time) {
        return;
    }
    // Cleanse time input depending on in seconds or full format
    if (time.length >= 7) {
        if (time[1] === "0") {
            return time.slice(3);
        } else if (time[0] === "0") {
            return time.slice(1);
        }
    } else {
        if (isNaN(time)) {
            return "-:--";
        }
        let hours = Math.floor(time / 3600);
        let minutes = Math.floor((time - hours * 3600) / 60);
        let seconds = Math.floor(time % 60);

        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        if (hours > 0) {
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return hours + ":" + minutes + ":" + seconds;
        } else {
            return minutes + ":" + seconds;
        }
    }
}


export const ProgressSlider = ({ currentTime, duration, onChangeTime }) => {
  const classes = useStyles();
  const valueLabelFormat = (value) => formatTime(value * duration);
  return (
    <div className={classes.slider}>
      <div className={classes.currentTime}>
        {formatTime(currentTime) || "0:00"}
      </div>
      <Slider
        size="small"
        value={currentTime / duration || 0}
        aria-label="Small"
        valueLabelDisplay="auto"
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        max={1}
        min={0}
        step={0.01}
        onChange={(e, val) => onChangeTime(val)}
      />
      <div className={classes.timeLeft}>
        {formatTime(duration - currentTime) || "-:--"}
      </div>
    </div>
  );
};
