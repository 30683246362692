import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import {
  Pause,
  Play,
  RotateCcw,
  RotateCw,
  SkipBack,
  SkipForward,
} from "react-feather";
import { VolumeControls } from "./VolumeControls";
import { createUseStyles } from "react-jss";
import { ProgressSlider } from "./ProgressSlider";
import {useAudioState} from "./audioContext";
const useStyles = createUseStyles({
  playerControls: { display: "flex", alignItems: "center", flexGrow: 1 },
});

export const PlayerControls = ({
  currentIndex,
  volume,
  episodesCount,
  audioElement,
  onVolumeChange,
  onChangeTime,
  onPlay,
  onPause,
  onSkipBackwards15Seconds,
  onSkipForward15Seconds,
  onPreviousEpisode,
  onNextEpisode,
}) => {
  const classes = useStyles()
  const {playing} = useAudioState()

  return (
    <div className={classes.playerControls}>
      <Tooltip title="Previous episode">
        <span>
          <IconButton
            onClick={() => onPreviousEpisode()}
            disabled={currentIndex <= 0}
            sx={{ width: "32px", height: "32px" }}
          >
            <SkipBack />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Backwards 15 seconds">
        <span>
          <IconButton
            onClick={() => onSkipBackwards15Seconds()}
            sx={{ width: "32px", height: "32px" }}
          >
            <RotateCcw />
          </IconButton>
        </span>
      </Tooltip>
      {!playing ? (
        <Tooltip title="Play">
          <span>
            <IconButton
              onClick={() => onPlay()}
              sx={{ width: "32px", height: "32px" }}
            >
              <Play />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="Pause">
          <span>
            <IconButton
              onClick={() => onPause()}
              sx={{ width: "32px", height: "32px" }}
            >
              <Pause />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <VolumeControls value={volume} onChange={onVolumeChange} />
      <Tooltip title="Forward 15 seconds">
        <span>
          <IconButton
            onClick={() => onSkipForward15Seconds()}
            sx={{ width: "32px", height: "32px" }}
          >
            <RotateCw />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Next episode">
        <span>
          <IconButton
            onClick={() => onNextEpisode()}
            disabled={currentIndex >= episodesCount - 1}
            sx={{ width: "32px", height: "32px" }}
          >
            <SkipForward />
          </IconButton>
        </span>
      </Tooltip>
      <ProgressSlider
        currentTime={audioElement.currentTime}
        duration={audioElement.duration}
        onChangeTime={onChangeTime}
      />
    </div>
  );
};
