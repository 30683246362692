import {useThreeSixtyProps} from "../App/ThreeSixtyContext";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";

export const useCheckDuplicateDomeIds = domes => {
    const {
        onFeedback,
        adminMode
    } = useThreeSixtyProps()

    const hasDuplicates = array => new Set(array).size !== array.length

    useDeepCompareEffectNoCheck(
        () => {
            if(domes && adminMode) {
                const domeIds = domes.map(dome => dome.id)
                if(hasDuplicates(domeIds)) {
                    onFeedback.snackbarWarning({
                        message: "Teknisk problem har ført til at 2 eller flere domes har fått samme id. Kontakt support!"
                    })
                }
            }
        }, [domes, adminMode]
    )
}