import {useEffect, useState} from "react";
import {ImageBitmapLoader} from "three";


export const useImageBitmapLoader = () => {
    const [loader, setLoader] = useState(null)

    useEffect(
        () => {
            let bitmapLoader = new ImageBitmapLoader();
            bitmapLoader.setOptions({
                imageOrientation: 'flipY',
            })

            setLoader(bitmapLoader)
        }, []
    )

    return loader
}