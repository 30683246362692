import {ThreeSixtyIcon} from "../components/icons/ThreeSixtyIcon";
import {Fab, Typography} from "@mui/material";
import React from "react";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";
import {addDomes} from "../App/service";
import {Add} from "@mui/icons-material";


export const NoDomesPlaceholder = () => {
    const t = useThreeSixtyTranslation()
    const {onChange, data, onMediaSelect} = useThreeSixtyProps()

    const handleAddImage = () => {
        const length = data?.domes.length;
        if (onMediaSelect) {
            onMediaSelect(
                "image",
                (images) => {
                    const domes = images.map(({dmsId, alt}, i) => ({
                        id: length ? length + 1 + i : i + 1,
                        order: length ? length + 1 + i : i + 1,
                        image: dmsId,
                        viewDirection: {
                            x: 0,
                            y: 0,
                            z: 9.9,
                        },
                        title: alt,
                        hotspots: [],
                    }));
                    onChange(addDomes(data, domes));
                },
                "multiple"
            );
        } else {
            onChange(
                addDomes(data, [
                    {
                        id: length ? length + 1 : 1,
                        order: length ? length + 1 : 1,
                        image: "013AitwxjPKA",
                        viewDirection: {
                            x: 0,
                            y: 0,
                            z: 9.9,
                        },
                        hotspots: [],
                    },
                ])
            );
            console.warn("onMediaSelect is undefined");
        }
    }

    return (
        <>
                <ThreeSixtyIcon/>
                <Typography variant="h5" sx={{marginTop: "30px"}}>
                    {t("emptyContentTitle", "Lag din 360-opplevelse")}
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{
                        margin: "9px 0 26px",
                        color: "#00000099",
                        textAlign: "center",
                    }}
                >
                    {t(
                        "emptyContentText",
                        "Begynn ved å laste opp og velge 360-bilder fra mediebiblioteket"
                    )}
                </Typography>
                <Fab
                    variant="extended"
                    size="large"
                    color="primary"
                    onClick={handleAddImage}
                >
                    <Add/>
                    {t("emptyContentButtonText", "Legg til bilder")}
                </Fab>
        </>
    )
}