import { createUseStyles } from "react-jss";
import { theme } from "@ekultur/vm";

const useStyles = createUseStyles({
  bar: {
    height: "8px",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    borderRadius: "72px",
    backgroundColor: `${theme.colors.black}1A`,
    "& > div": {
      top: 0,
      left: 0,
      bottom: 0,
      width: "100%",
      position: "absolute",
      borderRadius: "72px",
      backgroundColor: `${theme.colors.black}80`,
      transformOrigin: "left",
      transition: "transform .4s linear",
    },
  },
});

export const ProgressBar = ({ progress }) => {
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.bar}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={progress}
      >
        <div style={{ transform: `translateX(-${100 - progress}%)` }} />
      </div>
    </>
  );
};
