import {useGesture} from "@use-gesture/react";
import {useFrame} from "@react-three/fiber";
import {useEffect, useState} from "react";

export const useZoom = ({adminMode, resetZoom}) => {
    const [zoom, setZoom] = useState(1)
    const gesturesBind = useGesture({
        onWheel: state => handleWheel(state),
        onPinch: state => handlePinch(state)
    })

    useEffect(
        () => {
            setZoom(1)
        }, [resetZoom]
    )

    const calculateZoom = (deltaY) => {
        if (deltaY > 0) {
            return zoom - 0.025;
        } else if (deltaY < 0) {
            return zoom + 0.025;
        } else {
            return zoom;
        }
    };
    const handleWheel = ({delta}) => {
        if(!adminMode) {
            const newZoom = calculateZoom(delta[1]);
            if (newZoom <= 2 && newZoom >= 0.75) {
                setZoom(newZoom);
            }
        }
    }

    const handlePinch = ({delta}) => {
        if(!adminMode && delta[0] !== 0) {
            const newZoom = calculateZoom(delta[0] * -1);
            if (newZoom <= 2 && newZoom >= 0.75) {
                setZoom(newZoom);
            }
        }
    }

    useFrame(({ camera }) => {
        camera.zoom = zoom;
        camera.updateProjectionMatrix();
    })

    return gesturesBind
}