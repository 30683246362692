import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useThree} from "@react-three/fiber";

import {NavigationIcon} from "../components/icons/NavigationIcon";
import {PortalTypes} from "../helpers/enums";
import {PortalHelper} from "./PortalHelper";
import useDeepCompareEffect from "use-deep-compare-effect";
import {PortalMesh} from "./PortalMesh";
import {AdminPortalMesh} from "./AdminPortalMesh";
import {PortalLabel} from "./PortalLabel";
import {PortalDestination} from "./PortalDestination";

export const Portal = forwardRef(
    ({portal, onChange, isAdminMode, onPortalClick, opacity, iconId, iconRotation = 0}, domeRef) => {
        const {id, position, size, portalType, domeId, color, showLabel, label} =
            portal;
        const portalRef = useRef(null);
        const {camera} = useThree();

        const [isDragging, setIsDragging] = useState(false);
        const [dragPosition, setDragPosition] = useState(position);

        useEffect(() => {
            setDragPosition(position);
        }, [position]);

        useDeepCompareEffect(() => {
            if (portalRef?.current && portalType === PortalTypes.WALL) {
                portalRef?.current.lookAt(camera.position.clone().normalize());
            }
        }, [dragPosition, portalType, camera.position]);

        const portalClickHandler = event => {
            event.stopPropagation()
            onPortalClick(domeId)
        }

        if(isAdminMode) {
            return (
                <AdminPortalMesh
                    portalRef={portalRef}
                    name={`Portal-${id}`}
                    position={position}
                    isDragging={isDragging}
                    setIsDragging={setIsDragging}
                    onChange={onChange}
                    ref={domeRef}
                    portalType={portalType}
                    id={id}
                >
                    <PortalDestination
                        scale={size}
                        destinationDomeId={portal.domeId}
                    />
                    <NavigationIcon
                        scale={size}
                        hoverEffect={!isDragging}
                        portalType={portalType}
                        color={color}
                        iconId={iconId}
                        iconRotation={iconRotation}
                    />
                    <PortalLabel
                        showLabel={showLabel}
                        scale={size}
                    >
                        {label}
                    </PortalLabel>
                    <PortalHelper
                        portalType={portalType}
                        show={isDragging}
                    />
                </AdminPortalMesh>
            )
        } else {
            return (
                <PortalMesh
                    portalRef={portalRef}
                    name={`Portal-${id}`}
                    position={position}
                    onClick={portalClickHandler}
                >
                    <NavigationIcon
                        scale={size}
                        hoverEffect={!isDragging}
                        portalType={portalType}
                        color={color}
                        iconId={iconId}
                        iconRotation={iconRotation}
                    />
                    <PortalLabel
                        showLabel={showLabel}
                        scale={size}
                    >
                        {label}
                    </PortalLabel>
                    <PortalHelper
                        portalType={portalType}
                        show={isDragging}
                    />
                </PortalMesh>
            )
        }
    }
);
