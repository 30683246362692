import {createContext, useContext, useReducer} from "react";

const TabStateContext = createContext(undefined)
const TabDispatchContext = createContext(undefined)

export const TAB_CHANGED = "tabContext/tabChanged";

const initialState = {
    tab: 0
}

export const tabReducer = (state, action) => {
    console.debug({action})
    switch (action.type) {
        case TAB_CHANGED:
            return {
                ...state,
                tab: action.tab
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const TabProvider = ({children}) => {
    const [state, dispatch] = useReducer(tabReducer, initialState, undefined)
    return (
        <TabStateContext.Provider value={state}>
            <TabDispatchContext.Provider value={dispatch}>
                {children}
            </TabDispatchContext.Provider>
        </TabStateContext.Provider>
    )
}

export const useTabState = () => {
    const context = useContext(TabStateContext)

    if (undefined === context) {
        throw new Error("useTabState must be used within a TabProvider")
    } else {
        return context
    }
}
export const useTabDispatch = () => {
    const context = useContext(TabDispatchContext)

    if (undefined === context) {
        throw new Error("useTabDispatch must be used within a TabProvider")
    } else {
        return context
    }
}