import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {IconButton, Paper, Slide, Tooltip} from "@mui/material";
import {List} from "react-feather";

import {Episode} from "./Episode";

const useStyles = createUseStyles({
    episodeControlsWrapper: {
        display: "flex",
        alignItems: "center"
    },
});

export const EpisodeControls = ({items, onEpisodeChange}) => {
    const [showEpisodeList, setShowEpisodeList] = useState(false);
    const classes = useStyles();
    return (
        <div className={classes.episodeControlsWrapper}>
            <Tooltip title={`${items?.length}`}>
                <IconButton
                    onClick={() => setShowEpisodeList(!showEpisodeList)}
                    sx={{width: "32px", height: "32px"}}
                >
                    <List/>
                </IconButton>
            </Tooltip>
            <Slide
                in={showEpisodeList}
                direction={"up"}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <Paper
                    sx={{
                        position: "absolute",
                        bottom: "56px",
                        color: "#000000",
                        width: {
                            xs: "100dvw",
                            md: "588px"
                        },
                        height: "408px",
                        overflowY: "auto",
                        left: {
                            xs: 0,
                            md: "inherit"
                        }
                    }}
                >
                    {items?.map((item, i) => (
                        <Episode
                            key={item.id}
                            isLast={(1 + item === items.length)}
                            episode={item}
                            onEpisodeChange={(ep) => {
                                onEpisodeChange(ep, i);
                                setShowEpisodeList(false);
                            }}
                        />
                    ))}
                </Paper>
            </Slide>
        </div>
    );
};
