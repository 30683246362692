import React from "react";
import { DoubleSide } from "three";

export const Floor = () => (
  <mesh
    name="Floor"
    scale={[10, 10, 10]}
    position={[0, -0.6, 0]}
    rotation={[Math.PI / 2, 0, 0]}
    visible={false}
  >
    <planeGeometry attach="geometry" args={[10, 10]} />
    <meshBasicMaterial
      attach="material"
      transparent={true}
      opacity={0}
      side={DoubleSide}
    />
  </mesh>
);
