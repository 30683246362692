export const demo1 = {
    settings: {
        colors: [],
    },
    domes: [
        {
            id: 1,
            order: 1,
            title: "Image 1",
            // image: "0136NvASPz4a",
            image: {
                dmsId: "0136NvASPz4a",
                source: "vm",
                alt: "360 bilde",
                sourceId: "",
                type: "image"
            },
            viewDirection: {
                x: -1.705109656498648,
                y: -0.09019278301109437,
                z: -9.837750268049565,
            },
            hotspots: [
                {
                    id: 1,
                    type: "text",
                    position: {
                        x: 1.1166668659985184,
                        y: -0.6445823268706286,
                        z: -9.908306965501843,
                    },
                    title: "Lorem 1",
                    width: 450,
                    content: {
                        image: "0136NvASPz4a",
                        description: "Lorem ipsum dolor sit amet",
                    },
                },
                {
                    id: 2,
                    type: "portal",
                    domeId: 2,
                    position: {
                        x: -1.0788633278356654,
                        y: -0.6000000000000006,
                        z: -2.9797499102632976,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
                {
                    id: 3,
                    type: "image",
                    position: {
                        x: 3.1234338113933195,
                        y: -4.21098108260259,
                        z: -8.494119580696431,
                    },
                    title: "Lorem 1",
                    width: 450,
                    content: {
                        media: {
                            dmsId: "0136NvASPz4a",
                            source: "vm",
                            alt: "360 bilde",
                            sourceId: "",
                            type: "image"
                        },
                        description: "Lorem ipsum dolor sit amet",
                    },
                },
                {
                    id: 4,
                    type: "video",
                    videoType: "Dms",
                    position: {
                        x: 3.456556915962922,
                        y: 2.1775814831895195,
                        z: -9.10774785811625,
                    },
                    title: "Lorem 1",
                    width: 450,
                    content: {
                        media: {
                            dmsId: "013AitqNDu7M",
                            source: "vm",
                            alt: "Litt av en video",
                            sourceId: "",
                            type: "video"
                        },
                        description: "Lorem ipsum dolor sit amet",
                    },
                },
                {
                    audioType: "Rss",
                    content: {
                        audio: "",
                        rss: "https://feeds.buzzsprout.com/1913076.rss",
                    },
                    id: 5,
                    order: 5,
                    position: {
                        x: -0.8082662786939512,
                        y: -1.298561907491472,
                        z: -9.86598145060044,
                    },
                    type: "audio",
                },
                // {
                //     audioType: "Dms",
                //     content: {
                //         media: {
                //             dmsId: "019EBtFo2gP1w",
                //             source: "vm",
                //             alt: "hør på denna da",
                //             sourceId: "",
                //             type: "audio"
                //         }
                //     },
                //     id: 5,
                //     order: 5,
                //     position: {
                //         x: -0.8082662786939512,
                //         y: -1.298561907491472,
                //         z: -9.86598145060044,
                //     },
                //     type: "audio",
                // },
            ],
        },
        {
            id: 2,
            order: 2,
            title: "Image 2",
            image: "0136NvASPz92",
            viewDirection: {
                x: 2.9739330277608778, //x: -3.4524185257564532,
                y: 1.3462326323651723, //y: -0.12761934572560937,
                z: 9.441115101626384, //z: -9.36614712798078
            },
            hotspots: [
                {
                    id: 1,
                    type: "text",
                    position: {
                        x: -17.5,
                        y: -2,
                        z: -10.5,
                    },
                    title: "Lorem ipsum dolor sit amet",
                    content: {
                        image: "0136NvASPz92",
                        description:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ullamcorper vehicula nibh, ac semper magna convallis molestie.",
                    },
                },
                {
                    id: 2,
                    type: "text",
                    position: {
                        x: 13,
                        y: -2,
                        z: -15,
                    },
                    title: "Lorem ipsum dolor sit amet",
                    content: {
                        image: "0136NvASPz92",
                        description:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
                    },
                },
                {
                    id: 3,
                    type: "portal",
                    domeId: 1,
                    position: {
                        x: -1.0520915772329529,
                        y: -0.6000000000000008,
                        z: -2.9025677682864273,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
            ],
        },
    ],
}