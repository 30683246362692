import {alpha, useTheme} from "@mui/material";
import {Volume2} from "react-feather";
import React from "react";

export const CoverImage = ({isCurrentEpisode, imageSrc, alt}) => {
    const theme = useTheme()
    if(imageSrc) {
        return (
            <img
                src={imageSrc}
                alt={alt}
                style={{
                    width: 80,
                    height: 80,
                    objectFit: "cover",
                    borderRadius: "6px",
                    opacity: isCurrentEpisode ? 0.1 : 1
                }}
            />
        )
    } else {
        return (
            <Volume2
                size={32}
                color={alpha(theme.palette.grey[400], isCurrentEpisode ? 0.2 : 1)}
            />
        )
    }
}