import { getDmsUrl } from "@ekultur/dms-url-generator";
import { CursorTypes } from "./enums";

const generateDmsIndex = () => {
  const randNum = Math.floor(Math.random() * 10) + 1;
  return randNum < 10 ? "0" + randNum : randNum;
};

const dmsServerId = generateDmsIndex();

export const getImageDimension = (url, dimension) => {
  return `${url}?dimension=${dimension ? dimension : "1200x1200"}`;
};

export const getScreenResolution = () => {
  const x = window.screen.width * window.devicePixelRatio;
  const y = window.screen.height * window.devicePixelRatio;
  return { x, y };
};

export const getImageByDmsId = (id, dimension) => {
  return getImageDimension(getDmsUrl(id, dmsServerId.toString()), dimension);
};

export const setCursor = (cursorType) => {
  const body = document.getElementById("canvasContainer");

  if (body) {
    body.style.cursor = cursorType;

    switch (cursorType) {
      case CursorTypes.SHOE_PRINT:
        body.style.cursor = `url(${getImageByDmsId(
          "0136NQiVj3D5",
          "167x167"
        )}) 12 12, auto`;
        break;
      case CursorTypes.EYE:
        body.style.cursor = `url(${getImageByDmsId(
          "0136NQiVj3D3",
          "167x167"
        )}) 12 12, auto`;
        break;
      case CursorTypes.PLAY:
        body.style.cursor = `url(${getImageByDmsId(
          "0136NvAQwLQG",
          "167x167"
        )} 12 12, auto`;
        break;
      case CursorTypes.PAUSE:
        body.style.cursor = `url(${getImageByDmsId(
          "0136NvAQwLKq",
          "167x167"
        )} 12 12, auto`;
        break;
      default:
        body.style.cursor = cursorType;
        break;
    }
  }
};

export const hexToRgba = (hex) => {
  if (hex) {
    const r = parseInt(hex.substring(1, 3), 16) || 0;
    const g = parseInt(hex.substring(3, 5), 16) || 0;
    const b = parseInt(hex.substring(5, 7), 16) || 0;
    const a = parseInt(hex.substring(7, 9), 16) || 1;
    return { r, g, b, a };
  } else {
    throw Error("Hex color is undefined!");
  }
};

export const placeholderBackground = (type) => {
  switch (type) {
    case "image": {
      return "013AjtNnCUXw";
    }
    case "video": {
      return "013AjtNogoyu";
    }
    case "audio": {
      return "013AjtNogoyu";
    }
    default:
      return undefined;
  }
};
