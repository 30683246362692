import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { UnfoldMore } from "@mui/icons-material";
import { HexFields } from "./HexFields";
import { RGBFields } from "./RGBFields";
import { HSLFields } from "./HSLFields";

const useStyles = createUseStyles({
  colorFields: {
    padding: "1rem",
    display: "flex",
  },
  fields: {
    flex: "1",
    display: "flex",
    marginLeft: "-6px",
  },
  toggle: {
    width: "32px",
    textAlign: "right",
    position: "relative",
  },
  icon: {
    marginRight: "-4px",
    marginTop: "12px",
    cursor: "pointer",
    position: "relative",
  },
});
const inputStyles = {
  input: {
    fontSize: "11px",
    color: "#333",
    width: "100%",
    borderRadius: "2px",
    border: "none",
    boxShadow: "inset 0 0 0 1px #dadada",
    height: "21px",
    textAlign: "center",
  },
  label: {
    textTransform: "uppercase",
    fontSize: "11px",
    lineHeight: "11px",
    color: "#969696",
    textAlign: "center",
    display: "block",
    marginTop: "12px",
  },
};

export const ColorFields = ({ rgb, hsl, hex, onChange }) => {
  const [view, setView] = useState("hex");

  const toggleView = () => {
    if (view === "hex") {
      setView("rgb");
    } else if (view === "rgb") {
      setView("hsl");
    } else if (view === "hsl") {
      setView("hex");
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.colorFields}>
      <div className={classes.fields}>
        {view === "hex" && (
          <HexFields style={inputStyles} hex={hex} onChange={onChange} />
        )}
        {view === "rgb" && (
          <RGBFields
            style={inputStyles}
            rgb={rgb}
            hsl={hsl}
            onChange={onChange}
          />
        )}
        {view === "hsl" && (
          <HSLFields style={inputStyles} hsl={hsl} onChange={onChange} />
        )}
      </div>
      <div className={classes.toggle}>
        <div className={classes.icon} onClick={toggleView}>
          <UnfoldMore
            sx={{
              fill: "#333",
              width: "24px",
              height: "24px",
              border: "1px transparent solid",
              borderRadius: "5px",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.background = "#eee";
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.background = "#eee";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.background = "transparent";
            }}
          />
        </div>
      </div>
    </div>
  );
};
