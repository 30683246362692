export const demo5 = {
    "domes": [
        {
            "hotspots": [
                {
                    "domeId": 60,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4642088325333213,
                        "y": -0.6000000000000006,
                        "z": -2.1125674333851903
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.4737888247581292,
                        "y": -0.5999999999999999,
                        "z": 0.3956606714624632
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.959293753651681,
                        "y": -0.5999999999999999,
                        "z": 0.33171459087358757
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -8.268759942238523,
                        "y": -0.6000000000000001,
                        "z": -0.2021967392028776
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7072755685045619,
                        "y": -0.6000000000000005,
                        "z": -2.0043737918214113
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.2813151860538372,
                        "y": -0.6000000000000005,
                        "z": -1.9739245451743737
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.868688509194725,
                        "y": -0.6000000000000004,
                        "z": -1.8316933738824273
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.031122578867833,
                        "y": -0.6000000000000004,
                        "z": -2.293680643058796
                    },
                    "showLabel": true,
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 56,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.501364700651576,
                        "y": -0.6000000000000005,
                        "z": -2.503950634469025
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.774134114635365,
                        "y": -0.6000000000000003,
                        "z": -1.6830031445921145
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.3164358217200345,
                        "y": -0.6000000000000003,
                        "z": -1.6399621328155893
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -6.322137427128763,
                        "y": -0.6000000000000003,
                        "z": -1.5345744426611743
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -7.456089212079955,
                        "y": -0.6000000000000003,
                        "z": -1.444543716711917
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 67,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.189756766407889,
                        "y": -0.6,
                        "z": 0.53893765627121
                    },
                    "size": 0.7,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBtjtM3Qec"
                    },
                    "id": 15,
                    "order": 15,
                    "position": {
                        "x": -0.6281663171724652,
                        "y": 2.8218580375969364,
                        "z": 9.553316437729984
                    },
                    "showLabel": true,
                    "title": "Postkort fra gamle \"Sörumsanden Station\" ",
                    "type": "image"
                }
            ],
            "id": 65,
            "image": "019EBskgh6GMe",
            "modified": true,
            "order": 1,
            "title": "Tertitten-65.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 3,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.610130303564647,
                        "y": -0.6000000000000003,
                        "z": -1.6387776391457156
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 6,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.3450777227344333,
                        "y": -0.5999999999999999,
                        "z": 0.006097671833561733
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 7,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.26793950350031004,
                        "y": -0.5999999999999995,
                        "z": 2.3095787714227907
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 54,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.3510448934977226,
                        "y": -0.5999999999999991,
                        "z": 3.711557438819165
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 58,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.4370468845091694,
                        "y": -0.5999999999999986,
                        "z": 5.099328389780967
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.358412190581256,
                        "y": -0.5999999999999988,
                        "z": 5.414789178621878
                    },
                    "size": 0.7,
                    "type": "portal"
                }
            ],
            "id": 5,
            "image": "019EBskTT8gJh",
            "modified": true,
            "order": 2,
            "title": "Tertitten-2.jpg",
            "viewDirection": {
                "x": -9.828422537540305,
                "y": -0.15152692131169326,
                "z": -1.7447030192690924
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 5,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.9778253895674167,
                        "y": -0.5999999999999998,
                        "z": 0.8905490449249494
                    },
                    "size": 0.3,
                    "type": "portal"
                }
            ],
            "id": 3,
            "image": "013Amsnn733M",
            "modified": true,
            "order": 3,
            "title": "Tertitten_1.jpg",
            "viewDirection": {
                "x": -2.0747379125117913,
                "y": -0.40640707270059356,
                "z": 9.766024786438832
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 5,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.184208777794302,
                        "y": -0.6000000000000001,
                        "z": -0.07412609881538297
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.12813246454083416,
                        "y": -0.6000000000000002,
                        "z": -1.0590801476336738
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.562429934760001,
                        "y": -0.6,
                        "z": 0.05668552197087171
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.460434354794384,
                        "y": -0.6,
                        "z": 0.08040141646073502
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.901609894363169,
                        "y": -0.6,
                        "z": 0.13166409368873377
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 54,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7160855627102412,
                        "y": -0.6,
                        "z": -0.005551451734989442
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 58,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.2937324237549186,
                        "y": -0.6,
                        "z": 0.02698308228250564
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.9496694746577514,
                        "y": -0.5999999999999994,
                        "z": 2.9798028266642334
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.88632659744201,
                        "y": -0.5999999999999993,
                        "z": 2.603674828258164
                    },
                    "size": 0.6,
                    "type": "portal"
                }
            ],
            "id": 7,
            "image": "019EBskTT8gJj",
            "order": 4,
            "title": "Tertitten-5.jpg",
            "viewDirection": {
                "x": -0.87953985608254,
                "y": -0.7389177863396716,
                "z": 9.924873217197517
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 7,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.058650542856184915,
                        "y": -0.6,
                        "z": 0.511919204053512
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5328907307868133,
                        "y": -0.6,
                        "z": 0.06785204615766673
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0544751269744062,
                        "y": -0.6,
                        "z": 0.11366934951776751
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7689843828945009,
                        "y": -0.6,
                        "z": 0.18133197415764246
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 12,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.3277479762992,
                        "y": -0.6,
                        "z": 0.2275530422205422
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 13,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.0046099250931584,
                        "y": -0.5999999999999999,
                        "z": 0.30875711233902375
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.4995866491324485,
                        "y": -0.5999999999999998,
                        "z": 0.35841203717528297
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.893263306981835,
                        "y": -0.5999999999999998,
                        "z": 0.39790477270389224
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 8,
            "image": "019EBskTT8gJk",
            "order": 5,
            "title": "Tertitten-6.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 5,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.3179696817727705,
                        "y": -0.6,
                        "z": 0.5393970328667024
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.590504971074483,
                        "y": -0.5999999999999994,
                        "z": 2.771978259139532
                    },
                    "size": 0.5,
                    "type": "portal"
                }
            ],
            "id": 6,
            "image": "019EBskTT8gJg",
            "modified": true,
            "order": 6,
            "title": "Tertitten-4.jpg",
            "viewDirection": {
                "x": -4.345244542815525,
                "y": -1.3212503382868603,
                "z": 8.888128263938444
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 9,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5544956595802116,
                        "y": -0.6,
                        "z": -0.07105394018452746
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9448962891610984,
                        "y": -0.6,
                        "z": -0.0962459683440499
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6014076061800939,
                        "y": -0.6,
                        "z": 0.056926658584919285
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 12,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0823010433520575,
                        "y": -0.5999999999999999,
                        "z": 0.12430464766456345
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 13,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.8832530610242648,
                        "y": -0.6,
                        "z": 0.22268806500360636
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.2403233108671157,
                        "y": -0.5999999999999999,
                        "z": 0.26641871913115944
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.653534673293456,
                        "y": -0.6,
                        "z": 0.3131986873578745
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfN"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 9.251604602752689,
                        "y": -3.0141535145360057,
                        "z": 2.250903667827981
                    },
                    "type": "image"
                },
                {
                    "domeId": 75,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5898834221446897,
                        "y": -0.6000000000000001,
                        "z": -0.30188109534242774
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 75,
                    "iconId": "arrow",
                    "iconRotation": 347,
                    "id": 10,
                    "order": 10,
                    "portalType": "Wall",
                    "position": {
                        "x": 0.674980335415455,
                        "y": -0.6000000000000002,
                        "z": -0.32583902988778435
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 10,
            "image": "019EBskTT8gP2",
            "order": 7,
            "title": "Tertitten-8.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 8,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.42635265381728993,
                        "y": -0.6,
                        "z": -0.07250481730487852
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5773203566318519,
                        "y": -0.6,
                        "z": 0.08562849764030056
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1998532635008297,
                        "y": -0.5999999999999999,
                        "z": 0.20144569174624286
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 12,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7024952811718266,
                        "y": -0.5999999999999999,
                        "z": 0.28186730567115026
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 13,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.391199537533499,
                        "y": -0.5999999999999999,
                        "z": 0.3968732210889542
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.7956348452732795,
                        "y": -0.5999999999999999,
                        "z": 0.47290074619970823
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.2048604776267924,
                        "y": -0.6,
                        "z": 0.53749137423199
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfN"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 9.466739761092969,
                        "y": -1.8886631420743816,
                        "z": 2.5297857214730377
                    },
                    "type": "image"
                }
            ],
            "id": 9,
            "image": "019EBskTT8gP1",
            "modified": true,
            "order": 8,
            "title": "Tertitten-7.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 11,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5738486676116041,
                        "y": -0.6,
                        "z": 0.00009129088158362907
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1857145879080067,
                        "y": -0.6,
                        "z": -0.002760338937954271
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6032575000785598,
                        "y": -0.5999999999999999,
                        "z": -0.00600740665307411
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.9635045989403046,
                        "y": -0.6,
                        "z": -0.009432289931965099
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.01462691974745002,
                        "y": -0.6000000000000001,
                        "z": -0.26606661319048036
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 13,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5815551607867491,
                        "y": -0.6,
                        "z": 0.024754140921697535
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9620675392613265,
                        "y": -0.5999999999999999,
                        "z": 0.02254359561888393
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4067907299428686,
                        "y": -0.6,
                        "z": 0.01993725712399365
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 75,
                    "id": 9,
                    "label": "Sett deg her",
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9293935945222862,
                        "y": -0.6000000000000001,
                        "z": -0.3271949041210073
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysE"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 3.3202655603897893,
                        "y": -8.540547415625587,
                        "z": 3.9929300769017506
                    },
                    "type": "image"
                },
                {
                    "domeId": 75,
                    "iconId": "arrow",
                    "iconRotation": 9,
                    "id": 11,
                    "order": 11,
                    "portalType": "Wall",
                    "position": {
                        "x": -1.0920058583111578,
                        "y": -0.6000000000000001,
                        "z": -0.3640138703325397
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 12,
            "image": "019EBskTT8gJf",
            "order": 9,
            "title": "Tertitten-10.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 12,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5869203284187615,
                        "y": -0.6,
                        "z": 0.021484639549073595
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5768177546650284,
                        "y": -0.6,
                        "z": 0.012739522307037331
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0338188787588767,
                        "y": -0.6,
                        "z": 0.00342240688924739
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5051433805802426,
                        "y": -0.6,
                        "z": -0.01177815186665313
                    },
                    "showLabel": false,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6102225950709238,
                        "y": -0.6,
                        "z": -0.24044039845849002
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 13,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.2066586071881649,
                        "y": -0.5999999999999999,
                        "z": 0.06671590889976427
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.612775873011484,
                        "y": -0.5999999999999999,
                        "z": 0.0541063284102336
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0267158169932364,
                        "y": -0.5999999999999999,
                        "z": 0.06273569929635348
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 75,
                    "id": 9,
                    "label": "Sett deg her",
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.2586417228875871,
                        "y": -0.6000000000000002,
                        "z": -0.3091410147647984
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfN"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 8.111372805568816,
                        "y": -5.5132080120805265,
                        "z": 1.883214647282893
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiysD"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": 7.673346704892781,
                        "y": -6.134182143103937,
                        "z": 1.7981745842091785
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiynp"
                    },
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": 8.474287430480404,
                        "y": -4.910628779060659,
                        "z": 1.95113280602212
                    },
                    "type": "image"
                },
                {
                    "domeId": 75,
                    "iconId": "arrow",
                    "iconRotation": 174,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.2542521580047517,
                        "y": -0.6000000000000001,
                        "z": -0.3761417680139866
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 11,
            "image": "019EBskTT8gP3",
            "modified": true,
            "order": 10,
            "title": "Tertitten-9.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 12,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5895988976604039,
                        "y": -0.6,
                        "z": -0.06583697449893823
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1356940373847022,
                        "y": -0.6000000000000001,
                        "z": -0.1089268255428601
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5628381807553353,
                        "y": -0.6,
                        "z": -0.13706697719930883
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.9920261511211323,
                        "y": -0.6000000000000001,
                        "z": -0.16233328335229413
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.425952092067466,
                        "y": -0.6,
                        "z": -0.1849043386633136
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.4112720872017714,
                        "y": -0.5999999999999999,
                        "z": 0.01737178990795669
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8538543437813266,
                        "y": -0.6,
                        "z": 0.04229492848043587
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 74,
                    "id": 8,
                    "label": "Sett deg her",
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.1928536023365354,
                        "y": -0.6000000000000001,
                        "z": -0.3705429650731945
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 74,
                    "iconId": "arrow",
                    "iconRotation": 18,
                    "id": 9,
                    "order": 9,
                    "portalType": "Wall",
                    "position": {
                        "x": -0.23930315021084406,
                        "y": -0.6000000000000002,
                        "z": -0.41270840030976835
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 13,
            "image": "019EBskTUdLdN",
            "modified": true,
            "order": 11,
            "title": "Tertitten-12.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 12,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.036434499816577544,
                        "y": -0.5999999999999999,
                        "z": 0.24402419372421402
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6729054930728737,
                        "y": -0.5999999999999999,
                        "z": 0.194445003897101
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0882676485583418,
                        "y": -0.6,
                        "z": 0.17674594392881196
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.651688330790118,
                        "y": -0.6,
                        "z": 0.16045294546281907
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.005454078665503,
                        "y": -0.6,
                        "z": 0.15425666861686435
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 75,
                    "id": 6,
                    "label": "Sett deg her",
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9490857343695718,
                        "y": -0.6,
                        "z": -0.08256701046827401
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysE"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": 1.721365462196001,
                        "y": -6.148277837393085,
                        "z": 7.682439290357722
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiynp"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 1.1228057433152077,
                        "y": -7.142655351288844,
                        "z": 6.893262494655044
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiysD"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": 0.86828010104593,
                        "y": -8.037186481459516,
                        "z": 5.873396481470955
                    },
                    "type": "image"
                }
            ],
            "id": 21,
            "image": "019EBskTUdLdL",
            "modified": true,
            "order": 12,
            "title": "Tertitten-11.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 14,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5199907744444755,
                        "y": -0.5999999999999999,
                        "z": 0.011485960647889728
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 13,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9594578056701781,
                        "y": -0.5999999999999999,
                        "z": -0.011192181545137481
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 12,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.565048576997273,
                        "y": -0.6,
                        "z": -0.03576774076569745
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.001539757934166,
                        "y": -0.6,
                        "z": -0.06585709780690906
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.4090983055318516,
                        "y": -0.6,
                        "z": -0.08616099658627778
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.8722718869165216,
                        "y": -0.6,
                        "z": -0.11081720252406155
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.231816927053377,
                        "y": -0.6,
                        "z": -0.12890336422345175
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.05239116128589534,
                        "y": -0.5999999999999999,
                        "z": 0.42646134410949327
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5383656019788494,
                        "y": -0.5999999999999999,
                        "z": 0.4815056372602343
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6577454184212407,
                        "y": -0.6,
                        "z": 0.004737449250922483
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1177844256008027,
                        "y": -0.6,
                        "z": 0.03452374468352581
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7798515654097413,
                        "y": -0.6,
                        "z": 0.15172658467338068
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.5553989584982006,
                        "y": -0.6,
                        "z": 0.19501548464858257
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.4194629901441296,
                        "y": -0.5999999999999999,
                        "z": 0.24995765901648198
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 15,
                    "order": 15,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.9403609287117227,
                        "y": -0.5999999999999994,
                        "z": 2.6109023361247
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 16,
                    "order": 16,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.050654726796037,
                        "y": -0.5999999999999995,
                        "z": 2.1568995466610303
                    },
                    "size": 0.3,
                    "type": "portal"
                }
            ],
            "id": 15,
            "image": "019EBskTUdLhd",
            "modified": true,
            "order": 13,
            "title": "Tertitten-14.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 13,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.456535400159854,
                        "y": -0.5999999999999999,
                        "z": 0.0031170917891212217
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 12,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0172134749268424,
                        "y": -0.6,
                        "z": -0.020163327149017047
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5643861494755602,
                        "y": -0.5999999999999999,
                        "z": -0.04817869628212949
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.0206593152576495,
                        "y": -0.6,
                        "z": -0.06926151159809453
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.458091391256235,
                        "y": -0.6,
                        "z": -0.07492666867354326
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 8,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.7944285788277208,
                        "y": -0.6,
                        "z": -0.08265545016857556
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.37472213863287096,
                        "y": -0.6,
                        "z": 0.000540392478808767
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 74,
                    "id": 8,
                    "label": "Sett deg her",
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.804116805176266,
                        "y": -0.6000000000000001,
                        "z": -0.3335750348740806
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.5507835744769607,
                        "y": -0.6000000000000001,
                        "z": 0.019626327678188383
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9645230955163435,
                        "y": -0.6,
                        "z": -0.005287351451083616
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 74,
                    "iconId": "arrow",
                    "iconRotation": 14,
                    "id": 11,
                    "order": 11,
                    "portalType": "Wall",
                    "position": {
                        "x": -0.9086027452159705,
                        "y": -0.6000000000000002,
                        "z": -0.36520839466216487
                    },
                    "showLabel": false,
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 14,
            "image": "019EBskTUdLhg",
            "modified": true,
            "order": 14,
            "title": "Tertitten-13.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 16,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7079053220091103,
                        "y": -0.6,
                        "z": -0.07276877012788743
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.0035659850821673747,
                        "y": -0.6000000000000002,
                        "z": -1.046557836827368
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9991364846823441,
                        "y": -0.6000000000000002,
                        "z": -0.9626744954111338
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.8744197788696106,
                        "y": -0.6,
                        "z": -0.20700930897130582
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.076362363217443,
                        "y": -0.6,
                        "z": -0.2541866862114042
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.351733254536525,
                        "y": -0.6000000000000001,
                        "z": -0.3632430628362663
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 7,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.922350610374326,
                        "y": -0.6,
                        "z": -0.0048245359405962125
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4562383298679251,
                        "y": -0.6000000000000001,
                        "z": -0.12290936145344197
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 58,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.1385222005385476,
                        "y": -0.6,
                        "z": -0.04266255446371772
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 54,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.0793965962841394,
                        "y": -0.6000000000000001,
                        "z": -0.030759143614055837
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.685315098590783,
                        "y": -0.5999999999999995,
                        "z": 2.1334893141365803
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.235446086766924,
                        "y": -0.5999999999999995,
                        "z": 1.960606649110918
                    },
                    "size": 0.8,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.4939718371126764,
                        "y": -0.5999999999999994,
                        "z": 2.3619063429381986
                    },
                    "size": 0.3,
                    "type": "portal"
                }
            ],
            "id": 17,
            "image": "019EBskTUdLhj",
            "modified": true,
            "order": 15,
            "title": "Tertitten-16.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 15,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.06301092401479672,
                        "y": -0.6000000000000002,
                        "z": -0.8192184047536653
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0231180859702984,
                        "y": -0.6000000000000001,
                        "z": -0.8940619522642994
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.730227471343014,
                        "y": -0.6,
                        "z": -0.013988828027822862
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.6744465495394945,
                        "y": -0.6,
                        "z": -0.02423522868817409
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.174855587700878,
                        "y": -0.6,
                        "z": -0.05172386461743111
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 7,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.263619830961,
                        "y": -0.5999999999999999,
                        "z": 0.06801188034461511
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.684718578420649,
                        "y": -0.6000000000000001,
                        "z": 0.006809732742437493
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 58,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3920323975399334,
                        "y": -0.5999999999999999,
                        "z": 0.03821255920674808
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 54,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.5922898644012236,
                        "y": -0.6,
                        "z": 0.06698403732233939
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.317616558065857,
                        "y": -0.5999999999999995,
                        "z": 2.0944435915764408
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 9.05443402364131,
                        "y": -0.5999999999999995,
                        "z": 2.336720467423304
                    },
                    "size": 0.9,
                    "type": "portal"
                }
            ],
            "id": 16,
            "image": "019EBskTUdLhi",
            "modified": true,
            "order": 16,
            "title": "Tertitten-15.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 22,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5867005999919391,
                        "y": -0.6000000000000002,
                        "z": 0.013407862227897444
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.3026956664392662,
                        "y": -0.5999999999999999,
                        "z": 0.04202978524930436
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0289930705423624,
                        "y": -0.6,
                        "z": 0.06423064152116817
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 26,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.5015838129866874,
                        "y": -0.6,
                        "z": 0.0060266175437437815
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.9291181389036742,
                        "y": -0.6,
                        "z": 0.007365933807871509
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.44321620093944303,
                        "y": -0.6,
                        "z": -0.09325964385561045
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0466331784321496,
                        "y": -0.6000000000000001,
                        "z": -0.13404703137834312
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.425395607760695,
                        "y": -0.6,
                        "z": -0.040705757717486926
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 19,
            "image": "019EBskTUdLhf",
            "modified": true,
            "order": 17,
            "title": "Tertitten-18.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 17,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.06627832222830173,
                        "y": -0.5999999999999999,
                        "z": 0.4616644974860329
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5313504698158782,
                        "y": -0.5999999999999998,
                        "z": 0.3654317011384163
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5507975726119412,
                        "y": -0.6,
                        "z": 0.1326644308634242
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1997451520895277,
                        "y": -0.5999999999999999,
                        "z": 0.24587514999850243
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.9300357198550557,
                        "y": -0.5999999999999999,
                        "z": 0.3673074519235242
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.7808458794697,
                        "y": -0.5999999999999999,
                        "z": 0.48817560531718734
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 26,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.2343945247556367,
                        "y": -0.6,
                        "z": 0.48775010178287276
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 15,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7084653296403566,
                        "y": -0.6000000000000001,
                        "z": -0.0722263219247231
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0717146162057511,
                        "y": -0.6000000000000001,
                        "z": -0.0697863836099312
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 13,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.406468377975948,
                        "y": -0.6000000000000001,
                        "z": -0.13108893545579448
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.9773170592713813,
                        "y": -0.6000000000000001,
                        "z": -0.21539769872110198
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.3782750704732,
                        "y": -0.6000000000000001,
                        "z": -0.27186664599214694
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 10,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.7300334539591113,
                        "y": -0.6000000000000002,
                        "z": -0.3200474361297178
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 9,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.2640756663463026,
                        "y": -0.6000000000000001,
                        "z": -0.3835763540561572
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 18,
            "image": "019EBskTUdLhh",
            "modified": true,
            "order": 18,
            "title": "Tertitten-17.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 20,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6142020353150764,
                        "y": -0.6000000000000001,
                        "z": -0.014223494466675628
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.313270858653236,
                        "y": -0.6,
                        "z": -0.029416132276859547
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 26,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.8374234555575544,
                        "y": -0.6000000000000001,
                        "z": -0.11405379724796093
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.2013091687716053,
                        "y": -0.6000000000000001,
                        "z": -0.12658044198534413
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5442582394002543,
                        "y": -0.6,
                        "z": -0.0023510353310593392
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9058030448461323,
                        "y": -0.6,
                        "z": -0.045256381692456986
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 22,
            "image": "019EBskTW4Hxs",
            "modified": true,
            "order": 19,
            "title": "Tertitten-19.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 25,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6119724044321696,
                        "y": -0.6,
                        "z": 0.0374957971803368
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 26,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1899647593553397,
                        "y": -0.5999999999999999,
                        "z": -0.007033316181594712
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.679583302107702,
                        "y": -0.6000000000000001,
                        "z": -0.03089813479589435
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5503208310206529,
                        "y": -0.6,
                        "z": -0.0027141318572950885
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0257723270418946,
                        "y": -0.6,
                        "z": -0.03557397951277474
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3734641538129084,
                        "y": -0.6,
                        "z": -0.09115451060519192
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysC"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": 9.832356611907334,
                        "y": 0.5893086964168159,
                        "z": -1.6100612539078203
                    },
                    "type": "image"
                }
            ],
            "id": 20,
            "image": "019EBskTUdLhe",
            "modified": true,
            "order": 20,
            "title": "Tertitten-20.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 20,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5564852246658294,
                        "y": -0.5999999999999999,
                        "z": 0.001087607052455423
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.069731030475176,
                        "y": -0.6,
                        "z": -0.013437976417162205
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5319363545375988,
                        "y": -0.6,
                        "z": -0.0338884959203659
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.9056917519323047,
                        "y": -0.6,
                        "z": -0.07133218769340618
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 71,
                    "id": 5,
                    "label": "Sett deg her",
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9699286437003528,
                        "y": -0.6000000000000002,
                        "z": -0.4154203400449414
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 26,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6235298132696523,
                        "y": -0.6,
                        "z": -0.06655290883705874
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0318086466088103,
                        "y": -0.6,
                        "z": -0.0807353343452974
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysC"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 9.455593351633908,
                        "y": 0.6129325442134658,
                        "z": -3.1662679795703266
                    },
                    "type": "image"
                },
                {
                    "domeId": 71,
                    "iconId": "arrow",
                    "iconRotation": 346,
                    "id": 9,
                    "order": 9,
                    "portalType": "Wall",
                    "position": {
                        "x": 1.087664379467127,
                        "y": -0.6,
                        "z": -0.45343013573184204
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 25,
            "image": "019EBskTW4Hxt",
            "modified": true,
            "order": 21,
            "title": "Tertitten-21.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 25,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6262298201070116,
                        "y": -0.5999999999999999,
                        "z": 0.13232002089638503
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1167623013612247,
                        "y": -0.6000000000000001,
                        "z": 0.11478703794903389
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6447404304896953,
                        "y": -0.6,
                        "z": 0.100103292539784
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.095694697724714,
                        "y": -0.6,
                        "z": 0.06150813838305526
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.41321173117902654,
                        "y": -0.5999999999999999,
                        "z": 0.04299450072041921
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.3914514962925364,
                        "y": -0.5999999999999999,
                        "z": 0.03719561397746775
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 71,
                    "id": 7,
                    "label": "Sett deg her",
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.17084346667382255,
                        "y": -0.6,
                        "z": -0.24213072128212743
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysC"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 7.374209660066723,
                        "y": 1.9181232665439438,
                        "z": -6.454956402771021
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiys9"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": 8.637104872955103,
                        "y": 1.9835997157905345,
                        "z": -4.59117540429688
                    },
                    "type": "image"
                },
                {
                    "domeId": 27,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1117222141780956,
                        "y": -0.5999999999999999,
                        "z": 0.09735508989718158
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.5763519122095273,
                        "y": -0.6,
                        "z": 0.08472293494221367
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 71,
                    "iconId": "arrow",
                    "iconRotation": 266,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.2376029574544328,
                        "y": -0.6,
                        "z": -0.24206785981726314
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 26,
            "image": "019EBskTW4Hxu",
            "modified": true,
            "order": 22,
            "title": "Tertitten-22.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 26,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.48192967588081803,
                        "y": -0.5999999999999999,
                        "z": -0.020515873320094774
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.257476200581695,
                        "y": -0.6000000000000001,
                        "z": 0.07129364969414957
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6609827128180727,
                        "y": -0.6,
                        "z": 0.06429396114241734
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.158299257246224,
                        "y": -0.6,
                        "z": 0.06103625701572891
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.583995459841517,
                        "y": -0.6,
                        "z": 0.05399648604312407
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 18,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.9345422487579147,
                        "y": -0.6,
                        "z": 0.036447031655279814
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.015905833087656467,
                        "y": -0.5999999999999999,
                        "z": 0.47962353381814266
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6173328764859333,
                        "y": -0.6,
                        "z": 0.48757772246196995
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6821424622794177,
                        "y": -0.6,
                        "z": -0.014886779403359796
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysC"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -8.252077528661497,
                        "y": 1.5061642135445008,
                        "z": -5.415420868580122
                    },
                    "type": "image"
                },
                {
                    "domeId": 31,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0575877705553767,
                        "y": -0.6,
                        "z": -0.008948533145488545
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.74795302679774,
                        "y": -0.6,
                        "z": 0.07085865198244545
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.2525041203942955,
                        "y": -0.6,
                        "z": 0.0776196560116202
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.364274980931759,
                        "y": -0.5999999999999996,
                        "z": 2.084334300985617
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 15,
                    "order": 15,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.3375968448600473,
                        "y": -0.5999999999999995,
                        "z": 2.038432422463497
                    },
                    "size": 0.4,
                    "type": "portal"
                }
            ],
            "id": 24,
            "image": "019EBskTW4Hxv",
            "modified": true,
            "order": 23,
            "title": "Tertitten-23.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 24,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.011328823707513563,
                        "y": -0.6000000000000002,
                        "z": -0.9963300443143709
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7575275146349789,
                        "y": -0.6,
                        "z": -0.07768859204100358
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0749366523092758,
                        "y": -0.6000000000000002,
                        "z": -0.9846855467901178
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.2762288681433716,
                        "y": -0.6,
                        "z": 0.004580195052982922
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.38635976248806,
                        "y": -0.6000000000000001,
                        "z": -0.13916358498116363
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.6874526148308697,
                        "y": -0.6000000000000001,
                        "z": -0.12075509027050407
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 7,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.994773688912235,
                        "y": -0.6000000000000001,
                        "z": -0.23630308371705436
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.379354660979079,
                        "y": -0.6,
                        "z": 0.016783535761836606
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.163751477587115,
                        "y": -0.6,
                        "z": 0.021413812548462842
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.456523933566207,
                        "y": -0.5999999999999999,
                        "z": -0.07913337648971062
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 54,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.519838091819091,
                        "y": -0.6000000000000001,
                        "z": -0.2225271360062993
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.175620360757128,
                        "y": -0.5999999999999995,
                        "z": 2.0007397164606457
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.3756931603823472,
                        "y": -0.5999999999999995,
                        "z": 2.0496750642791586
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.297995657334975,
                        "y": -0.5999999999999994,
                        "z": 2.4393516931237533
                    },
                    "size": 0.7,
                    "type": "portal"
                },
                {
                    "id": 15,
                    "order": 15,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.719074562521645,
                        "y": -0.5999999999999996,
                        "z": 1.9222015915953963
                    },
                    "size": 0.6,
                    "type": "portal"
                }
            ],
            "id": 23,
            "image": "019EBskTW4Hxw",
            "modified": true,
            "order": 24,
            "title": "Tertitten-24.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 23,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6739900396556792,
                        "y": -0.6,
                        "z": -0.08165186867172508
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9773694472614048,
                        "y": -0.6000000000000003,
                        "z": -0.9978964142309972
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.050531369844893266,
                        "y": -0.6000000000000003,
                        "z": -1.0503032396001053
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.8786523278550202,
                        "y": -0.6000000000000001,
                        "z": -0.08853869500794992
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.660122740589995,
                        "y": -0.6,
                        "z": -0.08243688890996628
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.591744211437932,
                        "y": -0.6,
                        "z": -0.10916622812208758
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.5110938995196026,
                        "y": -0.6000000000000002,
                        "z": -0.07781771393697112
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.084448798815346,
                        "y": -0.6,
                        "z": -0.07957409486410225
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.218951999426383,
                        "y": -0.5999999999999999,
                        "z": -0.0865421669150535
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.8930048476952386,
                        "y": -0.6,
                        "z": -0.08263849492006464
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 54,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.79955951062412,
                        "y": -0.6000000000000001,
                        "z": -0.0927588978384008
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.304283176312724,
                        "y": -0.5999999999999995,
                        "z": 1.9522108281762753
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.187585111571353,
                        "y": -0.5999999999999994,
                        "z": 2.0935403255351264
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.940883609369443,
                        "y": -0.5999999999999995,
                        "z": 2.425899816180312
                    },
                    "size": 0.7,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfM"
                    },
                    "id": 15,
                    "order": 15,
                    "position": {
                        "x": 9.44807393479898,
                        "y": -2.4751159424874922,
                        "z": -2.1042433878709743
                    },
                    "type": "image"
                }
            ],
            "id": 34,
            "image": "019EBskTW4JaF",
            "modified": true,
            "order": 25,
            "title": "Tertitten-25.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 34,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.020357897683618478,
                        "y": -0.6,
                        "z": 0.4538208750684478
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5467543537116597,
                        "y": -0.5999999999999999,
                        "z": 0.425932983733725
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6898133042577912,
                        "y": -0.6,
                        "z": -0.01726502502834135
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.3833838864779507,
                        "y": -0.5999999999999999,
                        "z": 0.010757124784206845
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0587777217610497,
                        "y": -0.5999999999999999,
                        "z": 0.10462009107847754
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.6772105544399283,
                        "y": -0.6,
                        "z": 0.11082057461344022
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 72,
                    "id": 7,
                    "label": "Sett deg her",
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8599612289501124,
                        "y": -0.5999999999999999,
                        "z": 0.35984352108718176
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 26,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0477911200108119,
                        "y": -0.6,
                        "z": -0.010298758888426972
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.7988794396377081,
                        "y": -0.6,
                        "z": 0.06017014020183607
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.2696965579669284,
                        "y": -0.5999999999999999,
                        "z": 0.06239544931822184
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.6970093816340306,
                        "y": -0.6,
                        "z": 0.06709773580441764
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 19,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.054116890175107,
                        "y": -0.6,
                        "z": 0.05779038721237479
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 27,
            "image": "019EBskTW4JaL",
            "modified": true,
            "order": 26,
            "title": "Tertitten-26.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 27,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.4127137136623664,
                        "y": -0.6000000000000001,
                        "z": -0.06034581608852138
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0951522068619517,
                        "y": -0.6,
                        "z": -0.06512975915351643
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.549609369159411,
                        "y": -0.6,
                        "z": 0.0591745887582466
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1134440205686829,
                        "y": -0.5999999999999999,
                        "z": 0.07468911186976754
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.716015266094571,
                        "y": -0.6,
                        "z": 0.012838278785506321
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.302394480657758,
                        "y": -0.6,
                        "z": 0.02417216883056465
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 72,
                    "id": 7,
                    "label": "Sett deg her",
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.22216180509563205,
                        "y": -0.5999999999999998,
                        "z": 0.2950766191735684
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys8"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 9.524706632408732,
                        "y": -2.4598968368705196,
                        "z": -1.7106304202828737
                    },
                    "type": "image"
                },
                {
                    "domeId": 26,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.590613673631691,
                        "y": -0.6000000000000001,
                        "z": -0.10059660975247298
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 31,
            "image": "019EBskTW4JaE",
            "modified": true,
            "order": 27,
            "title": "Tertitten-27.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 28,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5786131773141842,
                        "y": -0.6000000000000001,
                        "z": 0.007655737925907628
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1249222740362539,
                        "y": -0.6,
                        "z": -0.05893341220092361
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7125454201262615,
                        "y": -0.6,
                        "z": -0.04987156186586918
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5973025351252326,
                        "y": -0.6,
                        "z": -0.08037386838941725
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9275009953279392,
                        "y": -0.6,
                        "z": -0.1199430752540939
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 72,
                    "id": 6,
                    "label": "Sett deg her",
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.21888294422966093,
                        "y": -0.6,
                        "z": 0.22415160837176953
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys8"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": 8.653566021771613,
                        "y": -3.78119961732679,
                        "z": -3.2484671350320697
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiys6"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 8.39068563881146,
                        "y": -4.62399576241427,
                        "z": -2.8391086254848967
                    },
                    "type": "image"
                },
                {
                    "domeId": 24,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5757690734868164,
                        "y": -0.5999999999999999,
                        "z": -0.12117212472304775
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 72,
                    "iconId": "arrow",
                    "iconRotation": 99,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.28116529322869976,
                        "y": -0.6,
                        "z": 0.213574916883923
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 33,
            "image": "019EBskTW4JaH",
            "modified": true,
            "order": 28,
            "title": "Tertitten-28.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 33,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6133773878629207,
                        "y": -0.5999999999999999,
                        "z": 0.0812401982258725
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1268427067304227,
                        "y": -0.6000000000000001,
                        "z": 0.05482121364339465
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.46446351153679644,
                        "y": -0.6,
                        "z": -0.001863696339059744
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1466900815204315,
                        "y": -0.6,
                        "z": 0.0023074093361290034
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 72,
                    "id": 5,
                    "label": "Sett deg her",
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.003688795616193,
                        "y": -0.5999999999999999,
                        "z": 0.33329997656876503
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys8"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": 3.115164682580481,
                        "y": -8.035254280127285,
                        "z": -5.058865451181077
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiys6"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": 2.1519810268536927,
                        "y": -8.95582826563619,
                        "z": -3.8805253509201907
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskuejJfH"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 9.822803124451172,
                        "y": -1.5990992435614182,
                        "z": 0.9000033035994935
                    },
                    "type": "image"
                },
                {
                    "domeId": 72,
                    "iconId": "arrow",
                    "iconRotation": 9,
                    "id": 9,
                    "order": 9,
                    "portalType": "Wall",
                    "position": {
                        "x": -1.1427239305205679,
                        "y": -0.5999999999999998,
                        "z": 0.3670678411870082
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 28,
            "image": "019EBskTW4JaJ",
            "modified": true,
            "order": 29,
            "title": "Tertitten-29.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 28,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5749676839583563,
                        "y": -0.5999999999999999,
                        "z": 0.09743362445344339
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.077182962441571,
                        "y": -0.6,
                        "z": 0.1281944341674898
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6021532156233285,
                        "y": -0.5999999999999999,
                        "z": 0.10261959977119899
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5512648914986319,
                        "y": -0.6,
                        "z": 0.015524224910133189
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfH"
                    },
                    "id": 5,
                    "order": 5,
                    "position": {
                        "x": 9.592817726489676,
                        "y": -2.3935144527998995,
                        "z": 1.4001085179926593
                    },
                    "type": "image"
                }
            ],
            "id": 30,
            "image": "019EBskTW4JaD",
            "modified": true,
            "order": 30,
            "title": "Tertitten-30.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 30,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5013133057263317,
                        "y": -0.6,
                        "z": 0.003616696388554889
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.14024802430214,
                        "y": -0.6,
                        "z": 0.05698547391679659
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.647686363525176,
                        "y": -0.6,
                        "z": 0.07566273623966337
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.1281652315741955,
                        "y": -0.6,
                        "z": 0.05538052730608639
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 76,
                    "id": 5,
                    "label": "Sett deg her",
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.019747692738240553,
                        "y": -0.5999999999999999,
                        "z": 0.34094999913611923
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfH"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": 7.640585147369045,
                        "y": -5.736813975181036,
                        "z": 2.9023007373318612
                    },
                    "type": "image"
                },
                {
                    "domeId": 38,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1458815352513354,
                        "y": -0.5999999999999999,
                        "z": 0.021333454190852755
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.6687650082272318,
                        "y": -0.6000000000000001,
                        "z": -0.03088530290192142
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 72,
                    "iconId": "arrow",
                    "iconRotation": 180,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.016700565430530948,
                        "y": -0.5999999999999999,
                        "z": 0.2683470717919736
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 32,
            "image": "019EBskTW4JaG",
            "modified": true,
            "order": 31,
            "title": "Tertitten-31.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 32,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.542309015623303,
                        "y": -0.5999999999999999,
                        "z": -0.00270991211216179
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1075230015721969,
                        "y": -0.6000000000000002,
                        "z": -0.01973688285866183
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5713229728660536,
                        "y": -0.6,
                        "z": -0.029809216691246115
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.02367958645788793,
                        "y": -0.5999999999999999,
                        "z": 0.5232030391078147
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5012543056811868,
                        "y": -0.6,
                        "z": 0.5305332376480912
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6202218878223194,
                        "y": -0.6,
                        "z": 0.0063630220333901885
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.238997313105898,
                        "y": -0.5999999999999999,
                        "z": 0.005405031153971845
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.824604284400186,
                        "y": -0.6,
                        "z": 0.01607931059699107
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.309624060839093,
                        "y": -0.6000000000000001,
                        "z": 0.026923193643362278
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.1912178985624617,
                        "y": -0.6,
                        "z": 0.08048427936839045
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 77,
            "image": "019EBtG1uro7u",
            "modified": true,
            "order": 32,
            "title": "Tertitten-32.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 36,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7060680446151469,
                        "y": -0.6000000000000001,
                        "z": -0.049133669778595575
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.04041609193192,
                        "y": -0.6000000000000003,
                        "z": -0.9820062780692852
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.976645966943803,
                        "y": -0.6,
                        "z": -0.037843089804433315
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.6663579630399967,
                        "y": -0.6,
                        "z": -0.037478149414051205
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.893334926246495,
                        "y": -0.6,
                        "z": -0.012382236171137675
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.88567894150073,
                        "y": -0.6,
                        "z": 0.01800348135114262
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.4273459708599787,
                        "y": -0.5999999999999999,
                        "z": -0.037574564252880534
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.959098317307791,
                        "y": -0.6000000000000001,
                        "z": -0.08254025089659908
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.697172306845078,
                        "y": -0.5999999999999996,
                        "z": 1.8358805688908986
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7277984066092145,
                        "y": -0.5999999999999994,
                        "z": 2.162258248605898
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.035917153111637,
                        "y": -0.5999999999999995,
                        "z": 2.5359616011709147
                    },
                    "size": 0.8,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfM"
                    },
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": -9.362225809689837,
                        "y": -2.8740361002321664,
                        "z": -1.9905334979091347
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiysB"
                    },
                    "id": 13,
                    "order": 13,
                    "position": {
                        "x": -1.5952955053290574,
                        "y": -0.014220868953220113,
                        "z": -9.85754089607267
                    },
                    "type": "image"
                }
            ],
            "id": 29,
            "image": "019EBskTW4JaK",
            "modified": true,
            "order": 33,
            "title": "Tertitten-33.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 29,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6633574553541467,
                        "y": -0.6000000000000001,
                        "z": -0.09260945768850941
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.019157368574105438,
                        "y": -0.6000000000000001,
                        "z": -0.9946526044967609
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.8941381514140785,
                        "y": -0.6000000000000001,
                        "z": -0.04567793229322845
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.287352139028531,
                        "y": -0.6,
                        "z": -0.029386743734263376
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.193396265337396,
                        "y": -0.6000000000000001,
                        "z": -0.02335972688016583
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.689281820052834,
                        "y": -0.6,
                        "z": -0.009094790103373553
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.478010328464041,
                        "y": -0.6,
                        "z": -0.02466787362233568
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.222903436562408,
                        "y": -0.6,
                        "z": -0.012589339572770319
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.102700437559845,
                        "y": -0.6,
                        "z": -0.052818220350500295
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.259639450828687,
                        "y": -0.5999999999999999,
                        "z": -0.032728720456338324
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysB"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": -6.877928910172855,
                        "y": -0.1744729678152994,
                        "z": -7.235144435068566
                    },
                    "type": "image"
                },
                {
                    "domeId": 63,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.072225234830819,
                        "y": -0.5999999999999995,
                        "z": 1.8659177027107203
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1341922554476025,
                        "y": -0.5999999999999994,
                        "z": 2.0797273757654278
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.307499112357707,
                        "y": -0.5999999999999995,
                        "z": 2.410136508605026
                    },
                    "size": 0.6,
                    "type": "portal"
                }
            ],
            "id": 36,
            "image": "019EBskge8HG9",
            "modified": true,
            "order": 34,
            "title": "Tertitten-34.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 36,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.009057481992540794,
                        "y": -0.5999999999999999,
                        "z": 0.48561083361300716
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5086239216433304,
                        "y": -0.5999999999999999,
                        "z": 0.4733393548937461
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.4382398578865002,
                        "y": -0.5999999999999999,
                        "z": 0.026904251155231512
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0363474261045766,
                        "y": -0.6,
                        "z": 0.028891688550562816
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.5101082046754981,
                        "y": -0.6,
                        "z": 0.027996047918118695
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.19302155772426,
                        "y": -0.5999999999999999,
                        "z": 0.039854621416485554
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 35,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.8862247158958034,
                        "y": -0.5999999999999999,
                        "z": 0.12226933082415034
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.5249045042919174,
                        "y": -0.6,
                        "z": 0.13956320241497494
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.3099586978302216,
                        "y": -0.6,
                        "z": -0.28392006655955954
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysB"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -9.589700588319262,
                        "y": -2.553079108087342,
                        "z": -1.1325111903047769
                    },
                    "type": "image"
                },
                {
                    "domeId": 32,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0937822289186723,
                        "y": -0.6,
                        "z": 0.01776956234569732
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6457739276630436,
                        "y": -0.6,
                        "z": 0.01287232338292219
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 38,
            "image": "019EBskge8HLV",
            "modified": true,
            "order": 35,
            "title": "Tertitten-35.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 38,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.45527514540676045,
                        "y": -0.6,
                        "z": 0.00031199037778374445
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.47608330913043956,
                        "y": -0.6,
                        "z": -0.0036864098263529608
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9071090833966454,
                        "y": -0.6000000000000001,
                        "z": -0.001525404360510415
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.476759153710706,
                        "y": -0.6,
                        "z": 0.01606280915006985
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 35,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.2750271895875813,
                        "y": -0.6,
                        "z": 0.10738300502646805
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.046698436646716,
                        "y": -0.6,
                        "z": 0.11154041414468072
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.8724735233069616,
                        "y": -0.5999999999999999,
                        "z": 0.0712403099877835
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "id": 8,
                    "label": "Sett deg her",
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5227164620857057,
                        "y": -0.6000000000000001,
                        "z": -0.27904729246077886
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 69,
                    "id": 9,
                    "label": "Sett deg her",
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.29597923595737385,
                        "y": -0.6000000000000001,
                        "z": -0.3403900262656965
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys7"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 9.249332249209763,
                        "y": -3.284626328302585,
                        "z": 1.8565791939699046
                    },
                    "type": "image"
                },
                {
                    "domeId": 32,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5190128364443043,
                        "y": -0.5999999999999999,
                        "z": -0.015204084542737946
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.048362067483529,
                        "y": -0.6,
                        "z": -0.029449851382365465
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 69,
                    "iconId": "arrow",
                    "iconRotation": 185,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.2901710824571738,
                        "y": -0.6,
                        "z": -0.3967824180064619
                    },
                    "showLabel": false,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "iconId": "arrow",
                    "iconRotation": 342,
                    "id": 14,
                    "order": 14,
                    "portalType": "Wall",
                    "position": {
                        "x": 0.5719006057678104,
                        "y": -0.6000000000000001,
                        "z": -0.312104525822479
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 39,
            "image": "019EBskge8HLY",
            "modified": true,
            "order": 36,
            "title": "Tertitten-36.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 39,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.4787215417204369,
                        "y": -0.5999999999999999,
                        "z": 0.009919403715918006
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.8802628653965885,
                        "y": -0.6,
                        "z": -0.0033595812142268623
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5008529671093179,
                        "y": -0.6,
                        "z": 0.04569453301054667
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0189406609390248,
                        "y": -0.6,
                        "z": 0.09542240015095871
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 35,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.711368666057258,
                        "y": -0.6,
                        "z": 0.16668407362084625
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.505364950292839,
                        "y": -0.5999999999999999,
                        "z": 0.20154206451994722
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 42,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.254309925474912,
                        "y": -0.6,
                        "z": 0.1793533845911509
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.940467553321909,
                        "y": -0.5999999999999999,
                        "z": 0.22439064614479073
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "id": 9,
                    "label": "Sett deg her",
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.07057439362917725,
                        "y": -0.6,
                        "z": -0.26339463618604875
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 69,
                    "id": 10,
                    "label": "Sett deg her",
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.8291385973994535,
                        "y": -0.6000000000000001,
                        "z": -0.3305395053324114
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys7"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": 7.893355831899516,
                        "y": -5.129337677641441,
                        "z": 3.3460751187293916
                    },
                    "type": "image"
                },
                {
                    "domeId": 69,
                    "iconId": "arrow",
                    "iconRotation": 10,
                    "id": 12,
                    "order": 12,
                    "portalType": "Wall",
                    "position": {
                        "x": -0.9048962934447136,
                        "y": -0.6000000000000001,
                        "z": -0.3542066461194304
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "iconId": "arrow",
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.06791900946176385,
                        "y": -0.6000000000000001,
                        "z": -0.19457418943126703
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 40,
            "image": "019EBskge8HLU",
            "modified": true,
            "order": 37,
            "title": "Tertitten-37.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 41,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.4933226774638197,
                        "y": -0.6000000000000001,
                        "z": 0.014425701006496212
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 35,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.209787436330811,
                        "y": -0.5999999999999998,
                        "z": 0.11088747690438965
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0856696668412744,
                        "y": -0.5999999999999999,
                        "z": 0.13204894889492544
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.338530363538103,
                        "y": -0.6,
                        "z": 0.12355392300345094
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.48914883195366216,
                        "y": -0.5999999999999999,
                        "z": -0.04602374424434629
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9512224951125569,
                        "y": -0.6,
                        "z": -0.03846421647787943
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3286572018549159,
                        "y": -0.6,
                        "z": -0.04874515454448168
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "id": 9,
                    "label": "Sett deg her",
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5754487930408134,
                        "y": -0.6000000000000001,
                        "z": -0.3254033320994163
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 69,
                    "id": 10,
                    "label": "Sett deg her",
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.501611839217901,
                        "y": -0.6000000000000001,
                        "z": -0.39649187102286093
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys7"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": 1.7378931060031795,
                        "y": -8.322854657171115,
                        "z": 5.2477876908195835
                    },
                    "type": "image"
                },
                {
                    "domeId": 42,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.825035193870489,
                        "y": -0.5999999999999999,
                        "z": 0.08134009865893023
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "iconId": "arrow",
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5821087703908799,
                        "y": -0.6,
                        "z": -0.2547257529065154
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 69,
                    "iconId": "arrow",
                    "iconRotation": 5,
                    "id": 14,
                    "order": 14,
                    "portalType": "Wall",
                    "position": {
                        "x": -1.691345311972619,
                        "y": -0.6000000000000001,
                        "z": -0.43999390383526393
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 37,
            "image": "019EBskge8HLX",
            "modified": true,
            "order": 38,
            "title": "Tertitten-38.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 35,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5608708794072179,
                        "y": -0.6,
                        "z": 0.004300494886494341
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.5008683825491917,
                        "y": -0.6000000000000001,
                        "z": 0.028691508137799197
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.6529671730731637,
                        "y": -0.6,
                        "z": -0.007474226505654736
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5083795756281082,
                        "y": -0.6,
                        "z": -0.07632221655949557
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0091994531200394,
                        "y": -0.6000000000000001,
                        "z": -0.09230710025120417
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.446195546028057,
                        "y": -0.6,
                        "z": -0.0829380498163368
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.8367603702950464,
                        "y": -0.6,
                        "z": -0.08091600113791156
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 68,
                    "id": 8,
                    "label": "Sett deg her",
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.19971256937416274,
                        "y": -0.5999999999999999,
                        "z": 0.2592154614281942
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 70,
                    "id": 9,
                    "label": "Sett deg her",
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3200264496698688,
                        "y": -0.6000000000000001,
                        "z": -0.4038016535599742
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfJ"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -8.961612099881687,
                        "y": 1.6426621100958423,
                        "z": 4.103468028846657
                    },
                    "type": "image"
                },
                {
                    "domeId": 42,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.2306033723346506,
                        "y": -0.6,
                        "z": -0.015396832178053483
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 68,
                    "iconId": "arrow",
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.198372445188794,
                        "y": -0.5999999999999999,
                        "z": 0.32081453065544807
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 41,
            "image": "019EBskge8HGC",
            "modified": true,
            "order": 39,
            "title": "Tertitten-39.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 41,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.4901334490043755,
                        "y": -0.6,
                        "z": -0.024913508867844852
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0624810403216893,
                        "y": -0.6000000000000001,
                        "z": -0.11485180656225766
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6083259687139042,
                        "y": -0.6,
                        "z": -0.13020694403572164
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.9704715649474687,
                        "y": -0.6,
                        "z": -0.14509060580749128
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.284054026056127,
                        "y": -0.5999999999999999,
                        "z": -0.15360499909257855
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7627448757623487,
                        "y": -0.6,
                        "z": -0.01681685855507174
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 42,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.5821496016899108,
                        "y": -0.6000000000000002,
                        "z": -0.008802808755266725
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0332948632962986,
                        "y": -0.6,
                        "z": -0.0008578183396344474
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfJ"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -9.909937566253957,
                        "y": 0.5137089552188249,
                        "z": 1.1391967473305422
                    },
                    "type": "image"
                }
            ],
            "id": 35,
            "image": "019EBskge8HLW",
            "modified": true,
            "order": 40,
            "title": "Tertitten-40.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 42,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8005598705266823,
                        "y": -0.6,
                        "z": -0.045717203922736614
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.2402694204842502,
                        "y": -0.6,
                        "z": -0.052227032382143504
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 35,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7990265079918659,
                        "y": -0.6,
                        "z": -0.07102901656615414
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.161007110176833,
                        "y": -0.6000000000000001,
                        "z": -0.10904960068205109
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.659551968397221,
                        "y": -0.6,
                        "z": -0.19009223410917306
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.142535258971687,
                        "y": -0.6000000000000001,
                        "z": -0.22377364940040947
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.6133175136155433,
                        "y": -0.6000000000000001,
                        "z": -0.25990137070352604
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.9307901848601485,
                        "y": -0.6,
                        "z": -0.2803784261228277
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfJ"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -9.994943985830897,
                        "y": 0.10309058413121949,
                        "z": 0.019187762799388365
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskueiysA"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -9.707125434065597,
                        "y": 0.5169676730041696,
                        "z": 2.2782252558243785
                    },
                    "type": "image"
                }
            ],
            "id": 43,
            "image": "019EBskge8HZj",
            "modified": true,
            "order": 41,
            "title": "Tertitten-41.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 44,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.3250399133079878,
                        "y": -0.6,
                        "z": 0.013436983830455407
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7946459475069294,
                        "y": -0.6,
                        "z": 0.06462787012768949
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 35,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.4565712958275991,
                        "y": -0.5999999999999999,
                        "z": 0.060607212113361036
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.006521090373461,
                        "y": -0.6000000000000001,
                        "z": 0.030423111637481223
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.554660971656482,
                        "y": -0.6,
                        "z": -0.00037532326288801913
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.934297570192798,
                        "y": -0.6000000000000001,
                        "z": -0.0049226225804403344
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysA"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": -9.789047787084066,
                        "y": 0.18328525195280276,
                        "z": 1.9853395500617341
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskuejJfJ"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -9.95531227660976,
                        "y": 0.08891021497519913,
                        "z": 0.7860380168692346
                    },
                    "type": "image"
                },
                {
                    "domeId": 50,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9297326061445748,
                        "y": -0.5999999999999999,
                        "z": 0.044424950267490014
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 45,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.376928406701943,
                        "y": -0.6,
                        "z": 0.07038013686590892
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 42,
            "image": "019EBskge8HZh",
            "modified": true,
            "order": 42,
            "title": "Tertitten-42.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 42,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.42540866477168393,
                        "y": -0.6,
                        "z": -0.003769730551976385
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.265077928326181,
                        "y": -0.6000000000000001,
                        "z": 0.09841503581928424
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.9418376939992086,
                        "y": -0.6,
                        "z": 0.0885393911515691
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.3851392248037744,
                        "y": -0.6,
                        "z": 0.05789342148779672
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.005022755424488036,
                        "y": -0.5999999999999999,
                        "z": 0.48710555207844003
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5038347771580355,
                        "y": -0.6,
                        "z": 0.460861397792229
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiysA"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": -9.882565054142116,
                        "y": 0.3002392833767397,
                        "z": 1.3655614213620437
                    },
                    "type": "image"
                },
                {
                    "domeId": 50,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6140131502442979,
                        "y": -0.6000000000000001,
                        "z": 0.051458924402730644
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 45,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9077928777779315,
                        "y": -0.6,
                        "z": 0.026087490493681146
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 46,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7327913130136763,
                        "y": -0.6,
                        "z": 0.11896795664206004
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 47,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.802044280606877,
                        "y": -0.5999999999999999,
                        "z": 0.16515136928283367
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8902629769335645,
                        "y": -0.5999999999999994,
                        "z": 2.0346305742340385
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 67,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.2223056077813865,
                        "y": -0.5999999999999994,
                        "z": 2.5346068756218605
                    },
                    "size": 0.5,
                    "type": "portal"
                }
            ],
            "id": 44,
            "image": "019EBskge8HZi",
            "modified": true,
            "order": 43,
            "title": "Tertitten-43.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 48,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6429638758267993,
                        "y": -0.6000000000000002,
                        "z": -0.0502046867322051
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.0002554771701058251,
                        "y": -0.6000000000000003,
                        "z": -0.9601765499185142
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.449677397762235,
                        "y": -0.6,
                        "z": 0.057598094987428565
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.203356093162095,
                        "y": -0.6000000000000001,
                        "z": -0.07370757713739727
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 55,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.528498187980868,
                        "y": -0.6,
                        "z": -0.11050150622357564
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.811643484542717,
                        "y": -0.6,
                        "z": 0.09881731550364387
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.504442455176196,
                        "y": -0.5999999999999999,
                        "z": 0.11088729850999159
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -6.292846737273816,
                        "y": -0.6,
                        "z": 0.1392361377334353
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 67,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.065796997622415,
                        "y": -0.5999999999999993,
                        "z": 2.9029587146650666
                    },
                    "size": 0.8,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.3326538237293593,
                        "y": -0.5999999999999994,
                        "z": 2.1680507642194073
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.1449537289671743,
                        "y": -0.5999999999999995,
                        "z": 2.1907782185152254
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.977469404801946,
                        "y": -0.5999999999999996,
                        "z": 1.7088902920199533
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 50,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9031851401086477,
                        "y": -0.6000000000000002,
                        "z": -0.8734310346791772
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 49,
            "image": "019EBskge8HeG",
            "modified": true,
            "order": 44,
            "title": "Tertitten-44.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 49,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.611600715229261,
                        "y": -0.6,
                        "z": -0.046712480379912893
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 50,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.003343939672631648,
                        "y": -0.6000000000000003,
                        "z": -1.0132820824403368
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.136427681339222,
                        "y": -0.6000000000000001,
                        "z": -0.013240009460290119
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.3589789884447483,
                        "y": -0.6000000000000001,
                        "z": -0.08078464247238046
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 55,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.833065028986389,
                        "y": -0.5999999999999999,
                        "z": -0.09471679552213008
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.248606387836144,
                        "y": -0.6,
                        "z": 0.012662623307767374
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.974347618781578,
                        "y": -0.6,
                        "z": 0.02378043588895606
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 56,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.166542849263496,
                        "y": -0.6000000000000001,
                        "z": -0.11249899474773112
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 67,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.153492332429193,
                        "y": -0.5999999999999994,
                        "z": 2.811581911908598
                    },
                    "size": 0.7,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6888431373208047,
                        "y": -0.5999999999999995,
                        "z": 2.096804581648933
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.4657683092505094,
                        "y": -0.5999999999999995,
                        "z": 2.043501959865806
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.732029268660421,
                        "y": -0.5999999999999996,
                        "z": 1.7359915973481188
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9299496822897219,
                        "y": -0.6000000000000001,
                        "z": -0.9482745357853986
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 48,
            "image": "019EBskge8HeC",
            "modified": true,
            "order": 45,
            "title": "Tertitten-45.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 48,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.02256209623677546,
                        "y": -0.5999999999999998,
                        "z": 0.4347549846154326
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6054584560853725,
                        "y": -0.5999999999999998,
                        "z": 0.39900006298686336
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 45,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9585180265348312,
                        "y": -0.6,
                        "z": 0.1196926622004587
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 46,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4013189365310605,
                        "y": -0.5999999999999999,
                        "z": 0.12750030614773292
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 47,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.195646350284234,
                        "y": -0.5999999999999999,
                        "z": 0.19637876741664453
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 52,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.2406400371236006,
                        "y": -0.5999999999999999,
                        "z": 0.3628598688195866
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.130229620507963,
                        "y": -0.5999999999999995,
                        "z": 1.8917478668796772
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.4164913695765138,
                        "y": -0.5999999999999994,
                        "z": 2.2202010676538295
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 44,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5848216698322443,
                        "y": -0.6,
                        "z": -0.047488534719253156
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 42,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.989168053544841,
                        "y": -0.6,
                        "z": -0.06809823975277753
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.8510213314465873,
                        "y": -0.6,
                        "z": -0.02073073011223969
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 50,
            "image": "019EBskge8Hib",
            "modified": true,
            "order": 46,
            "title": "Tertitten-46.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 46,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5243900318387228,
                        "y": -0.6,
                        "z": 0.003438208805770818
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 47,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1041266773426304,
                        "y": -0.6,
                        "z": 0.03579377378716101
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 52,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0778767452243634,
                        "y": -0.6,
                        "z": 0.16341650195053042
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 51,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.09007877373297,
                        "y": -0.5999999999999999,
                        "z": 0.19965915896628222
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 50,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.8644998392644152,
                        "y": -0.6,
                        "z": -0.059354227055975976
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 73,
                    "id": 6,
                    "label": "Sett deg her",
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.18533600175829673,
                        "y": -0.6,
                        "z": 0.24812443487255811
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 73,
                    "iconId": "arrow",
                    "iconRotation": 273,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.12579352228116772,
                        "y": -0.6,
                        "z": 0.24348665234712213
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 45,
            "image": "019EBskge8HeB",
            "modified": true,
            "order": 47,
            "title": "Tertitten-47.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 45,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6068508536685631,
                        "y": -0.6,
                        "z": 0.03512286186945225
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 50,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3496924801760268,
                        "y": -0.6,
                        "z": -0.020214577157813513
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 47,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5036916365045103,
                        "y": -0.6000000000000001,
                        "z": 0.0540403365063052
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 52,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.5479975292912729,
                        "y": -0.6,
                        "z": 0.15687770557095862
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 51,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.5646194887381792,
                        "y": -0.6,
                        "z": 0.1834423411341128
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 73,
                    "id": 6,
                    "label": "Sett deg her",
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.852249034859981,
                        "y": -0.5999999999999999,
                        "z": 0.29594396954756946
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 73,
                    "iconId": "arrow",
                    "iconRotation": 10,
                    "id": 7,
                    "order": 7,
                    "portalType": "Wall",
                    "position": {
                        "x": -0.9555833213709806,
                        "y": -0.5999999999999998,
                        "z": 0.3128520111366426
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 46,
            "image": "019EBskge8HeE",
            "modified": true,
            "order": 48,
            "title": "Tertitten-48.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 46,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5605618891401782,
                        "y": -0.5999999999999999,
                        "z": -0.05926619988414405
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 45,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0651047119018289,
                        "y": -0.6,
                        "z": -0.04136839766184841
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 50,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.7762737650316471,
                        "y": -0.6,
                        "z": -0.07245376418733393
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 52,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.136688594975014,
                        "y": -0.6,
                        "z": 0.07548612238453156
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 51,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.9293232237891185,
                        "y": -0.6,
                        "z": 0.0969346579724132
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 47,
            "image": "019EBskge8HeD",
            "modified": true,
            "order": 49,
            "title": "Tertitten-49.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 51,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.540677127458716,
                        "y": -0.6000000000000001,
                        "z": -0.0950260135503907
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 47,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.026748017869084,
                        "y": -0.6000000000000001,
                        "z": -0.13216608137432778
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 46,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.640266451626243,
                        "y": -0.6000000000000001,
                        "z": -0.17136960750690572
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 45,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.2595886549591735,
                        "y": -0.6,
                        "z": -0.19332040573055026
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 50,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.815400512056364,
                        "y": -0.6000000000000001,
                        "z": -0.2370783964553374
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 53,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.288061548939852,
                        "y": -0.6,
                        "z": -0.22350348381040802
                    },
                    "size": 0.2,
                    "type": "portal"
                }
            ],
            "id": 52,
            "image": "019EBskge8HiY",
            "modified": true,
            "order": 50,
            "title": "Tertitten-50.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 53,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5901685109348581,
                        "y": -0.6000000000000001,
                        "z": -0.10126833149838992
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 52,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7342607050288351,
                        "y": -0.6,
                        "z": 0.0993480091355748
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 51,
            "image": "019EBskge8HiX",
            "modified": true,
            "order": 51,
            "title": "Tertitten-51.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 51,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6639010380692446,
                        "y": -0.5999999999999999,
                        "z": 0.10084303926104843
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 52,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.38170105113069,
                        "y": -0.5999999999999999,
                        "z": 0.18101683708422062
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 55,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.006261548424381269,
                        "y": -0.6,
                        "z": 0.4735959136896144
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 62,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9611730994058042,
                        "y": -0.6,
                        "z": 0.04904986857336008
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 56,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.48446972694512397,
                        "y": -0.6,
                        "z": 0.5309260649062022
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 67,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.40863781365134366,
                        "y": -0.5999999999999994,
                        "z": 2.2916694687575188
                    },
                    "size": 0.3,
                    "type": "portal"
                }
            ],
            "id": 53,
            "image": "019EBskge8HiZ",
            "modified": true,
            "order": 52,
            "title": "Tertitten-52.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 62,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.397340339052776,
                        "y": -0.6000000000000001,
                        "z": -0.6472824229153382
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 53,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.024628812776030073,
                        "y": -0.6000000000000002,
                        "z": -0.9857870737188688
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 56,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7106620792444485,
                        "y": -0.6,
                        "z": 0.04572051241615947
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.0693154624233236,
                        "y": -0.6,
                        "z": -0.03587784643052805
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.8062492854407495,
                        "y": -0.6,
                        "z": -0.02646525713674457
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.467616875046529,
                        "y": -0.6,
                        "z": -0.03149653239614031
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.851047954871715,
                        "y": -0.6000000000000001,
                        "z": -0.047541624063034915
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfL"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -8.644818649600564,
                        "y": -2.956317034440929,
                        "z": -4.0367368067257186
                    },
                    "type": "image"
                },
                {
                    "domeId": 67,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6837918690822826,
                        "y": -0.5999999999999995,
                        "z": 2.5243034456207565
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.035931287002507,
                        "y": -0.5999999999999995,
                        "z": 1.8087815276277555
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.422430546724975,
                        "y": -0.5999999999999996,
                        "z": 1.6716479540385485
                    },
                    "size": 0.4,
                    "type": "portal"
                }
            ],
            "id": 55,
            "image": "019EBskge8Ho3",
            "modified": true,
            "order": 53,
            "title": "Tertitten-53.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 55,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.4937580891036206,
                        "y": -0.5999999999999999,
                        "z": 0.6714561782560639
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 53,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.1813396815184944,
                        "y": -0.6,
                        "z": -0.021105257241983547
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 56,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6496568964580929,
                        "y": -0.5999999999999998,
                        "z": 0.7311671131453786
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 67,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5608158344617402,
                        "y": -0.5999999999999991,
                        "z": 3.14220619903886
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.2712333583368665,
                        "y": -0.5999999999999993,
                        "z": 2.49379323582078
                    },
                    "size": 0.6,
                    "type": "portal"
                }
            ],
            "id": 62,
            "image": "019EBskge8Ho5",
            "modified": true,
            "order": 54,
            "title": "Tertitten-54.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 62,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7505672889858499,
                        "y": -0.6000000000000002,
                        "z": -0.764590966997729
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 55,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5975482463905447,
                        "y": -0.6000000000000001,
                        "z": -0.17304677592413714
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.553655977703782,
                        "y": -0.6,
                        "z": -0.13405423679613826
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.215038509815773,
                        "y": -0.6000000000000001,
                        "z": -0.1540650857730253
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.941567269119244,
                        "y": -0.6000000000000002,
                        "z": -0.15468913234736514
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfL"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": -9.287263640187074,
                        "y": -1.675087106045229,
                        "z": -3.2674298920150715
                    },
                    "type": "image"
                },
                {
                    "domeId": 53,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9730116629716863,
                        "y": -0.6000000000000003,
                        "z": -1.2011723253118913
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 67,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.07652036610852132,
                        "y": -0.5999999999999995,
                        "z": 2.273799706668936
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.4629737275880346,
                        "y": -0.5999999999999996,
                        "z": 1.7013333918602065
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.517577509550751,
                        "y": -0.5999999999999996,
                        "z": 1.7030663026175281
                    },
                    "size": 0.6,
                    "type": "portal"
                }
            ],
            "id": 56,
            "image": "019EBskge8Ho1",
            "modified": true,
            "order": 55,
            "title": "Tertitten-55.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 55,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.509207698190492,
                        "y": -0.6,
                        "z": -0.0196956277107605
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 56,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.4026663577438168,
                        "y": -0.6,
                        "z": -0.0018060565334831586
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.0614288671944254,
                        "y": -0.6000000000000001,
                        "z": -0.17374656538431232
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.6762675797869626,
                        "y": -0.6000000000000001,
                        "z": -0.2006667246028662
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.118899262103367,
                        "y": -0.6000000000000001,
                        "z": -0.25042947535506693
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfL"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": 9.715318663549526,
                        "y": -1.1574486882148025,
                        "z": -2.018313081053528
                    },
                    "type": "image"
                },
                {
                    "domeId": 67,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.056702556271,
                        "y": -0.5999999999999993,
                        "z": 2.628052916132982
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3809888404830728,
                        "y": -0.5999999999999995,
                        "z": 1.8823481991184778
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.961838832889436,
                        "y": -0.5999999999999998,
                        "z": 1.6616732118877349
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -6.8176350246047335,
                        "y": -0.5999999999999996,
                        "z": 1.3747174392268404
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.84593617760262,
                        "y": -0.6000000000000001,
                        "z": -0.30031858534223166
                    },
                    "size": 0.4,
                    "type": "portal"
                }
            ],
            "id": 60,
            "image": "019EBskge8HsP",
            "modified": true,
            "order": 56,
            "title": "Tertitten-56.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 49,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.2179771669371693,
                        "y": -0.6000000000000001,
                        "z": -0.10803391196525933
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.7751040597365293,
                        "y": -0.6,
                        "z": -0.1148499930844176
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.361061024007817,
                        "y": -0.6,
                        "z": -0.08929834389604858
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 55,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.639708466492117,
                        "y": -0.6000000000000001,
                        "z": -0.05481229156265699
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.31036190629201,
                        "y": -0.6,
                        "z": -0.1585379189968379
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.037839035441383,
                        "y": -0.6000000000000001,
                        "z": -0.15641703669145826
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.4960295333399465,
                        "y": -0.6000000000000001,
                        "z": -0.17190097099349044
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.068653144718427,
                        "y": -0.6000000000000001,
                        "z": -0.17349267722652328
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.303414105247878,
                        "y": -0.5999999999999994,
                        "z": 2.2470995992875533
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7144310644785373,
                        "y": -0.5999999999999994,
                        "z": 2.077682227839736
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.6612450551685276,
                        "y": -0.5999999999999996,
                        "z": 1.5765478415412149
                    },
                    "size": 0.5,
                    "type": "portal"
                }
            ],
            "id": 57,
            "image": "019EBskge8Ho2",
            "modified": true,
            "order": 57,
            "title": "Tertitten-57.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 34,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.835163460884179,
                        "y": -0.6,
                        "z": -0.10509264780347854
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.362177013564514,
                        "y": -0.6,
                        "z": -0.0787819108465706
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.636734475306355,
                        "y": -0.6,
                        "z": -0.06615241217896768
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4491225036183537,
                        "y": -0.6000000000000002,
                        "z": -0.17463376432034042
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.163432004674162,
                        "y": -0.6,
                        "z": -0.18081488454458278
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.777115694257538,
                        "y": -0.6,
                        "z": -0.1809967632498506
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.188366885353805,
                        "y": -0.6000000000000001,
                        "z": -0.18689738178235757
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.4104987133903384,
                        "y": -0.5999999999999999,
                        "z": -0.07687518715628118
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfM"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -3.4650896490540424,
                        "y": -6.610687876048457,
                        "z": -6.63659251668537
                    },
                    "type": "image"
                },
                {
                    "domeId": 63,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.21044218822427896,
                        "y": -0.5999999999999995,
                        "z": 2.116680899092641
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.25679280462636,
                        "y": -0.5999999999999995,
                        "z": 1.7806540819786303
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.4278151032672137,
                        "y": -0.5999999999999995,
                        "z": 2.369999165570827
                    },
                    "size": 0.5,
                    "type": "portal"
                }
            ],
            "id": 61,
            "image": "019EBskge8Ho6",
            "modified": true,
            "order": 58,
            "title": "Tertitten-58.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 16,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.2079427303778596,
                        "y": -0.6000000000000001,
                        "z": -0.0896949206299626
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6913060973871765,
                        "y": -0.6,
                        "z": -0.11127228100894455
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 7,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.608170604163407,
                        "y": -0.6000000000000001,
                        "z": -0.03500136493724254
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4330337754250142,
                        "y": -0.6000000000000002,
                        "z": -0.19266179508765266
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.3398649856627305,
                        "y": -0.6000000000000001,
                        "z": -0.2407673465529885
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.34413589252533,
                        "y": -0.6000000000000001,
                        "z": -0.36768519119228377
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.140689812827663,
                        "y": -0.6000000000000002,
                        "z": -0.28123969636489154
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.341822475724906,
                        "y": -0.6000000000000001,
                        "z": -0.39735571277032844
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.9474597796269935,
                        "y": -0.5999999999999994,
                        "z": 2.060158978168868
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.521991864315706,
                        "y": -0.5999999999999995,
                        "z": 1.740273697282015
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.509108244225868,
                        "y": -0.5999999999999994,
                        "z": 2.4206374726097555
                    },
                    "size": 0.7,
                    "type": "portal"
                }
            ],
            "id": 59,
            "image": "019EBskge8HsN",
            "modified": true,
            "order": 59,
            "title": "Tertitten-59.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 7,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5800345424809676,
                        "y": -0.6,
                        "z": -0.27391487772167916
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 5,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.599677732341646,
                        "y": -0.6000000000000001,
                        "z": -0.44325067086242
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7432257977155259,
                        "y": -0.6,
                        "z": -0.01702277698735661
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.5936814930456036,
                        "y": -0.5999999999999999,
                        "z": 0.015377897624800768
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.294769391302655,
                        "y": -0.6,
                        "z": 0.14401750807744734
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.11172410064921846,
                        "y": -0.5999999999999995,
                        "z": 2.3702959132541803
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.385332808799219,
                        "y": -0.5999999999999994,
                        "z": 3.1923944991336173
                    },
                    "size": 0.8,
                    "type": "portal"
                }
            ],
            "id": 58,
            "image": "019EBskge8HsM",
            "modified": true,
            "order": 60,
            "title": "Tertitten-60.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 7,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6997670986241427,
                        "y": -0.5999999999999999,
                        "z": -0.035563367902525456
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 5,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.5353376701891635,
                        "y": -0.6,
                        "z": -0.007645010835966507
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 58,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4533531875726113,
                        "y": -0.6,
                        "z": -0.13922453168484056
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.3299510952531266,
                        "y": -0.6000000000000001,
                        "z": -0.22813694274423435
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.105149627261347,
                        "y": -0.6000000000000001,
                        "z": -0.2581109290704108
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.323781164771242,
                        "y": -0.6000000000000001,
                        "z": -0.3264230713207973
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.8812617069402673,
                        "y": -0.5999999999999994,
                        "z": 2.2147164730552515
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.805349646918296,
                        "y": -0.5999999999999995,
                        "z": 2.318100362358777
                    },
                    "size": 0.8,
                    "type": "portal"
                }
            ],
            "id": 54,
            "image": "019EBskge8Ho4",
            "modified": true,
            "order": 61,
            "title": "Tertitten-61.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 63,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.329549073931311,
                        "y": -0.6,
                        "z": -0.1344075093780087
                    },
                    "size": 0.9,
                    "type": "portal"
                },
                {
                    "domeId": 5,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.5418990685859946,
                        "y": -0.6000000000000005,
                        "z": -2.2655308362517443
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 3,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.247792788585479,
                        "y": -0.6000000000000005,
                        "z": -2.521824428964329
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 7,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.0751752144480125,
                        "y": -0.6000000000000004,
                        "z": -2.3502194492149857
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 54,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6856643562559972,
                        "y": -0.6000000000000005,
                        "z": -2.3801141298170876
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 58,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.06662739701040798,
                        "y": -0.6000000000000005,
                        "z": -2.4981706429670316
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.5734810047077106,
                        "y": -0.6000000000000006,
                        "z": -2.6669418401073814
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.050289211642945,
                        "y": -0.6000000000000005,
                        "z": -2.6652136014521925
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.735487923393664,
                        "y": -0.6000000000000006,
                        "z": -2.9034026527482757
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.730065011613697,
                        "y": -0.6000000000000006,
                        "z": -2.93082330913223
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.792599088483755,
                        "y": -0.6000000000000008,
                        "z": -3.0095856751886076
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.5363389143533848,
                        "y": -0.6000000000000006,
                        "z": -2.72131947610628
                    },
                    "size": 0.5,
                    "type": "portal"
                }
            ],
            "id": 64,
            "image": "019EBskgh6GMf",
            "modified": true,
            "order": 62,
            "title": "Tertitten-62.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 54,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -7.432722185064185,
                        "y": -0.6000000000000004,
                        "z": -1.9850198033268311
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 16,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.160327750487186,
                        "y": -0.6000000000000004,
                        "z": -2.1698882606682726
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 17,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.46722987280067,
                        "y": -0.6000000000000005,
                        "z": -2.159216078395147
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.490682763299035,
                        "y": -0.6000000000000001,
                        "z": -0.08852421605701881
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.0790979172756523,
                        "y": -0.6000000000000005,
                        "z": -2.4301384210461
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.4684901762420544,
                        "y": -0.6000000000000004,
                        "z": -2.460537133569829
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.2138655921309702,
                        "y": -0.6000000000000003,
                        "z": -2.3139835559423867
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.058736461886886825,
                        "y": -0.6000000000000005,
                        "z": -2.5394858427307936
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7056215553268195,
                        "y": -0.6000000000000005,
                        "z": -2.6229891002985517
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.3821242393863926,
                        "y": -0.6000000000000006,
                        "z": -2.648826388362924
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.482466325109376,
                        "y": -0.6000000000000006,
                        "z": -2.8171667164469727
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.668868461227558,
                        "y": -0.6000000000000006,
                        "z": -2.974115675982156
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 8.841101521251959,
                        "y": -0.6000000000000008,
                        "z": -3.0133427925360605
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 14,
                    "order": 14,
                    "portalType": "Floor",
                    "position": {
                        "x": 4.157677914854859,
                        "y": -0.6,
                        "z": 0.0071275463566159766
                    },
                    "size": 0.6,
                    "type": "portal"
                }
            ],
            "id": 63,
            "image": "019EBskgh6GMj",
            "modified": true,
            "order": 63,
            "title": "Tertitten-63.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 63,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.9946581111249246,
                        "y": -0.6,
                        "z": 0.23731203009060375
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 64,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -8.083841661061475,
                        "y": -0.6000000000000001,
                        "z": -0.2373978732783355
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.4924278272991023,
                        "y": -0.6000000000000005,
                        "z": -2.255479290504846
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.5519982039518259,
                        "y": -0.6000000000000005,
                        "z": -2.266187730929667
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.1158271036985936,
                        "y": -0.6000000000000005,
                        "z": -2.2459277015064343
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 61,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.4407005990886876,
                        "y": -0.6000000000000005,
                        "z": -2.1695478468064104
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.394337369663518,
                        "y": -0.6000000000000004,
                        "z": -2.0815103056408946
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.244717939395124,
                        "y": -0.6000000000000004,
                        "z": -2.1270988512348414
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 59,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -6.332077626139619,
                        "y": -0.6000000000000005,
                        "z": -2.0673745318428103
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.1808040631254397,
                        "y": -0.6000000000000004,
                        "z": -2.300692499056906
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.9031170487242517,
                        "y": -0.6000000000000005,
                        "z": -2.2138716904222884
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 12,
                    "order": 12,
                    "portalType": "Floor",
                    "position": {
                        "x": 6.227222944823563,
                        "y": -0.6000000000000004,
                        "z": -2.2789474254869075
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 65,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": 5.05386378600633,
                        "y": -0.6000000000000001,
                        "z": 0.4647709208173918
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBtjtM3Qee"
                    },
                    "id": 14,
                    "order": 14,
                    "position": {
                        "x": 9.68343380863255,
                        "y": -1.7465596756959807,
                        "z": 1.6808172679328592
                    },
                    "showLabel": true,
                    "title": "En ansamling mennesker på stasjonen",
                    "type": "image"
                }
            ],
            "id": 66,
            "image": "019EBskgh6GMk",
            "modified": true,
            "order": 64,
            "title": "Tertitten-64.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 65,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.480590946479812,
                        "y": -0.5999999999999999,
                        "z": 0.38408369981491053
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 62,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9997930759871647,
                        "y": -0.6000000000000005,
                        "z": -2.935743252520961
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 56,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.21723136402697996,
                        "y": -0.6000000000000005,
                        "z": -2.198654761480055
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 55,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.48272598112299275,
                        "y": -0.6000000000000004,
                        "z": -2.046609251716038
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 60,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.3376603305131933,
                        "y": -0.6000000000000003,
                        "z": -1.8438284319189144
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 48,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.177579103768268,
                        "y": -0.6000000000000002,
                        "z": -1.6125705753704145
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 49,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.689262948784202,
                        "y": -0.6000000000000003,
                        "z": -1.536026496891014
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 66,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.516656521805051,
                        "y": -0.5999999999999999,
                        "z": 0.6812831479576291
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 63,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -8.127221834189488,
                        "y": -0.5999999999999998,
                        "z": 0.6439184775380034
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "domeId": 57,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -6.0872833587531785,
                        "y": -0.6000000000000002,
                        "z": -1.3712216012562666
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 11,
                    "order": 11,
                    "portalType": "Floor",
                    "position": {
                        "x": -7.337371357563635,
                        "y": -0.6000000000000003,
                        "z": -1.2314699426651967
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBtjtM3Qeg"
                    },
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": -8.43934040565529,
                        "y": -0.03578565482285052,
                        "z": 5.337599484478064
                    },
                    "showLabel": true,
                    "title": "Sørumsand station en gang i tiden",
                    "type": "image"
                }
            ],
            "id": 67,
            "image": "019EBskgh6GMm",
            "modified": true,
            "order": 65,
            "title": "Tertitten-66.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 45,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.21696358966545815,
                        "y": -0.6,
                        "z": 0.389967234565249
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 46,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9307981061890328,
                        "y": -0.5999999999999999,
                        "z": 0.4127663446038665
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 50,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9514840844006434,
                        "y": -0.5999999999999999,
                        "z": 0.25806915134008934
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 73,
            "image": "019EBskgh6GMi",
            "modified": true,
            "order": 66,
            "title": "Tertitten-67.jpg",
            "viewDirection": {
                "x": 0.5315517749826433,
                "y": -1.848467265801974,
                "z": -9.792510590594059
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 41,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.41366146515415997,
                        "y": -0.5999999999999999,
                        "z": 0.360038487807765
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 35,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.41434636556834636,
                        "y": -0.6,
                        "z": 0.26080361568147503
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3179834867789333,
                        "y": -0.6,
                        "z": 0.24193150778511868
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfJ"
                    },
                    "id": 4,
                    "order": 4,
                    "position": {
                        "x": 8.541836984633909,
                        "y": 4.630945017528488,
                        "z": 2.3033781917041405
                    },
                    "type": "image"
                }
            ],
            "id": 68,
            "image": "019EBskgh6GS7",
            "modified": true,
            "order": 67,
            "title": "Tertitten-68.jpg",
            "viewDirection": {
                "x": -1.4573931522083243,
                "y": -0.4106724641745844,
                "z": -9.863633229089222
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 38,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.3064688838933614,
                        "y": -0.5999999999999998,
                        "z": 1.1040847366038178
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.2914700852136575,
                        "y": -0.5999999999999999,
                        "z": 0.6201826337399422
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.35091294234504283,
                        "y": -0.6,
                        "z": -0.010217544580721603
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.30082390228379385,
                        "y": -0.6000000000000001,
                        "z": -0.6552671147726798
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.3497769230871313,
                        "y": -0.6000000000000003,
                        "z": -1.355709673804756
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 69,
                    "id": 6,
                    "label": "Sett deg her",
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.03281706323983105,
                        "y": -0.5999999999999998,
                        "z": 1.2343191532898599
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys7"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": 5.72813724212569,
                        "y": -2.9966475410930533,
                        "z": -7.616378011029622
                    },
                    "type": "image"
                }
            ],
            "id": 70,
            "image": "019EBskgh6GMn",
            "modified": true,
            "order": 68,
            "title": "Tertitten-69.jpg",
            "viewDirection": {
                "x": 9.970285000312499,
                "y": -0.7275494812486608,
                "z": -0.02155386822932348
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 70,
                    "id": 1,
                    "label": "Sett deg her",
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.1144870606060706,
                        "y": -0.6,
                        "z": -0.04102652708145241
                    },
                    "showLabel": true,
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7370109323155221,
                        "y": -0.5999999999999998,
                        "z": 0.2771299338939006
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.3309949621813375,
                        "y": -0.5999999999999999,
                        "z": 0.20452476347826362
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.049449056770238,
                        "y": -0.5999999999999999,
                        "z": 0.11928676744242347
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.1861922594018861,
                        "y": -0.5999999999999999,
                        "z": 0.31914067268024254
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskueiys7"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": 9.455857474483556,
                        "y": -1.9805460495123004,
                        "z": 2.4998802827911404
                    },
                    "type": "image"
                }
            ],
            "id": 69,
            "image": "019EBskgh6GS3",
            "modified": true,
            "order": 69,
            "title": "Tertitten-70.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 32,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.29412115626986274,
                        "y": -0.6,
                        "z": -0.005524780203433582
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskuejJfH"
                    },
                    "id": 2,
                    "order": 2,
                    "position": {
                        "x": 3.795389750272742,
                        "y": -2.8777151406985757,
                        "z": 8.780412795869273
                    },
                    "type": "image"
                }
            ],
            "id": 76,
            "image": "019EBskgh6GS6",
            "modified": true,
            "order": 70,
            "title": "Tertitten-71.jpg",
            "viewDirection": {
                "x": -9.97670932782678,
                "y": -0.3078938943350149,
                "z": 0.2135852468184849
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 28,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.084401406981824,
                        "y": -0.6,
                        "z": 0.40546146289568186
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.32059506502996704,
                        "y": -0.6000000000000001,
                        "z": 0.31135167669363106
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5030299104126686,
                        "y": -0.6,
                        "z": 0.32631947433717695
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0092041704440273,
                        "y": -0.6,
                        "z": 0.34063272344856993
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 72,
            "image": "019EBskgh6GS5",
            "modified": true,
            "order": 71,
            "title": "Tertitten-72.jpg",
            "viewDirection": {
                "x": 0.2097838861221244,
                "y": 0.24983779388237395,
                "z": -9.979189572070739
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 26,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.26895833320228757,
                        "y": -0.6,
                        "z": 0.13357113396305811
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.42376892476557443,
                        "y": -0.5999999999999999,
                        "z": 0.892560737987235
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.2973153702590202,
                        "y": -0.6,
                        "z": -0.3667290360707617
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 71,
            "image": "019EBskgh6GS4",
            "modified": true,
            "order": 72,
            "title": "Tertitten-73.jpg",
            "viewDirection": {
                "x": 9.950920920331724,
                "y": -0.7848693857511633,
                "z": 0.34764996626214995
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 13,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.31211318715606184,
                        "y": -0.5999999999999998,
                        "z": 0.3965152887388626
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 14,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7124256281382578,
                        "y": -0.5999999999999998,
                        "z": 0.42336166224419153
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 74,
            "image": "019EBskgh6GMh",
            "modified": true,
            "order": 73,
            "title": "Tertitten-74.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 10,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6320005185337306,
                        "y": -0.5999999999999999,
                        "z": 0.4704872536894344
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 11,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.2627817145013275,
                        "y": -0.5999999999999999,
                        "z": 0.49738615650443097
                    },
                    "size": 0.1,
                    "type": "portal"
                }
            ],
            "id": 75,
            "image": "019EBskgh6GMg",
            "modified": true,
            "order": 77,
            "title": "Tertitten-75.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        }
    ],
    "settings": {
        "colors": [
            {
                "key": 0,
                "value": null
            },
            {
                "key": 1,
                "value": null
            },
            {
                "key": 2,
                "value": null
            },
            {
                "key": 3,
                "value": null
            }
        ]
    },
    "about": {
        "museum": {
            "name": "Museene i Akershus",
            "url": "/owners/AFM"
        },
        "exhibition": {
            "name": "Besøk Tertitten Urskog-Hølandsbanen",
            "url": "/virtual-experiences/5f9ddaa1-c3d3-42a1-8bcb-4bc74426f00a"
        }
    }
}