import {useEffect, useState} from "react";

export const useInnerSize = () => {
    const [size, setSize] = useState({
        height: 0,
        width: 0
    })
    useEffect(
        () => {
            const updateSize = () => {{
                const width = window.innerWidth
                const height = window.innerHeight
                setSize({
                    width: width,
                    height: height
                })
            }}
            updateSize()

            window.addEventListener("resize", updateSize)
            return () => {
                window.removeEventListener("resize", updateSize)
            }
        }, []
    )

    return {
        innerWidth: size.width,
        innerHeight: size.height
    }
}