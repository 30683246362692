import {Button, Tooltip} from "@mui/material";
import {colors as vmColors} from "@ekultur/vm";
import {ContentCopy} from "@mui/icons-material";
import React from "react";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";
import {addDome, updateDomeOrder} from "../App/service";

export const CopyDomeButton = ({dome, count, index}) => {
    const t = useThreeSixtyTranslation()
    const {onChange, data} = useThreeSixtyProps()

    const handleCopy = () => {
        const updatedData = addDome(data, {
            ...dome,
            id: count + 1,
            order: count + 1,
        });
        onChange(updateDomeOrder(updatedData, count, index));
    }

    return (
        <Tooltip
            title={t("copyDomeTooltip", "Kopier Bilde")}
            placement="right"
        >
            <Button
                onClick={handleCopy}
                sx={{color: vmColors.black}}
                startIcon={<ContentCopy />}
            >
                {t("copyDomeButtonText", "Kopier bilde")}
            </Button>
        </Tooltip>
    )
}