import {useTabState} from "../Sidebar/tabContext";
import React from "react";
import {Domes} from "../Dome/Domes";
import {HotspotModal} from "../Canvas/HotspotModal";
import {SelectedMap} from "../map/SelectedMap";
import {AudioProvider} from "../audio/audioContext";
import {useThreeSixtyProps, useThreeSixtyState} from "./ThreeSixtyContext";

export const TabContent = () => {
    const {tab} = useTabState()
    const {adminMode} = useThreeSixtyProps()
    const {data} = useThreeSixtyProps()
    const hasMap =  Boolean(data?.maps?.length)


    if (tab === 0 || (!adminMode && !hasMap)) {
        return (
            <>
                <Domes/>
                <AudioProvider>
                    <HotspotModal/>
                </AudioProvider>
            </>
        )
    } else {
        return (
            <SelectedMap/>
        )
    }

}