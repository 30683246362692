import {Box} from '@mui/material';
import {useAudioState} from "./audioContext";

export const Visualizer = ({show}) => {
    const {playing} = useAudioState()

    if(show) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .wave": {
                        height: "32px",
                        width: "60px",
                        fill: "black"
                    },
                    "& .line1": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${1 * 0.15}s`
                    },
                    "& .line2": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${2 * 0.15}s`
                    },
                    "& .line3": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${3 * 0.15}s`
                    },
                    "& .line4": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${4 * 0.15}s`
                    },
                    "& .line5": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${5 * 0.15}s`
                    },
                    "& .line6": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${6 * 0.15}s`
                    },
                    "& .line7": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${7 * 0.15}s`
                    },
                    "& .line8": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${8 * 0.15}s`
                    },
                    "& .line9": {
                        animation: "pulse 1s infinite",
                        animationDelay: `${9 * 0.15}s`
                    },
                    "@keyframes pulse":  {
                        "0%": {
                            transform: "scaleY(1)",
                            transformOrigin: "50% 50%"
                        },
                        "50%": {
                            transform: "scaleY(0.7)",
                            transformOrigin: "50% 50%"
                        },
                        "100%": {
                            transform: "scaleY(1)",
                            transformOrigin: "50% 50%"
                        }
                    }
                }}
            >
                <svg
                    className="wave"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 38.05"
                >
                    <title>Audio Wave</title>
                    <path
                        className={playing ? "line1" : null}
                        data-name="Line 1"
                        d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
                    />
                    <path
                        className={playing ? "line2" : null}
                        data-name="Line 2"
                        d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"/>
                    <path
                        className={playing ? "line3" : null}
                        data-name="Line 3"
                        d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"/>
                    <path
                        className={playing ? "line4" : null}
                        data-name="Line 4"
                        d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"/>
                    <path
                        className={playing ? "line5" : null}
                        data-name="Line 5"
                        d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"/>
                    <path
                        className={playing ? "line6" : null}
                        data-name="Line 6"
                        d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"/>
                    <path
                        className={playing ? "line7" : null}
                        data-name="Line 7"
                        d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"/>
                    <path
                        className={playing ? "line8" : null}
                        data-name="Line 8"
                        d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"/>
                    <path
                        className={playing ? "line9" : null}
                        data-name="Line 9"
                        d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"/>
                </svg>
            </Box>
        )
    } else {
        return null
    }

}