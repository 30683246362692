import {SET_ACTIVE_DOME, useThreeSixtyDispatch, useThreeSixtyState} from "../App/ThreeSixtyContext";
import {Box} from "@mui/material";
import React from "react";
import {useDmsImage} from "../media/useDmsImage";
import {findDomeImage} from "../Dome/dome";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    preview: {
        // height: "103px",
        // width: "208px",
        // display: "flex",
        overflow: "hidden",
        border: "2px solid transparent",
        width: "208px",
        height: "150px",
        display: "inline-block",
        position: "relative",
        "&.active": {
            outline: "5px solid #4484F1",
            outlineOffset: "-5px",
            cursor: "arrow"
        },
        "&:hover": {
            outline: "5px solid #4484F1",
            outlineOffset: "-5px",
            cursor: "pointer"
        },
    },
    previewImage: {
        maxWidth: "unset",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        height: "300%"
    }
})

export const DomeThumbnail = ({dome}) => {
    const {activeDomeId} = useThreeSixtyState()
    const dispatch = useThreeSixtyDispatch()
    const classes = useStyles()
    const domeImageUrl = useDmsImage({
        id: findDomeImage(dome),
        dimension: "1024x1024",
    })

    const {id: domeId} = dome

    const handleClick = () => dispatch({type: SET_ACTIVE_DOME, domeId: domeId})

    return (
        <Box
            className={`${classes.preview} ${
                domeId === activeDomeId ? "active" : ""
            }`}
            onClick={handleClick}
        >
            <img
                className={classes.previewImage}
                src={domeImageUrl}
                alt="thumbnail"
            />
        </Box>
    )
}