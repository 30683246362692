import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tooltip: {},
});

export const Tooltip = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.tooltip}>{children}</div>;
};
