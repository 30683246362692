import {Button, Tooltip} from "@mui/material";
import {colors as vmColors} from "@ekultur/vm";
import {Delete} from "@mui/icons-material";
import React from "react";
import {
    SET_ACTIVE_MAP,
    useThreeSixtyDispatch,
    useThreeSixtyProps,
    useThreeSixtyState,
    useThreeSixtyTranslation
} from "../App/ThreeSixtyContext";
import {deleteMap} from "../App/service";

export const DeleteMapButton = ({mapId, count, index, onClick}) => {
    const t = useThreeSixtyTranslation()
    const {onChange, data} = useThreeSixtyProps()
    const dispatch = useThreeSixtyDispatch()
    const {activeMapId} = useThreeSixtyState()

    const handleDelete = () => {
        const updatedData = deleteMap(data, mapId);
        onChange(updatedData);
        if (activeMapId === mapId) {
            const nextMapId = count > 1 ? mapId + 1 : 1
            dispatch({
                type: SET_ACTIVE_MAP,
                mapId: index === 1 ? nextMapId : mapId - 1,
            });
        }
        onClick()
    }

    return (
        <Tooltip
            title={t("deleteMapTooltip", "Slett kart")}
            placement="right"
        >
            <Button
                onClick={handleDelete}
                sx={{color: vmColors.black}}
                startIcon={<Delete />}
            >
                {t("deleteMapButtonText", "Slett kart")}
            </Button>
        </Tooltip>
    )
}