import React from "react";
import {createUseStyles} from "react-jss";
import {LoadingLogo} from "./LoadingLogo";
import {useProgress} from "@react-three/drei";
import {ProgressBar} from "./ProgressBar";
import {useThreeSixtyState} from "../../App/ThreeSixtyContext";

const useStyles = createUseStyles({
  loadingPage: {
    display: "flex",
    height: "100vh",
    backgroundColor: "#F0EDE6",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    margin: "0 0 14px 0",
  },
  loadingText: {
    display: "block",
    position: "relative",
    textAlign: "center",
    color: "#6A686E",
    whiteSpace: "nowrap",
    fontSize: 18,
    fontFamily: "Akkurat",
  },
});

export const PageLoader = ({data}) => {
  const classes = useStyles();
  const { active, progress, item} = useProgress();
  const exhibition = data?.about?.exhibition?.name;
  const { activeDomeId } = useThreeSixtyState();

  const showProgress = () => {
    return active && activeDomeId && item.includes(data?.domes.find(d => d.id === activeDomeId).image)
  }

  return (
      showProgress() && (
      <div className={classes.loadingPage}>
        <div className={classes.loader}>
          <LoadingLogo className={classes.logo} />
          <p className={classes.loadingText}>
            {exhibition ? `Laster ${exhibition}` : "Laster 360 utstilling"}
            {`   ${progress.toFixed(2)}%`}
          </p>
          <ProgressBar progress={progress} />
        </div>
      </div>
    )
  );
};
