import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import React from "react";
import {useThreeSixtyTranslation} from "../../App/ThreeSixtyContext";

export const HighlightHotspotCheckbox = ({value, onChange}) => {
    const t = useThreeSixtyTranslation()
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value || false}
                        onChange={(e) => onChange(e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label={t("highlightHotspot", "Fremhev hotspot")}
            />
        </FormGroup>
    )
}