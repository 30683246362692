import {Button, Tooltip} from "@mui/material";
import {colors as vmColors} from "@ekultur/vm";
import {Delete} from "@mui/icons-material";
import React from "react";
import {
    SET_ACTIVE_DOME,
    useThreeSixtyDispatch,
    useThreeSixtyProps,
    useThreeSixtyState,
    useThreeSixtyTranslation
} from "../App/ThreeSixtyContext";
import {deleteDome, removePortalsToDome} from "../App/service";

export const DeleteDomeButton = ({domeId, count, index}) => {
    const t = useThreeSixtyTranslation()
    const {onChange, data} = useThreeSixtyProps()
    const dispatch = useThreeSixtyDispatch()
    const {activeDomeId} = useThreeSixtyState()

    const handleDelete = () => {
        const updatedData = deleteDome(data, domeId);
        onChange(removePortalsToDome(updatedData, domeId));
        if (activeDomeId === domeId) {
            const nextDomeId = count > 1 ? domeId + 1 : 1
            dispatch({
                type: SET_ACTIVE_DOME,
                domeId: index === 1 ? nextDomeId : domeId - 1,
            });
        }
    }

    return (
        <Tooltip
            title={t("deleteDomeTooltip", "Slett Bilde")}
            placement="right"
        >
            <Button
                onClick={handleDelete}
                sx={{color: vmColors.black}}
                startIcon={<Delete />}
            >
                {t("deleteDomeButtonText", "Slett bilde")}
            </Button>
        </Tooltip>
    )
}