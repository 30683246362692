import React, {createContext, useContext} from "react";
import {useTranslation} from "react-i18next";
import {create} from "zustand";
import {convertToThreeJsTypes} from "../helpers";
import {LoadingLogo} from "../layout/LoadingLogo";
import {LogoPlacer} from "../layout/LogoPlacer";

const ThreeSixtyPropsContext = createContext(undefined);
const ThreeSixtyTranslateContext = createContext(undefined);

export const ThreeSixtyProvider = ({
                                       data,
                                       onChange,
                                       onMediaSelect,
                                       adminMode,
                                       sidePanel,
                                       appReady,
                                       onFeedback,
                                       containerId="container",
                                       children,
                                   }) => {
    const {t, ready} = useTranslation("vm-admin.360", {
        useSuspense: false,
    });
    if (!ready || !appReady) {
        return (
            <LogoPlacer>
                <LoadingLogo/>
            </LogoPlacer>
        )
    } else {
        console.log(data);
        return (
            <ThreeSixtyPropsContext.Provider
                value={{
                    onChange,
                    data: convertToThreeJsTypes(JSON.parse(JSON.stringify(data))),
                    onMediaSelect,
                    adminMode,
                    sidePanel,
                    onFeedback,
                    containerId
                }}
            >
                <ThreeSixtyTranslateContext.Provider value={t}>
                    {children}
                </ThreeSixtyTranslateContext.Provider>
            </ThreeSixtyPropsContext.Provider>
        );
    }
};

export const SET_ACTIVE_DOME = "SET_ACTIVE_DOME";
export const SET_ACTIVE_MAP = 'setActiveMap'
export const MAP_DESELECTED = 'mapDeselected'
export const SET_ACTIVE_HOTSPOT = "SET_ACTIVE_HOTSPOT";
export const SET_MODAL_HOTSPOT = "SET_MODAL_HOTSPOT";
export const SET_SHOW_ANNOTATIONS = "SET_SHOW_ANNOTATIONS";
export const SET_CAMERA_CONTROLS_ENABLED = "SET_CAMERA_CONTROLS_ENABLED";
export const ADD_HOTSPOT_TYPE = "ADD_HOTSPOT_TYPE";
export const SET_SHOW_SIDEPANEL = "SET_SHOW_SIDEPANEL";
export const RESET_STATE = "RESET_STATE";
export const APP_SETUP = "appSetup";
export const DOMES_INITIALIZED = 'domesInitialized'

const initialState = {
    activeDomeId: null,
    activeMapId: null,
    previousMapId: null,
    activeHotspot: undefined,
    previousDomeId: null,
    showAnnotations: true,
    addHotspotType: undefined,
    cameraControlsEnabled: true,
    modalHotspot: 0,
    sidePanel: false,
    appReady: false,
};

const reducer = (state, action) => {
    console.debug(action);
    switch (action.type) {
        case APP_SETUP:
            return {
                ...state,
                appReady: true,
                dmsIndex: action.dmsIndex,
            };
        case DOMES_INITIALIZED:
            return {
                ...state,
                activeDomeId: action.domeId,
                previousDomeId: action.domeId,
            }
        case SET_ACTIVE_DOME: {
            return {
                ...state,
                activeDomeId: action.domeId,
                previousDomeId: state.activeDomeId
            };
        }
        case SET_ACTIVE_MAP: {
            return {
                ...state,
                activeMapId: action.mapId,
                previousMapId: state.activeMapId
            };
        }
        case MAP_DESELECTED: {
            return {
                ...state,
                previousMapId: state.activeMapId,
                activeMapId: null
            }
        }
        case SET_ACTIVE_HOTSPOT: {
            return {
                ...state,
                activeHotspot: action.hotspotId,
            };
        }
        case SET_MODAL_HOTSPOT: {
            return {
                ...state,
                modalHotspot: action.hotspotId,
            };
        }
        case SET_SHOW_ANNOTATIONS: {
            return {
                ...state,
                showAnnotations: action.showAnnotations,
            };
        }
        case SET_CAMERA_CONTROLS_ENABLED: {
            return {
                ...state,
                cameraControlsEnabled: action.cameraControlsEnabled,
            };
        }
        case ADD_HOTSPOT_TYPE: {
            return {
                ...state,
                addHotspotType: action.addHotspotType,
            };
        }
        case SET_SHOW_SIDEPANEL: {
            return {
                ...state,
                sidePanel: action.sidePanel,
            };
        }
        case RESET_STATE: {
            return {
                ...initialState,
            };
        }
        default:
            return undefined;
    }
};

export const useThreeSixtyProps = () => {
    const context = useContext(ThreeSixtyPropsContext);

    if (undefined === context) {
        throw new Error(
            "useThreeSixtyProps must be used within a ThreeSixtyProvider"
        );
    } else {
        return context;
    }
};

export const useThreeSixtyTranslation = () => {
    const context = useContext(ThreeSixtyTranslateContext);

    if (undefined === context) {
        throw new Error(
            "useThreeSixtyTranslation must be used within a ThreeSixtyProvider"
        );
    } else {
        return context;
    }
};

const useStore = create((set) => {
    return {
        ...initialState,
        dispatch: (args) => set((state) => reducer(state, args)),
    };
});

export const useThreeSixtyState = () => useStore();

export const useThreeSixtyDispatch = () => useStore((state) => state.dispatch);
