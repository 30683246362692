import React, { forwardRef } from "react";

import { HotspotTypes } from "../helpers/enums";
import { Portal } from "./Portal";
import { Annotation } from "./Annotation";
import {
  SET_MODAL_HOTSPOT,
  useThreeSixtyDispatch,
  useThreeSixtyState,
} from "../App/ThreeSixtyContext";
import { updateHotspot } from "../App/service";

export const Hotspots = forwardRef(
  (
    { dome, hotspots, data, onChange, adminMode, onPortalClick, opacity },
    ref
  ) => {
    const dispatch = useThreeSixtyDispatch();
    const { showAnnotations, modalHotspot } = useThreeSixtyState();

    const onHotspotChange = (id, changes) => {
      onChange(updateHotspot(data, dome.id, id, changes));
    };

    const handleAnnotationClick = hotspotId => {
      dispatch({
        type: SET_MODAL_HOTSPOT,
        hotspotId: hotspotId === modalHotspot ? 0 : hotspotId,
      });
    }

    return (
      showAnnotations && (
        <group>
          {hotspots?.map((hotspot) => {
            if (hotspot.type === HotspotTypes.PORTAL) {
              return (
                <Portal
                  ref={ref}
                  dome={dome}
                  portal={hotspot}
                  key={`${dome.id}-${hotspot.id}`}
                  data={data}
                  onChange={onHotspotChange}
                  isAdminMode={adminMode}
                  onPortalClick={onPortalClick}
                  opacity={opacity}
                  iconId={hotspot.iconId}
                  iconRotation={hotspot.iconRotation}
                />
              );
            } else {
              return (
                <Annotation
                  ref={ref}
                  hotspotId={hotspot.id}
                  annotation={hotspot}
                  isSelected={modalHotspot === hotspot.id}
                  key={`${dome.id}-${hotspot.id}`}
                  highlight={hotspot.highlight}
                  blackIcon={hotspot.whiteBackground}
                  isAdminMode={adminMode}
                  onClick={handleAnnotationClick}
                  onChange={onHotspotChange}
                  opacity={opacity}
                />
              );
            }
          })}
        </group>
      )
    );
  }
);
