import {createContext, useContext, useReducer} from "react";

export const HOTSPOT_SELECTED = 'setActiveHotspot'
export const HOTSPOT_DESELECTED = 'deselectHotspot'

const MapStateContext = createContext(undefined)
const MapDispatchContext = createContext(undefined)

const initialState = {
    activeHotspotId: null
}

const reducer = (state, action) => {
    switch (action.type) {
        case HOTSPOT_SELECTED:
            return {
                ...state,
                activeHotspotId: action.hotspotId
            }
        case HOTSPOT_DESELECTED:
            return {
                ...state,
                activeHotspotId: null
        }

        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const MapProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    return (
        <MapStateContext.Provider value={state}>
            <MapDispatchContext.Provider value={dispatch}>
                {children}
            </MapDispatchContext.Provider>
        </MapStateContext.Provider>
    )
}

export const useMapState = () => {
    const context = useContext(MapStateContext)
    if (context === undefined) {
        throw new Error('useMapState must be used within a MapProvider')
    } else {
        return context
    }
}

export const useMapDispatch = () => {
    const context = useContext(MapDispatchContext)
    if (context === undefined) {
        throw new Error('useMapDispatch must be used within a MapProvider')
    } else {
        return context
    }
}