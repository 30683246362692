import React from "react";

export const DragWrapper = () => (
  <div
    style={{
      display: "flex",
      width: "100vw",
      height: "100vh",
      position: "relative",
      minWidth: "100vw",
      cursor: "move",
      zIndex: 0,
    }}
  />
);
