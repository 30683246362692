import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    button: {
        display: 'flex',
        flexDirection: 'row',
        height: 36,
        width: 'fit-content',
        margin: '0 5px',
        padding: '0 15px',
        alignItems: 'center',
        alignSelf: 'center',
        cursor: 'pointer',
        border: '2px solid transparent',
        borderRadius: 999,
        '&:hover': {
            background: '#fff',
        },
        '@media (max-width: 700px)': {
            margin: '0 3px',
            padding: '0 8px',
        },
    },
    label: {
        paddingLeft: props => props.iconFirst ? 5 : 0,
        paddingRight: props => !props.iconFirst ? 5 : 0,
        lineHeight: '16px',
    },
    active: {
        background: '#ECEBEF',
        border: '2px solid #0C0B0F',
    },
});

export const Button = ({text, icon, iconFirst = true, active, onClick, dataTip = false, dataFor}) => {
    const styles = useStyles({iconFirst});

    return (
        <div
            className={`${styles.button} ${active ? styles.active : ''}`}
            onClick={onClick}
            data-tip={dataTip}
            data-for={dataFor}
        >
            {iconFirst && icon}
            <div className={styles.label}>{text}</div>
            {!iconFirst && icon}
        </div>
    );
}