import {Button, Tooltip} from "@mui/material";
import {colors as vmColors} from "@ekultur/vm";
import {SwapHoriz} from "@mui/icons-material";
import React from "react";
import {updateDome} from "../App/service";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";

export const SwapDomeImageButton = ({dome}) => {
    const t = useThreeSixtyTranslation()
    const {onChange, data, onMediaSelect} = useThreeSixtyProps()

    const handleSwap = () => {
        if (onMediaSelect) {
            onMediaSelect(
                "image",
                image => {
                    onChange(
                        updateDome(
                            data,
                            dome.id,
                            {
                                ...dome,
                                image: image.dmsId
                            }
                        )
                    )
                },
                "single"
            );
        } else {
            onChange(
                updateDome(
                    data,
                    dome.id,
                    {
                        ...dome,
                        image: '013AitwxjPKA'
                    }
                )
            )
            console.warn("onMediaSelect is undefined");
        }
    }
    return (
        <Tooltip
            title={t("swapDomeTooltip", "Bytt bilde")}
            placement="right"
        >
            <Button
                onClick={handleSwap}
                sx={{color: vmColors.black}}
                startIcon={<SwapHoriz />}
            >
                {t("swapDomeButtonText", "Bytt bilde")}
            </Button>
        </Tooltip>
    )
}