import {Spherical} from "three";
import {degToRad, radToDeg} from "three/src/math/MathUtils";
import {useEffect, useState} from "react";

const normalizeAngle = angle => angle < 0 ? angle + 360 : angle

const degreeAngleFromPosition = position => {
    const angleInDegrees = radToDeg(new Spherical().setFromVector3(position).theta)
    return normalizeAngle(angleInDegrees)
}

const calculateDegreesToRotate = (previousOpposite, current, previousRotation) => {
    const rotateDegrees = previousOpposite - current + previousRotation
    if(rotateDegrees < 0) {
        return rotateDegrees + 360
    } else {
        return rotateDegrees
    }
}

const calculateRotationForViewDirection = viewDirection => {
    if(viewDirection) {
        const spherical = new Spherical().setFromVector3(viewDirection)
        return -spherical.theta
    } else {
        return 0
    }
}

const findOppositeAngle = angleInDegrees => angleInDegrees < 180 ? angleInDegrees + 180 : angleInDegrees - 180

export const useDomeRotation = ({activeDome, prevDome}) => {
    const [rotationY, setRotationY] = useState(0)

    useEffect(
        () => {
            console.debug('yolo')
            const {viewDirection} = activeDome
            if(activeDome.id !== prevDome.id) {
                const portalPointingToPrevious = activeDome.hotspots.find(h => h.domeId === prevDome.id)
                const portalExitedFrom = prevDome.hotspots.find(h => h.domeId === activeDome.id)
                if(portalPointingToPrevious && portalExitedFrom) {
                    let currentDeg = degreeAngleFromPosition(portalPointingToPrevious.position)
                    let previousDeg = degreeAngleFromPosition(portalExitedFrom.position)
                    const previousOppositeDeg = findOppositeAngle(previousDeg);
                    const rotateDegrees = calculateDegreesToRotate(previousOppositeDeg, currentDeg, radToDeg(rotationY))
                    setRotationY(degToRad(rotateDegrees))
                } else {
                    const rotateRadians = calculateRotationForViewDirection(viewDirection)
                    setRotationY(rotateRadians)
                }
            } else {
                const rotateRadians = calculateRotationForViewDirection(viewDirection)
                setRotationY(rotateRadians)
            }
        }, [activeDome?.id, prevDome?.id]
    )

    return rotationY
}