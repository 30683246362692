import React, {forwardRef, useEffect, useState} from "react";
import {Html} from "@react-three/drei";
import {DragWrapper} from "../components/DragWrapper";
import {createUseStyles} from "react-jss";
import {FilterCenterFocus} from "@mui/icons-material";
import {getLocalDomePositionByMouseMove, setCursor} from "../helpers";
import {CursorTypes} from "../helpers/enums";
import {SET_CAMERA_CONTROLS_ENABLED, useThreeSixtyDispatch, useThreeSixtyTranslation,} from "../App/ThreeSixtyContext";
import {useThree} from "@react-three/fiber";
import {updateDome} from "../App/service";
import {Typography} from "@mui/material";

const useStyles = createUseStyles({
    viewDirectionHelper: {
        border: "1px dashed #F0EDE6",
        borderRadius: 30,
        position: "relative",
        width: 30,
        height: 30,
        marginTop: "-50%",
        marginLeft: "-50%",
        padding: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
        color: "#F0EDE6",
        backgroundColor: "rgba(12, 11, 15, 0.5)",
        "&:hover": {
            backgroundColor: "rgba(12, 11, 15, 0.8)",
            width: 33,
            height: 33,
        },
        "& svg": {
            position: "absolute",
        },
    },
});

export const ViewDirectionHelper = forwardRef(
    ({domeId, viewDirection, data, onChange}, ref) => {
        const t = useThreeSixtyTranslation()
        const [dragPosition, setDragPosition] = useState(viewDirection);
        const [isDragging, setIsDragging] = useState(false);

        const {camera, scene} = useThree();
        const dispatch = useThreeSixtyDispatch();

        useEffect(() => {
            setDragPosition(viewDirection);
        }, [viewDirection]);

        const handlePointerMove = (event) => {
            if (event.buttons === 1 && isDragging) {
                const localPosition = getLocalDomePositionByMouseMove(
                    event,
                    camera,
                    scene,
                    ref
                );
                if (localPosition) {
                    setDragPosition(localPosition);
                }
            }
        };

        const handlePointerDown = () => {
            setCursor(CursorTypes.GRABBING);
            setIsDragging(true);
            dispatch({
                type: SET_CAMERA_CONTROLS_ENABLED,
                cameraControlsEnabled: false,
            });
        };

        const handlePointerUp = () => {
            setCursor(CursorTypes.GRAB);
            setIsDragging(false);
            if (viewDirection !== dragPosition) {
                onChange(updateDome(data, domeId, {viewDirection: dragPosition}));
            }

            dispatch({
                type: SET_CAMERA_CONTROLS_ENABLED,
                cameraControlsEnabled: true,
            });
        };

        console.debug({dragPosition})

        const classes = useStyles();
        return (
            <mesh name="viewDirectionHelper" position={dragPosition}>
                <Html
                    zIndexRange={[0, 0]}
                >
                    <div
                        className={classes.viewDirectionHelper}
                        onPointerDown={handlePointerDown}
                        onPointerUp={handlePointerUp}
                        onPointerMove={handlePointerMove}
                    >
                        <FilterCenterFocus/>
                        <Typography
                            sx={{
                                mt: "60px",
                                backgroundColor: "rgba(12, 11, 15, 0.5)"
                            }}
                        >
                            {t('viewDirectionHelperLabel', "Startretning")}
                        </Typography>

                        {isDragging && <DragWrapper/>}
                    </div>
                </Html>


            </mesh>
        );
    }
);
