import React from "react";
import { createUseStyles } from "react-jss";
import { EditableInput } from "react-color/lib/components/common";
const useStyles = createUseStyles({
  field: { paddingLeft: "6px", width: "100%" },
  alpha: { paddingLeft: "6px", width: "100%" },
});

export const RGBFields = ({ rgb, hsl, style, onChange }) => {
  const handleChange = (data, e) => {
    if (data.r || data.g || data.b) {
      onChange(
        {
          r: data.r || rgb.r,
          g: data.g || rgb.g,
          b: data.b || rgb.b,
          source: "rgb",
        },
        e
      );
    } else if (data.a) {
      if (data.a < 0) {
        data.a = 0;
      } else if (data.a > 1) {
        data.a = 1;
      }

      onChange(
        {
          h: hsl.h,
          s: hsl.s,
          l: hsl.l,
          a: Math.round(data.a * 100) / 100,
          source: "rgb",
        },
        e
      );
    }
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.field}>
        <EditableInput
          style={style}
          label="r"
          value={rgb.r}
          onChange={handleChange}
        />
      </div>
      <div className={classes.field}>
        <EditableInput
          style={style}
          label="g"
          value={rgb.g}
          onChange={handleChange}
        />
      </div>
      <div className={classes.field}>
        <EditableInput
          style={style}
          label="b"
          value={rgb.b}
          onChange={handleChange}
        />
      </div>
      <div className={classes.alpha}>
        <EditableInput
          style={style}
          label="a"
          value={rgb.a}
          arrowOffset={0.01}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
