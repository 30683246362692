import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    emptyCanvas: {
        width: "100%",
        background: "#EEEEEE",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    emptyContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "400px",
    },
})

export const PlaceHolderContainer = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.emptyCanvas}>
            <div className={classes.emptyContent}>
                {children}
            </div>
        </div>
    )
}