import React from "react";

import { useThreeSixtyTranslation } from "../../App/ThreeSixtyContext";
import { TextField } from "../../components/TextField";

export const RssTab = ({ content, updateContent }) => {
  const t = useThreeSixtyTranslation();

  return (
    <>
      <TextField
        id="rssPodcastFeedUrl"
        label={t("rssPodcastFeedUrlLabel", "Rss podcast url")}
        variant="filled"
        sx={{ margin: "10px 0" }}
        value={content?.rss}
        onChange={(value) => updateContent("rss", value)}
      />
    </>
  );
};
