import {FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack} from "@mui/material";
import React from "react";
import {useThreeSixtyTranslation} from "../../App/ThreeSixtyContext";
import {iconOptions} from "../../components/icons/navigationIcons";

export const PortalIconSelect = ({value, onChange}) => {
    const t = useThreeSixtyTranslation()
    return (
        <FormControl variant="filled" sx={{ width: "100%", margin: "10px 0" }}>
            <InputLabel id="select-icon-label">
                {t("selectIconLabel", "Ikon")}
            </InputLabel>
            <Select
                labelId="select-icon-label"
                id="select-icon-input"
                value={value || ""}
                onChange={(e) => onChange(e.target.value)}
            >
                {iconOptions.map(option => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                    >
                        <Stack
                            direction={"row"}
                            spacing={0}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                        >
                            <ListItemIcon>
                                <option.Icon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t(option.id, option.label)}
                            />
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}