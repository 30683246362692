import {Paper, Popper, Stack} from "@mui/material";
import {SET_ACTIVE_MAP, useThreeSixtyDispatch, useThreeSixtyProps} from "../App/ThreeSixtyContext";
import {SelectMapButton} from "./SelectMapButton";

export const MapPopper = ({onClose, open, anchorEl}) => {
    const {data} = useThreeSixtyProps()
    const dispatch = useThreeSixtyDispatch()

    const handleSelect = mapId => {
        dispatch({
            type: SET_ACTIVE_MAP,
            mapId: mapId
        })
        onClose()
    }

    return (
        <Popper
            anchorEl={anchorEl}
            placement={"bottom-start"}
            open={open}
            sx={{
                zIndex: 1300,
            }}
        >
            <Paper
                sx={{
                    p: 2
                }}
            >
                <Stack
                    spacing={2}
                    alignItems={"flex-start"}
                >
                    {data.maps.sort((a, b) => a.order - b.order)
                        .map(map => (
                            <SelectMapButton
                                key={map.id}
                                map={map}
                                onClick={handleSelect}
                            />
                        ))}
                </Stack>
            </Paper>
        </Popper>
    )
}