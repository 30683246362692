import React, {useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {useDrag, useDrop} from "react-dnd";
import {Box, IconButton, Tooltip} from "@mui/material";
import {DragHandle, MoreHoriz} from "@mui/icons-material";
import {useThreeSixtyState, useThreeSixtyTranslation,} from "../App/ThreeSixtyContext";
import {ContextMenu} from "./ContextMenu";
import {DeleteDomeButton} from "./DeleteDomeButton";
import {CopyDomeButton} from "./CopyDomeButton";
import {SwapDomeImageButton} from "./SwapDomeImageButton";
import {DomeThumbnail} from "./DomeThumbnail";

const useStyles = createUseStyles(({colors}) => ({
    dome: {
        display: "flex",
        flexDirection: "column",
        padding: "8px 8px 6px",
        opacity: ({isDragging}) => (isDragging ? 0 : 1),
        border: ({isDragging}) => (isDragging ? "1px dotted black" : "none"),
        "&.active": {
            backgroundColor: "rgb(0,0,0, 0.2)"
        }
    },
    domeData: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "208px",
    },
    domeInfo: {
        color: "white"
    },
    domeSettings: {
        display: "flex",
        position: "relative",
    },
    handle: {
        cursor: "move",
        color: "white"
    },
    domeOrder: {
        top: "0px",
        left: "-15px",
        position: "absolute",
        color: colors.white,
    },
    typeWrapper: {
        backgroundColor: colors.white,
        border: `1px solid ${colors.black}`,
        padding: "2px 5px",
    },
}));

export const DomeThumbnailContainer = ({
                                  index,
                                  dome,
                                  totalDomes,
                                  moveCard,
                                  resetCards,
                                  dropCard,
                                  adminMode,
                              }) => {
    const t = useThreeSixtyTranslation();
    const [handlePressed, setHandlePressed] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const {activeDomeId} = useThreeSixtyState()

    const ref = useRef();
    const [{handlerId}, drop] = useDrop({
        accept: "DOME",
        collect: (monitor) => {
            return {handlerId: monitor.getHandlerId()};
        },
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop: dropCard,
    });
    const [{isDragging}, drag] = useDrag({
        type: "DOME",
        item: () => {
            return {id: dome.id, index: index};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (_item, monitor) => {
            setHandlePressed(false);
            if (!monitor.didDrop()) {
                resetCards();
            }
        },
        canDrag: handlePressed,
    });

    const classes = useStyles({isDragging});
    drag(drop(ref));

    const onShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div
            className={`${classes.dome} ${
                dome.id === activeDomeId ? "active" : ""
            }`}
            ref={ref}
            data-handler-id={handlerId}
        >
            <DomeThumbnail
                dome={dome}
            />
            {adminMode && (
                <div className={classes.domeData}>
                    <Tooltip
                        title={t("reorderDomeTooltip", "Dra for å endre rekkefølge")}
                    >
                        <IconButton onMouseDown={() => setHandlePressed(true)}>
                            <DragHandle className={classes.handle}/>
                        </IconButton>
                    </Tooltip>
                    <div className={classes.domeInfo}>{dome.title || "no title"}</div>
                    <Box flexGrow={1}/>
                    <div className={classes.domeSettings}>
                        <Tooltip title={t("moreDomeTooltip", "Flere funksjoner")}>
                            <IconButton
                                onClick={onShowMore}
                                sx={{
                                    color: "white"
                                }}>
                                <MoreHoriz/>
                            </IconButton>
                        </Tooltip>
                        <ContextMenu
                            show={showMore}
                        >
                            <DeleteDomeButton
                                domeId={dome.id}
                                count={totalDomes}
                                index={index}
                            />
                            <CopyDomeButton
                                dome={dome}
                                count={totalDomes}
                                index={index}
                            />
                            <SwapDomeImageButton
                                dome={dome}
                            />
                        </ContextMenu>
                    </div>
                </div>
            )}
        </div>
    );
};
