import {TAB_CHANGED, useTabDispatch, useTabState} from "./tabContext";
import { Tab, Tabs } from '@mui/material';

export const SidebarTabs = () => {
    const {tab} = useTabState()
    const dispatch = useTabDispatch()

    const handleTabChange = (event, newValue) => {
        dispatch({
            type: TAB_CHANGED,
            tab: newValue
        })
    };

    return (
        <Tabs
            value={tab}
            onChange={handleTabChange}
            variant={"fullWidth"}
            sx={{
                backgroundColor: "#424242",
            }}
        >
            <Tab
                label="Bilder"
                value={0}
                sx={{
                    color: "white"
                }}
            />
            <Tab
                label="Kart"
                value={1}
                sx={{
                    color: "white"
                }}
            />
        </Tabs>
    );

}