import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";

import {HotspotSettings} from "./HotspotSettings";
import {useThreeSixtyState} from "../App/ThreeSixtyContext";
import {HotspotTypes} from "../helpers/enums";
import {ExpandMore} from "@mui/icons-material";

export const Hotspots = ({dome}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const {activeHotspot} = useThreeSixtyState();
    const portals = dome.hotspots.filter((h) => h.type === HotspotTypes.PORTAL);
    const rest = dome.hotspots.filter((h) => h.type !== HotspotTypes.PORTAL);

    useEffect(() => {
        if (activeHotspot) {
            setIsExpanded(true);
        }
    }, [activeHotspot]);

    const onExpand = (event, isExpanded) => {
        setIsExpanded(isExpanded);
    };

    const byTypeAscending = (a, b) => {
        return a.type > b.type ? 1 : -1;
    };

    return (
        <Accordion
            disableGutters
            elevation={0}
            square
            expanded={isExpanded}
            onChange={onExpand}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Hotspots</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {portals.sort(byTypeAscending).map((hotspot) => (
                    <HotspotSettings
                        key={`hotspot-${hotspot.id}`}
                        hotspot={hotspot}
                        dome={dome}
                    />
                ))}
                {rest.sort(byTypeAscending).map((hotspot) => (
                    <HotspotSettings
                        key={`hotspot-${hotspot.id}`}
                        hotspot={hotspot}
                        dome={dome}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    );
};
