import {Text} from "@react-three/drei";
import React, {useRef} from "react";
import {Vector3} from "three";
import {useFrame} from "@react-three/fiber";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";

export const PortalDestination = ({scale, destinationDomeId=null}) => {
    const text = useRef()
    const {data} = useThreeSixtyProps()
    const t = useThreeSixtyTranslation()

    useFrame(
        () => {
            if(text.current) {
                text.current.lookAt(new Vector3(0, 0, 0))
            }

        },
    )

    const destinationTitle = () => {
        if(destinationDomeId) {
            return data.domes.find(dome => dome.id === destinationDomeId)?.title
        } else {
            return t('portalNoDestinationLabel', 'Ingen destinasjon')
        }
    }

    return (
        <Text
            ref={text}
            fontSize={Math.max(0.1 * scale, 0.05)}
            position={[0, 0, 0]}
            maxWidth={1 * scale}
            textAlign={"center"}
            anchorX={"center"}
            anchorY={-(scale / 4)}
            outlineBlur={"15%"}
            outlineColor={"rgba(0,0,0, 0.75)"}
            outlineOpacity={0.9}
            outlineWidth={"20%"}
            color={"#3CA1FF"}
        >
            {destinationTitle()}
        </Text>
    )
}