import {DOMES_INITIALIZED, useThreeSixtyDispatch, useThreeSixtyState} from "../App/ThreeSixtyContext";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";

export const useInitialize = domes => {
    const {activeDomeId} = useThreeSixtyState()
    const dispatch = useThreeSixtyDispatch()

    useDeepCompareEffectNoCheck(
        () => {
            if(!activeDomeId || !domes.map(dome => dome.id).includes(activeDomeId)) {
                const firstDome = domes.reduce((previous, current) => {
                    if(!previous) {
                        return current
                    } else {
                        return previous.order > current.order ? current : previous
                    }
                }, null)

                dispatch({
                    type: DOMES_INITIALIZED,
                    domeId: firstDome.id
                })
            }
        }, [domes, activeDomeId, dispatch]
    )
}