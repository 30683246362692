import React from "react";
import {MuiModal} from "../components/MuiModal";
import {DialogContentText} from "@mui/material";

export const TextBox = ({ annotation }) => {
  const {title, content} = annotation;
  return (
      <MuiModal
          title={title}
      >
        <DialogContentText>
          {content?.description}
        </DialogContentText>
      </MuiModal>
  )
};
