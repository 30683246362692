import {useCallback} from "react";

export const useDragDropHandlers = ({internalState, setInternalState, currentState, onChange}) => {
    const moveHandler = useCallback(
        (dragIndex, hoverIndex) => {
            const dragged = internalState[dragIndex];
            const hovered = internalState[hoverIndex];
            setInternalState(prevState => (
                prevState.reduce((acc, q) => {
                    if (q.order === dragged.order) {
                        return [
                            ...acc,
                            {
                                ...q,
                                order: hovered.order,
                                modified: true,
                            },
                        ];
                    } else if (q.order === hovered.order) {
                        return [
                            ...acc,
                            {
                                ...q,
                                order: dragged.order,
                                modified: true,
                            },
                        ];
                    } else {
                        return [...acc, q];
                    }
                }, [])
                    .sort((a, b) => a.order - b.order)
            ))
        },
        [internalState]
    )

    const dropHandler = () => {
        const original = currentState.sort((a, b) => a.order - b.order);
        const modified = JSON.stringify(internalState) !== JSON.stringify(original);

        if (modified) {
            onChange(internalState)
        }
    }

    const resetHandler = () => {
        setInternalState(currentState.sort((a, b) => a.order - b.order))
    }

    return {
        moveHandler: moveHandler,
        dropHandler: dropHandler,
        resetHandler: resetHandler
    }
}