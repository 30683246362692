import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Demo} from './demo/Demo';
import {App} from './App/App';
import './App/i18n';
import {createRoot} from "react-dom/client";
import './polyfills/createImageBitMap'

const roots  = {}

window.kit360 = {
  renderDemo: args => {
    const container = document.getElementById(args.containerId)
    const root = createRoot(container)
    root.render(<Demo {...args} />)
  },
  render360: args => {
    const container = document.getElementById(args.containerId)
    if(!roots[args.containerId]) {
      roots[args.containerId] = createRoot(container)
    }
    roots[args.containerId].render(
        <App {...args} />
    )
  },
  unmount: containerId => {
    if(roots[containerId]) {
      roots[containerId].unmount()
      roots[containerId] = null
    }
  },
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
