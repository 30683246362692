import React from "react";
import {MuiModal} from "../components/MuiModal";
import {useDmsImage} from "../media/useDmsImage";
import {Box, Stack} from "@mui/material";

export const ImageModal = ({ annotation }) => {
  const { title, content } = annotation;
  const dmsId = content?.image ? content.image : content?.media?.dmsId

  const imageUrl = useDmsImage({
    id: dmsId
  })

  const image = imageUrl
      ? (
          <Box
              component={"img"}
              src={imageUrl}
              alt={content?.media?.alt}
              sx={{
                width: "100%",
                height: content?.description ? "90%" : "100%",
                  objectFit: "contain"
              }}
          />
      ) : null

  const description = content?.description ? <p>{content?.description}</p> : null

  return (
    <MuiModal
        title={title}
    >
      <Stack
          justifyContent={"flex-start"}
          sx={{
            width: "100%",
            height: "80vh",
          }}
      >
        {image}
        <Box>
          {description}
        </Box>
      </Stack>
    </MuiModal>
  );
};
