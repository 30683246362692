import React from "react";
import {useParams} from "react-router";
import {App} from "../App/App";
import {demo1} from "./demo01";
import {demo2} from "./demo02";
import {demo3} from "./demo03";
import {demo4} from "./demo04";
import {demo5} from "./demo05";
import {demo6} from "./demo06";

export const Demo360 = () => {
    const {id} = useParams();
    return (
        <App
            adminMode={false}
            data={scenes[id]}
            onFeedback={{
                snackbarError: message => console.error(message),
                snackbarWarning: message => console.warn(message),
                snackbarInfo: message => console.info(message),
                snackbarSuccess: message => console.debug(message)
            }}
        />
    )
};

const scenes = {
    1: demo1,
    2: demo2,
    3: demo3,
    4: demo4,
    5: demo5,
    6: demo6
};
