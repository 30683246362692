import {MapSettings} from "./MapSettings";
import {MapHotspotsSettings} from "./MapHotspotsSettings";
import {Divider, Stack, Typography} from "@mui/material";
import React from "react";
import {Info} from "@mui/icons-material";
import {useThreeSixtyTranslation} from "../App/ThreeSixtyContext";

export const MapSettingsBar = () => {
    const t = useThreeSixtyTranslation()
    return (
        <>
            <MapSettings/>
            <Divider />
            <MapHotspotsSettings/>
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                sx={{
                    pl: 2,
                    pr: 2
                }}
            >
                <Info
                    sx={{
                        color: "text.secondary"
                    }}
                />
                <Typography
                    variant={"body2"}
                    color={"text.secondary"}
                >
                    {t('mapSettingsHelpText', "Klikk i bilde for å legge til hotspots")}
                </Typography>
            </Stack>
        </>
    )
}