import React, {forwardRef, useEffect, useState} from "react";
import {Html} from "@react-three/drei";
import {useThree} from "@react-three/fiber";

import {getLocalDomePositionByMouseMove, setCursor} from "../helpers";
import {CursorTypes} from "../helpers/enums";
import {SET_ACTIVE_HOTSPOT, SET_CAMERA_CONTROLS_ENABLED, useThreeSixtyDispatch,} from "../App/ThreeSixtyContext";
import {createUseStyles} from "react-jss";
import {AnnotationComponent} from "./AnnotationComponent";

const useStyles = createUseStyles({
    label: {
        position: "absolute",
        width: "max-content",
        top: ({highlighted}) => highlighted ? "64px" : "44px",
        transform: "translateX(-50%)",
        color: "white",
        textShadow: "1px 1px 3px rgba(0,0,0, 0.75)",
        zIndex: 0
    }
})

export const Annotation = forwardRef(
    (
        {
            annotation,
            isAdminMode,
            isSelected = false,
            onClick,
            hotspotId,
            onChange,
            opacity,
            highlight = false,
            blackIcon = false
        },
        ref
    ) => {
        const {id, position, type, title, showLabel} = annotation;
        const dispatch = useThreeSixtyDispatch();
        const {camera, scene} = useThree();
        const [isDragging, setIsDragging] = useState(false);
        const [dragPosition, setDragPosition] = useState(position);
        const classes = useStyles({
            highlighted: highlight
        })

        useEffect(() => {
            setDragPosition(position);
        }, [position]);

        const handlePointerMove = event => {
            event.preventDefault()
            event.stopPropagation()
            if (isAdminMode) {
                if (event.buttons === 1 && isDragging) {
                    const localPosition = getLocalDomePositionByMouseMove(
                        event,
                        camera,
                        scene,
                        ref
                    );
                    if (localPosition) {
                        setDragPosition(localPosition);
                    }
                }
            }
        };

        const handlePointerDown = event => {
            event.preventDefault()
            event.stopPropagation()
            if (isAdminMode) {
                setCursor(CursorTypes.GRABBING);
                setIsDragging(true);
                dispatch({
                    type: SET_CAMERA_CONTROLS_ENABLED,
                    cameraControlsEnabled: false,
                });
                dispatch({
                    type: SET_ACTIVE_HOTSPOT,
                    hotspotId: id,
                });
            }
        };

        const handlePointerUp = event => {
            event.preventDefault()
            event.stopPropagation()
            if (isAdminMode) {
                if (position.equals(dragPosition)) {
                    onClick(hotspotId);
                }
                setCursor(CursorTypes.GRAB);
                setIsDragging(false);
                if (position !== dragPosition) {
                    onChange(id, {position: dragPosition});
                }

                dispatch({
                    type: SET_CAMERA_CONTROLS_ENABLED,
                    cameraControlsEnabled: true,
                });
            } else {
                onClick(hotspotId);
            }
        };


        const zIndexRange = isSelected && !isDragging ? [1, 0] : [0, 0];

        return (
            <mesh
                name={`Annotation-${id}`}
                position={isAdminMode ? dragPosition : position}
                userData={{id}}
            >
                <Html
                    zIndexRange={zIndexRange}
                >
                    <AnnotationComponent
                        onPointerDown={handlePointerDown}
                        onPointerUp={handlePointerUp}
                        onPointerMove={handlePointerMove}
                        onTouchEnd={handlePointerUp}
                        type={type}
                        blackIcon={blackIcon}
                        highlight={highlight}
                        isDragging={isDragging}
                    />
                    {showLabel && (
                        <span
                            className={classes.label}
                        >
                                {title}
                            </span>
                    )}

                </Html>
            </mesh>
        );
    }
);
