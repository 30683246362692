import {Accordion, AccordionDetails, AccordionSummary, Box, Slider, Stack, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {TextField} from "../components/TextField";
import React, {useState} from "react";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";
import {useActiveMap} from "./useActiveMap";
import {updateMap} from "../App/service";

export const MapSettings = () => {
    const [isExpanded, setIsExpanded] = useState(true)
    const t = useThreeSixtyTranslation()
    const activeMap = useActiveMap()
    const {onChange, data} = useThreeSixtyProps()

    const handleToggleAccordion = () => {
        setIsExpanded(p => !p)
    }

    const onUpdate = (key, value) => {
        onChange(
            updateMap(
                data,
                activeMap.id,
                {
                    [key]: value
                }
            )
        )
    }

    const handleChangeTitle = value => onUpdate("title", value)

    const handleChangeIconSize = (_event, value) => onUpdate("iconSize", value)

    return (
        <Accordion
            disableGutters={true}
            elevation={0}
            square={true}
            expanded={isExpanded}
            onChange={handleToggleAccordion}
            sx={{
                backgroundColor: "grey.100"
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    {t("mapSettingsTitle", "Innstillinger for kartet")}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    spacing={2}
                >
                    <TextField
                        id="map-title-field"
                        label={t("mapTitle", "Tittel")}
                        variant="filled"
                        value={activeMap?.title}
                        onChange={handleChangeTitle}
                        sx={{
                            margin: "10px 0",
                            width: "100%"
                        }}
                    />
                    <Box>
                        <Typography
                            id="icon-size-slider"
                            gutterBottom={true}
                        >
                            {t('mapIconSize', "Størrelse ikoner")}
                        </Typography>
                        <Slider
                            aria-label="Temperature"
                            defaultValue={50}
                            value={activeMap?.iconSize || 50}
                            onChange={handleChangeIconSize}
                        />
                    </Box>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}