if (!('createImageBitmap' in window) ||  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    window.createImageBitmap = async function (blob) {
        return new Promise((resolve, reject) => {
            let img = document.createElement('img');
            img.addEventListener('load', function () {
                resolve(this);
            });
            img.src = URL.createObjectURL(blob);
        })
    }
}
