import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {DeleteOutline, ExpandMore, MyLocation} from "@mui/icons-material";
import React from "react";
import {TextField} from "../components/TextField";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";
import {useActiveMap} from "./useActiveMap";
import {updateMap} from "../App/service";
import {HOTSPOT_DESELECTED, HOTSPOT_SELECTED, useMapDispatch, useMapState} from "./mapContext";

export const MapHotspotSettings = ({hotspot}) => {
    const t = useThreeSixtyTranslation()
    const {data, onChange} = useThreeSixtyProps()
    const {activeHotspotId} = useMapState()
    const dispatch = useMapDispatch()
    const activeMap = useActiveMap()


    const handleToggleAccordion = () => {
        if (activeHotspotId === hotspot.id) {
            dispatch({type: HOTSPOT_DESELECTED})
        } else {
            dispatch({
                type: HOTSPOT_SELECTED,
                hotspotId: hotspot.id
            })
        }
    }

    const handleUpdateHotspot = ({key, value}) => {
        onChange(
            updateMap(
                data,
                activeMap.id,
                {
                    ...activeMap,
                    hotspots: [
                        ...activeMap.hotspots.filter(h => h.id !== hotspot.id),
                        {
                            ...hotspot,
                            [key]: value
                        }
                    ]
                }
            )
        )
    }

    const handleChangeLabel = value => handleUpdateHotspot({
        key: "label", value: value
    })

    const handleSelectDestination = event => {
        const value = event.target.value
        const destinationTitle = data?.domes.find(d => d.id === value)?.title
        onChange(
            updateMap(
                data,
                activeMap.id,
                {
                    ...activeMap,
                    hotspots: [
                        ...activeMap.hotspots.filter(h => h.id !== hotspot.id),
                        {
                            ...hotspot,
                            destination: event.target.value,
                            title: destinationTitle,
                            label: destinationTitle
                        }
                    ]
                }
            )
        )
    }

    const handleDelete = () => {
        onChange(
            updateMap(
                data,
                activeMap.id,
                {
                    ...activeMap,
                    hotspots: [
                        ...activeMap.hotspots.filter(h => h.id !== hotspot.id)
                    ]
                }
            )
        )
    }


    return (
        <Accordion
            disableGutters={true}
            elevation={5}
            square={true}
            expanded={activeHotspotId === hotspot.id}
            onChange={handleToggleAccordion}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    spacing={1}
                >
                    <MyLocation/>
                    <Typography>
                        {hotspot.title}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    spacing={2}
                >
                    <FormControl
                        variant="filled"
                    >
                        <InputLabel id="select-destination-label">
                            {t("selectDestinationLabel", "Destinasjon")}
                        </InputLabel>
                        <Select
                            labelId="select-destination-label"
                            id="select-destination-input"
                            value={hotspot.destination || ""}
                            onChange={handleSelectDestination}
                        >
                            {data.domes.map((d) => (
                                <MenuItem key={`destination-option-${d.id}`} value={d.id}>
                                    <Typography>{d.title || d.id}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="label-textfield"
                        label={t("mapHotSpotLabel", "Merkelapp")}
                        variant="filled"
                        value={hotspot?.label || ""}
                        onChange={handleChangeLabel}
                        fullWidth={true}
                    />
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={handleDelete}
                    >
                        <DeleteOutline/>
                        {t("deletePortalHotspot", "Slett Hotspot")}
                    </Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}