import {APP_SETUP, useThreeSixtyDispatch, useThreeSixtyState} from "./ThreeSixtyContext";
import {useEffect} from "react";

export const useSetupApp = (adminMode) => {
    const dispatch = useThreeSixtyDispatch()
    const {appReady} = useThreeSixtyState()

    useEffect(
        () => {
            const generateIndex = () => {
                if(adminMode) {
                    return "01"
                } else {
                    const randNum = Math.floor(Math.random() * 10) + 1;
                    return randNum < 10 ? `0${randNum}` : randNum.toString();
                }
            }

            dispatch({
                type: APP_SETUP,
                dmsIndex: generateIndex()
            })

        }, [adminMode]
    )
    return appReady
}
