import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {Controller} from "./Controller";
import {useAudioState} from "./audioContext";
import {Box} from '@mui/material';
import {useThreeSixtyProps} from "../App/ThreeSixtyContext";

export const AudioPlayer = () => {
    const {
        show,
        audioSrc,
        playing,
        currentEpisode
    } = useAudioState()
    const audioRef = useRef(null)
    const [, setCurrentTime] = useState(0);
    const [htmlAdded, setHtmlAdded] = useState(false)
    let interval;
    const {containerId} = useThreeSixtyProps()


    useEffect(
        () => () => {
            const audioElement = audioRef.current
            if(audioElement) {
                audioElement.pause();
                audioElement.currentTime = 0;
            }
        },
        []
    )

    useEffect(
        () => {
            if (playing && audioRef.current) {
                audioRef.current?.play()
                interval = setInterval(
                    () => setCurrentTime(audioRef.current?.currentTime),
                    1000
                )
            } else {
                audioRef.current?.pause()
                clearInterval(interval)
            }
        }, [playing, currentEpisode]
    )

    useEffect(() => {
        audioRef.current.src = audioSrc
    }, [audioSrc]);


    const renderPlayerController = () => {
        return createPortal(
            <Box>
                <audio ref={audioRef} />
                <Controller
                    audioRef={audioRef}
                    showControls={show}
                    setCurrentTime={setCurrentTime}
                    ready={htmlAdded}
                />
            </Box>,
            document.getElementById(containerId)
        );
    };

    return renderPlayerController()
};
