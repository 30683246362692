import {Typography} from "@mui/material";
import {useThreeSixtyProps} from "../App/ThreeSixtyContext";
import {MapPreviewHotspot} from "./MapPreviewHotspot";
import {MapAdminHotspot} from "./MapAdminHotspot";

const maxSize = 3.8
const minSize = 1.3
const minValue = 0
const maxValue = 100

export const MapHotspot = ({iconSize, hotspot}) => {
    const {adminMode} = useThreeSixtyProps()

    const normalizedValue = (iconSize - minValue) / (maxValue - minValue)
    const sizeInRem = minSize + (maxSize - minSize) * normalizedValue

    const mapHotspot = () => {
        if(adminMode) {
            return (
                <MapAdminHotspot
                    hotspotId={hotspot.id}
                    hotspotX={hotspot.x}
                    hotspotY={hotspot.y}
                    sizeInRem={sizeInRem}
                />
            )
        } else {
            return (
                <MapPreviewHotspot
                    destination={hotspot.destination}
                    hotspotX={hotspot.x}
                    hotspotY={hotspot.y}
                    sizeInRem={sizeInRem}
                />
            )
        }
    }

    return (
        <>
            {mapHotspot()}
            <Typography
                noWrap={true}
                sx={{
                    position: "absolute",
                    left: `${hotspot.x}%`,
                    top: `${hotspot.y}%`,
                    transform: 'translate(-50%, -50%)',
                    color: "white",
                    fontSize: "16px",
                    mt: `${Math.min(3.2, Math.max(2, sizeInRem)) + 0.5}rem`,
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    p: 0.5
                }}
            >
                {hotspot.label}
            </Typography>
        </>
    )
}