import React from "react";
import { createUseStyles } from "react-jss";
import { EditableInput } from "react-color/lib/components/common";
const useStyles = createUseStyles({
  field: { paddingLeft: "6px", width: "100%" },
  alpha: { paddingLeft: "6px", width: "100%" },
});

export const HSLFields = ({ style, hsl, onChange }) => {
  const handleChange = (data, e) => {
    if (data.h || data.s || data.l) {
      if (typeof data.s === "string" && data.s.includes("%")) {
        data.s = data.s.replace("%", "");
      }
      if (typeof data.l === "string" && data.l.includes("%")) {
        data.l = data.l.replace("%", "");
      }

      if (data.s === 1) {
        data.s = 0.01;
      } else if (data.l === 1) {
        data.l = 0.01;
      }

      onChange(
        {
          h: data.h || this.props.hsl.h,
          s: Number(data.s !== undefined ? data.s : this.props.hsl.s),
          l: Number(data.l !== undefined ? data.l : this.props.hsl.l),
          source: "hsl",
        },
        e
      );
    }
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.field}>
        <EditableInput
          style={style}
          label="h"
          value={Math.round(hsl.h)}
          onChange={handleChange}
        />
      </div>
      <div className={classes.field}>
        <EditableInput
          style={style}
          label="s"
          value={`${Math.round(hsl.s * 100)}%`}
          onChange={handleChange}
        />
      </div>
      <div className={classes.field}>
        <EditableInput
          style={style}
          label="l"
          value={`${Math.round(hsl.l * 100)}%`}
          onChange={handleChange}
        />
      </div>
      <div className={classes.alpha}>
        <EditableInput
          style={style}
          label="a"
          value={hsl.a}
          arrowOffset={0.01}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
