import React from "react";
import {VideoPlayer} from "../components/VideoPlayer";
import {getDmsStreamingUrl} from "@ekultur/dms-url-generator";
import {VideoTypes} from "../helpers/enums";
import YouTube from "react-youtube";
import {createUseStyles} from "react-jss";
import {MuiModal} from "../components/MuiModal";
import {useInnerSize} from "../mediaquery/useInnerSize";
import {useTheme} from "@mui/material";
import {PLAYER_CLOSED, useAudioDispatch, useAudioState} from "../audio/audioContext";

const useStyles = createUseStyles({
    youtubePlayer: {
        width: "100%",
        height: "100%"
    },
    video: {
        width: "100%",
        height: "100%"
    },
});

export const VideoModal = ({annotation}) => {
    const {playing: audioIsPlaying} = useAudioState()
    const audioDispatch = useAudioDispatch()
    const {title, content, videoType} = annotation;
    const dmsId = content?.video ? content.video : content?.media?.dmsId
    const video = getDmsStreamingUrl(dmsId, "01");
    const classes = useStyles();
    const {
        innerWidth,
        innerHeight
    } = useInnerSize()
    const {breakpoints} = useTheme()

    const maxWidthOrXl = Math.min((innerWidth * 0.85) - 48, breakpoints.values.xl - 48)

    const youtubeOptions = {
        width: maxWidthOrXl,
        height: Math.min(maxWidthOrXl / 16 * 9, innerHeight * 0.8)
    }

    const handlePlay = () => {
        if(audioIsPlaying) {
            audioDispatch({type: PLAYER_CLOSED})
        }
    }


    return (
        <MuiModal
            title={title}
        >
            {videoType === VideoTypes.DMS
                ? video && (
                <VideoPlayer
                    videoUrl={video}
                    controls={true}
                    className={classes.video}
                    onPlay={handlePlay}
                />
            )
                : content.youtube && (
                <YouTube
                    className={classes.youtubePlayer}
                    videoId={content?.youtube}
                    opts={youtubeOptions}
                    onPlay={handlePlay}
                />
            )}
            {content?.description && <p>{content?.description}</p>}
        </MuiModal>
    );
};
