import {ButtonBase} from "@mui/material";
import {Add} from "@mui/icons-material";

export const AddButton = ({show, onClick, children}) => {
    if(show) {
        return (
            <ButtonBase
                onClick={onClick}
                sx={{
                    height: 103,
                    backgroundColor: "#666666",
                    width: 208,
                    mt: 0.5,
                    ml: 1,
                    mr: 1,
                    color: "white",
                    "&:hover": {
                        boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.75)",
                    },
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px"
                }}
            >
                <Add />
                {children}
            </ButtonBase>
        )
    } else {
        return null
    }

}