import {DoubleSide, Vector3} from "three";
import React, {useState} from "react";
import {a} from "@react-spring/three";
import {Html, Svg} from "@react-three/drei";

import {CursorTypes, PortalTypes} from "../../helpers/enums";
import {setCursor} from "../../helpers";
import {Tooltip} from "../Tooltip";
import {useThreeSixtyState} from "../../App/ThreeSixtyContext";
import {iconOptions} from "./navigationIcons";
import tinycolor from 'tinycolor2'

const SCALE_FACTOR = 0.003;

export const NavigationIcon = ({
                                   scale = 1,
                                   color,
                                   hoverEffect = false,
                                   onClick,
                                   portalType = PortalTypes.FLOOR,
                                   tooltip,
                                   iconId = 'circle',
                                   iconRotation,
                               }) => {
    const {addHotspotType} = useThreeSixtyState();
    const [svgScale, setSvgScale] = useState(8.33)

    const iconUrl = () => {
        const iconDmsId = iconOptions.find(option => option.id === iconId).dmsId
        return `https://dms-cf-01.dimu.org/image/${iconDmsId}?mediaType=image/svg`
    }

    const [hover, setHover] = useState(false);
    const scaleVector = new Vector3(
        SCALE_FACTOR * scale,
        SCALE_FACTOR * scale,
        hoverEffect || onClick ? 1 : 0
    );

    const handlePointerEnter = () => {
        setSvgScale(8.33 * 1.1)
        if (!addHotspotType) {
            setCursor(CursorTypes.POINTER);
        }
        setHover(true);
    };

    const handlePointerLeave = () => {
        setSvgScale(8.33)
        if (!addHotspotType) {
            setCursor(CursorTypes.DEFAULT);
        }
        setHover(false);
    };


    const rotation = (type) => {
        switch (type) {
            case PortalTypes.FLOOR:
                return [Math.PI / 2, 0, Math.PI / 180 * iconRotation];
            case PortalTypes.WALL:
                return [0, 0, Math.PI / 180 * iconRotation];
            case PortalTypes.ROOF:
                return [-Math.PI / 2, 0, Math.PI / 180 * iconRotation];
            default:
                return undefined;
        }
    };

    const defaultColor = "white"
    const isDark = () => {
        const parsedTinyColor = tinycolor(color ? color.substring(0, 7) : defaultColor)
        return parsedTinyColor.isDark()
    }

    return (
        <a.mesh
            scale={scaleVector}
            rotation={rotation(portalType)}
            onPointerOver={() => hoverEffect && handlePointerEnter()}
            onPointerOut={() => hoverEffect && handlePointerLeave()}
        >
            <circleGeometry
                attach="geometry"
                args={[100, 48, 0, Math.PI * 2]}
            />
            <meshBasicMaterial
                attach="material"
                transparent={true}
                opacity={0}
                side={DoubleSide}
                depthTest={false}
            />
            <Svg
                src={iconUrl()}
                fillMaterial={{color: color ? color.substring(0, 7) : defaultColor}}
                strokeMaterial={{color: isDark() ? "white" : 'black'}}
                scale={svgScale}
                position={[-(24 * svgScale / 2), (24 * svgScale / 2), 0]}
            />
            {hover && tooltip && (
                <Html>
                    <Tooltip>{tooltip}</Tooltip>
                </Html>
            )}
        </a.mesh>
    );
};
