import {useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export const useImagesWithoutAdditionalDimensions = dmsIds => {
    const [dmsIdsWithoutDimensions, setDmsIdsWithoutDimensions] = useState([])


    useDeepCompareEffect(
        () => {
            Promise.all(
                dmsIds.map(dmsId => fetch(`https://mm.dimu.org/metadata/${dmsId}`)
                    .then(r => r.json())
                    .then(json => {
                        return {
                            dmsId: dmsId,
                            hasDimensions: json.additionalDimensions ? true : false
                        }
                    })
                    .catch(() => {
                        return {
                            dmsId: dmsId,
                            hasDimensions: false
                        }
                    })
                )
            ).then(values => {
                setDmsIdsWithoutDimensions(
                    values
                        .filter(v => !v.hasDimensions)
                        .map(v => v.dmsId)
                )
            })

        }, [dmsIds]
    )

    return dmsIdsWithoutDimensions
}