import React, { useEffect } from "react";
import Vivus, { EASE } from "vivus";
import { createUseStyles } from "react-jss";
import { getDmsUrl } from "@ekultur/dms-url-generator";

import { LogoGray } from "./LogoGray";
import { useThreeSixtyState } from "../../App/ThreeSixtyContext";

const useStyles = createUseStyles({
  loadingLogo: {
    width: 80,
    height: "86.73px",
    position: "relative",

    "& #my-svg": {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      height: "initial",
    },
    "& .invisible": {
      opacity: 0,
      animation: "$fadingIn ease-in .5s alternate",
      animationFillMode: "forwards",
      animationDuration: ".5s",
    },
    "& .invisible.two": {
      animationDelay: "1.8s",
    },
    "& .invisible.three": {
      animationDelay: "2.3s",
    },
  },
  "@keyframes fadingIn": {
    from: {
      opacity: 0,
    },
    to: { opacity: 1 },
  },
  "@keyframes fadingOut": {
    from: {
      opacity: 1,
    },
    to: { opacity: 0 },
  },
});

export const LoadingLogo = ({ className }) => {
  const classes = useStyles();
  const { dmsIndex } = useThreeSixtyState();
  const url = `${getDmsUrl("013AmsnrYKvX", dmsIndex)}?mediaType=image/svg`;

  useEffect(() => {
    new Vivus(
      "loading-logo",
      {
        duration: 160,
        file: url,

        animTimingFunction: EASE,
        type: "oneByOne",
      },
      (animation) => {
        animation.play(animation.getStatus() === "end" ? -1 : 1);
      }
    );
  }, []);

  return (
    <>
      <div id="loading-logo" className={`${classes.loadingLogo} ${className}`}>
        <LogoGray />
      </div>
    </>
  );
};
