import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    domes: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: "103px",
    },
})

export const SidebarContainer = ({children}) => {
    const classes = useStyles();
    return (
        <div className={classes.domes}>
            {children}
        </div>
    );
}