import {MAP_DESELECTED, SET_ACTIVE_DOME, useThreeSixtyDispatch} from "../App/ThreeSixtyContext";
import React from "react";
import {iconOptions} from "../components/icons/navigationIcons";
import {IconButton} from "@mui/material";

export const MapPreviewHotspot = ({destination,hotspotX, hotspotY, sizeInRem}) => {
    const dispatch = useThreeSixtyDispatch()

    const handleClick = () => {
        dispatch({
            type: SET_ACTIVE_DOME,
            domeId: destination
        })
        dispatch({type: MAP_DESELECTED})
    }


    const iconUrl = () => {
        const iconDmsId = iconOptions.find(option => option.id === 'circle').dmsId
        return `https://dms-cf-01.dimu.org/image/${iconDmsId}?mediaType=image/svg%2Bxml`
    }

    return (
        <IconButton
            onClick={handleClick}
            sx={{
                position: "absolute",
                left: `${hotspotX}%`,
                top: `${hotspotY}%`,
                transform: 'translate(-50%, -50%)',
                "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                },
                'img': {
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    width: `calc(${sizeInRem}rem + 20px)`,
                    height: `calc(${sizeInRem}rem + 20px)`,
                    "&:hover": {
                        cursor: "pointer"
                    },
                },
            }}
        >
            <img
                src={iconUrl()}
            />
        </IconButton>
    )
}