import React from "react";
import {createUseStyles} from "react-jss";
import {placeholderBackground} from "../../helpers/misc";
import {useThreeSixtyTranslation} from "../../App/ThreeSixtyContext";
import {TextField} from "../../components/TextField";
import {useDmsImage} from "../../media/useDmsImage";

const useStyles = createUseStyles({
  placeholderVideo: {
    width: "100%",
    height: "150px",
    objectFit: "contain",
  },
  youtubePlayer: {
    width: "100%",
    height: "150px",
  },
});
export const YoutubeTab = ({ content, updateContent }) => {
  const getYoutubePreview = (id) =>
    `https://img.youtube.com/vi/${id}/mqdefault.jpg`;

  const t = useThreeSixtyTranslation();
  const classes = useStyles();

  const typeImageUrl = useDmsImage({
    id: placeholderBackground("video")
  })

  return (
    <>
      {!content?.youtube ? (
        <div
          className={classes.placeholderVideo}
          style={{
            backgroundImage: `url(${typeImageUrl})`,
          }}
        />
      ) : (
        <img src={getYoutubePreview(content?.youtube)} alt="youtube video" />
      )}
      <TextField
        id="youtubeVideoId"
        label={t("youtubeIdLabel", "Youtube kode")}
        variant="filled"
        sx={{ margin: "10px 0" }}
        value={content?.youtube}
        onChange={(value) => updateContent("youtube", value)}
      />
    </>
  );
};
