import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  circlePointer: {
    width: "12px",
    height: "12px",
    borderRadius: "6px",
    boxShadow: "inset 0 0 0 1px #fff",
    transform: "translate(-6px, -6px)",
  },
});

export const CirclePointer = () => {
  const classes = useStyles();
  return <div className={classes.circlePointer} />;
};
