import React from "react";
import {createUseStyles} from "react-jss";
import {PlayCircle, PauseCircle} from "react-feather";
import {Stack, Typography} from "@mui/material";
import {PAUSE_TRIGGERED, PLAY_TRIGGERED, useAudioDispatch, useAudioState} from "./audioContext";

const useStyles = createUseStyles({
    playerTrigger: {
        display: "flex",
        width: "100%"
    },
    narrateButton: {
        backgroundColor: "#ffffff",
        border: "0",
        "&:hover": {
            cursor: "pointer",
        }
    },
});

export const AudioContent = ({}) => {
    const classes = useStyles();

    const {
        currentEpisode,
        isPodcast,
        playing
    } = useAudioState()

    const dispatch = useAudioDispatch()


    const handlePlay = () => dispatch({type: PLAY_TRIGGERED})

    const handlePause = () => dispatch({type: PAUSE_TRIGGERED})

    const toggleShowControls = () => {
        if(playing) {
            handlePause()
        } else {
            handlePlay()
        }
    }

    const podcastContent = () => {
        if (isPodcast) {
            return (
                <Stack>
                    <Typography
                        variant="sub"
                        sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            lineHeight: "12px",
                            width: "100%",
                            height: "100%",
                            margin: "0",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {currentEpisode?.title}
                    </Typography>
                    <Typography
                        variant="sub"
                        sx={{
                            fontSize: "10px",
                            fontWeight: "normal",
                            lineHeight: "10px",
                            width: 240
                        }}
                    >
                        <div dangerouslySetInnerHTML={{__html: currentEpisode?.summary}}/>
                    </Typography>
                </Stack>
            )
        } else {
            return null
        }
    }

    const buttonIcon = {
        true: (
            <PauseCircle
                size={50}
            />
        ),
        false: (
            <PlayCircle
                size={50}
            />
        )
    }

    return (
        <Stack
            direction={"row"}
        >
            <button className={classes.narrateButton} onClick={toggleShowControls}>
                {buttonIcon[playing]}
            </button>
            {podcastContent()}
        </Stack>
    )
}
