import {Button} from "@mui/material";

export const SelectMapButton = ({onClick, map}) => {

    const handleClick = () => onClick(map.id)

    return (
        <Button
            sx={{
                color: "black",
                justifyContent: "flex-start"
            }}
            fullWidth={true}
            onClick={handleClick}
        >
            {map.title}
        </Button>
    )
}