import React from "react";
import {Stack, useTheme} from "@mui/material";
import {Visualizer} from "./Visualizer";

export const Cover = ({isCurrentEpisode, children}) => {
    const theme = useTheme()

    return (
        <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
                width: 80,
                height: 80,
                backgroundColor: theme.palette.grey[300],
                borderRadius: "6px",
            }}
        >
            <Visualizer
                show={isCurrentEpisode}
            />
            {children}
        </Stack>
    )
}