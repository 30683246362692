import {getIntersectsFromCamera, getMousePosition, isDome, isObject, setCursor} from "../helpers";
import {CursorTypes} from "../helpers/enums";
import {SET_ACTIVE_HOTSPOT, SET_CAMERA_CONTROLS_ENABLED, useThreeSixtyDispatch} from "../App/ThreeSixtyContext";
import {useThree} from "@react-three/fiber";
import {forwardRef, useEffect, useState} from "react";

export const AdminPortalMesh = forwardRef(({
                                               id,
                                               isDragging,
                                               setIsDragging,
                                               onChange,
                                               portalRef,
                                               name,
                                               portalType,
                                               position,
                                               children
                                           }, domeRef) => {
        const {
            camera,
            scene
        } = useThree()
        const dispatch = useThreeSixtyDispatch()

        const [dragPosition, setDragPosition] = useState(position);

        useEffect(() => {
            setDragPosition(position);
        }, [position]);

        const dragStartedHandler = () => setIsDragging(true)

        const dragEndedHandler = () => setIsDragging(false)

        const handlePointerMove = (event) => {
            event.stopPropagation()
            if (event.buttons === 1 && isDragging) {
                const mouse = getMousePosition(event);
                const intersections = getIntersectsFromCamera(camera, scene, mouse);
                const domeIntersected = intersections.find((i) => isDome(i.object));
                const surfaceIntersected = intersections.find((i) =>
                    isObject(portalType, i.object)
                );
                const isSurfaceFirst =
                    domeIntersected?.distance - 0.5 > surfaceIntersected?.distance;
                if (surfaceIntersected && isSurfaceFirst) {
                    const local = domeRef.current.worldToLocal(
                        surfaceIntersected.point.clone()
                    );
                    setCursor(CursorTypes.GRABBING);
                    setDragPosition(local);
                } else {
                    setCursor(CursorTypes.NOT_ALLOWED);
                }
            }
        };

        const handlePointerDown = event => {
            event.stopPropagation()
            setCursor(CursorTypes.GRABBING);
            dragStartedHandler()
            dispatch({
                type: SET_CAMERA_CONTROLS_ENABLED,
                cameraControlsEnabled: false,
            });
            dispatch({
                type: SET_ACTIVE_HOTSPOT,
                hotspotId: id,
            });
        };

        const handlePointerUp = event => {
            event.stopPropagation()
            setCursor(CursorTypes.GRAB);
            dragEndedHandler()
            if (position !== dragPosition) {
                onChange(id, {position: dragPosition});
            }
            dispatch({
                type: SET_CAMERA_CONTROLS_ENABLED,
                cameraControlsEnabled: true,
            });
        };

        return (
            <mesh
                ref={portalRef}
                name={name}
                position={dragPosition}
                onPointerMove={handlePointerMove}
                onPointerDown={handlePointerDown}
                onPointerUp={handlePointerUp}
                onTouchEnd={handlePointerUp}
            >
                {children}
            </mesh>
        )
    }
)