import React from "react";
import { DoubleSide } from "three";

export const Wall = () => {
  return (
    <mesh name="Wall" visible={false}>
      <cylinderGeometry attach="geometry" args={[7, 7, 7, 32, 1, true]} />
      <meshBasicMaterial
        attach="material"
        transparent={true}
        opacity={0}
        side={DoubleSide}
      />
    </mesh>
  );
};
