import React from "react";
import {theme} from "@ekultur/vm";
import {ThemeProvider} from "react-jss";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

import {ThreeSixty} from "../Canvas/ThreeSixty";
import {Admin} from "../Admin/Admin";
import {ThreeSixtyProvider} from "./ThreeSixtyContext";
import {useSetupApp} from "./useSetupApp";
import {TabProvider} from "../Sidebar/tabContext";
import {RenderIfAdminMode} from "../Admin/RenderIfAdminMode";
import {RenderUnlessAdminMode} from "../Admin/RenderUnlessAdminMode";
import {MapProvider} from "../map/mapContext";
import {AudioProvider} from "../audio/audioContext";

export const adminTheme = {
    ...theme,
    colors: {
        ...theme.colors,
        adminBlue: "#4484F1",
        adminGray: "#9E9E9E",
        primaryColor: "#1B8751",
    },
};

export const App = ({
                        data,
                        adminMode = false,
                        onClose,
                        onMediaSelect,
                        onChange,
                        sidePanel,
                        onFeedback,
                        containerId
                    }) => {
    const appReady = useSetupApp(adminMode);
    return (
        <ThemeProvider theme={adminTheme}>
            <DndProvider backend={HTML5Backend}>
                <ThreeSixtyProvider
                    data={data}
                    onMediaSelect={onMediaSelect}
                    onChange={onChange}
                    adminMode={adminMode}
                    sidePanel={sidePanel}
                    appReady={appReady}
                    onFeedback={onFeedback}
                    containerId={containerId}
                >
                    <TabProvider>
                        <MapProvider>
                            <RenderIfAdminMode>
                                <Admin/>
                            </RenderIfAdminMode>
                            <RenderUnlessAdminMode>
                                <ThreeSixty onClose={onClose}/>
                            </RenderUnlessAdminMode>
                        </MapProvider>
                    </TabProvider>
                </ThreeSixtyProvider>
            </DndProvider>
        </ThemeProvider>
    );
};
