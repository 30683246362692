import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({ colors }) => ({
  more: {
    display: "flex",
    zIndex: 1000,
    position: "absolute",
    background: colors.adminGray,
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px, rgb(0 0 0 / 12%) 0px 1px 14px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "2px",
    right: 0,
    top: "41px",
    width: "max-content",
  },
}));

export const ContextMenu = ({show, children}) => {
  const classes = useStyles();

  if(show) {
    return (
        <div className={classes.more}>
          {children}
        </div>
    )
  } else {
    return null
  }
}
