import {
    Euler,
    MathUtils,
    Raycaster,
    SkinnedMesh,
    Spherical,
    Vector2,
    Vector3,
} from "three";
import {clone} from "three/examples/jsm/utils/SkeletonUtils";
import {useGLTF as dreiUseGltf} from "@react-three/drei";
import {HotspotTypes, PortalTypes} from "./enums";

export const isAnimatedModel = (model) => {
    let animated = false;
    model.traverse((obj) => {
        if (obj instanceof SkinnedMesh) animated = true;
    });
    return animated;
};

export const copyScene = (scene) => {
    return isAnimatedModel(scene) ? clone(scene) : scene.clone();
};

export const useGLTF = (url) => {
    return dreiUseGltf(
        url,
        "https://www.gstatic.com/draco/versioned/decoders/1.4.3/"
    );
};

export const sphericalDegToRad = (elevation, azimuth) => {
    const phi = MathUtils.degToRad(90 - elevation);
    const theta = MathUtils.degToRad(azimuth);
    return new Vector3().setFromSphericalCoords(0.001, phi, theta);
};

export const getIntersectsFromCamera = (camera, scene, coords) => {
    const raycaster = new Raycaster();
    raycaster.setFromCamera(coords, camera);
    return raycaster.intersectObjects(scene.children, true);
};

export const getSceneElementByName = (elements, name) => {
    const nestedFindByName = (name) => (elements) =>
        elements.reduce(
            (result, element) =>
                result
                    ? result
                    : element.name === name
                        ? element
                        : nestedFindByName(name)(element.children || []),
            undefined
        );

    return nestedFindByName(name)(elements);
};

export const getMousePosition = (event) => {
    const canvas = document.getElementById("canvasContainer");
    const rect = canvas.getBoundingClientRect();

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const mouse = new Vector2();
    mouse.x = (x / rect.width) * 2 - 1;
    mouse.y = -(y / rect.height) * 2 + 1;
    return mouse;
};

export const getHorizontalAngleFromZero = (coordinate) =>
    new Spherical().setFromVector3(coordinate).theta;

export const isObject = (objectType, object) => {
    switch (objectType) {
        case "Dome":
            return isDome(object);
        case "Floor":
            return isFloor(object);
        case "Wall":
            return isWall(object);
        case "Roof":
            return isRoof(object);
        default:
            return undefined;
    }
};
export const isDome = (object) => object.name?.startsWith("Dome");
export const isFloor = (object) => object.name?.startsWith("Floor");
export const isWall = (object) => object.name?.startsWith("Wall");
export const isRoof = (object) => object.name?.startsWith("Roof");

export const isAnnotation = (object) =>
    object.name?.startsWith("Annotation")
        ? true
        : object.parent
            ? isAnnotation(object.parent)
            : false;

export const isPortal = (object) =>
    object.name?.startsWith("Portal")
        ? true
        : object.parent
            ? isPortal(object.parent)
            : false;

export const convertToThreeJsTypes = (object) => {
    if (object !== null && typeof object === "object") {
        Object.entries(object).forEach(([key, value]) => {
            if (
                ["position", "viewDirection", "scale"].includes(key) &&
                !Array.isArray(value) &&
                typeof value === "object"
            ) {
                object[key] = Object.assign(new Vector3(), value);
            } else if (["rotation"].includes(key) && !Array.isArray(value)) {
                object[key] = Object.assign(new Euler(), value);
            } else {
                convertToThreeJsTypes(value);
            }
        });
    }

    return object;
};

export const sphericalToXYZ = (theta, phi, radius) => {
    const pos = new Vector3();
    pos.x = radius * Math.sin(theta) * Math.cos(phi);
    pos.y = radius * Math.sin(theta) * Math.sin(phi);
    pos.z = radius * Math.cos(theta);
    return pos;
};

export const getLocalDomePositionByMouseMove = (
    event,
    camera,
    scene,
    domeRef
) => {
    const mouse = getMousePosition(event);
    const intersections = getIntersectsFromCamera(camera, scene, mouse);
    const domeIntersected = intersections.find((i) => isDome(i.object));
    if (domeIntersected) {
        return domeRef.current.worldToLocal(domeIntersected.point.clone());
    } else return undefined;
};

export const getHotspotAddPosition = (e, hotspotType, ref) => {
    if (isDome(e.object)) {
        if (hotspotType === HotspotTypes.PORTAL) {
            const surfaceIntersected = e.intersections.find((i) =>
                isObject(PortalTypes.FLOOR, i.object)
            );
            const isSurfaceFirst = e?.distance - 0.5 > surfaceIntersected?.distance;
            if (surfaceIntersected && isSurfaceFirst) {
                return ref.current.worldToLocal(surfaceIntersected.point.clone());
            } else {
                return undefined;
            }
        } else {
            return ref.current.worldToLocal(e.point.clone());
        }
    }
};
