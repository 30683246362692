import React from "react";
import {getAudioUrl} from "@ekultur/dms-url-generator";
import {MuiModal} from "../components/MuiModal";
import {Box, Stack} from "@mui/material";
import {useAudio} from "../audio/useAudio";
import {AudioContent} from "../audio/AudioContent";

export const AudioModal = ({annotation}) => {
    const {title, content, audioType} = annotation;
    const dmsId = content?.audio ? content.audio : content?.media?.dmsId
    const audio = dmsId ? getAudioUrl(dmsId, "01") : undefined;
    useAudio({
        audioType: audioType,
        audio: audio,
        rss: content?.rss
    })

    return (
        <MuiModal
            title={title}
        >
            <Stack
                direction={"row"}
                sx={{
                    width: "100%"
                }}
            >
                <Box
                    sx={{
                        width: "100%"
                    }}
                >
                    <AudioContent />
                    {content?.description && <p>{content?.description}</p>}
                </Box>
            </Stack>
        </MuiModal>
    )
}
