import {useTexture} from "@react-three/drei";
import {useBitmapUrl} from "../media/useBitmapUrl";
import {EquirectangularReflectionMapping, LinearFilter} from "three";

export const useTextureLoad = imageDmsIds => {
    const bitmapUrls = useBitmapUrl(imageDmsIds)

    const textures = useTexture(
        bitmapUrls.map(bitmapUrl => bitmapUrl.url),
        loadedTextures => {
            loadedTextures.forEach(t => {
                t.minFilter = LinearFilter
                t.mapping = EquirectangularReflectionMapping
            })
        }
    )

    return imageDmsIds.reduce((acc, current, index) => ({
        ...acc,
        [current]: textures[index]
    }), {})
};
