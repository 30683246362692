export const CursorTypes = Object.freeze({
  SHOE_PRINT: "shoe-print",
  PLAY: "play",
  PAUSE: "pause",
  EYE: "eye",
  GRAB: "grab",
  GRABBING: "grabbing",
  POINTER: "pointer",
  DEFAULT: "default",
  MOVE: "move",
  NESW_RESIZE: "nesw-resize",
  NWSE_RESIZE: "nwse-resize",
  EW_RESIZE: "ew-resize",
  NS_RESIZE: "ns-resize",
  NOT_ALLOWED: "not-allowed",
  CROSSHAIR: "crosshair",
});

export const ModelTypes = Object.freeze({
  OBJECT: "Object",
  IMAGE_SINGLE: "ImageSingle",
  IMAGE_DOUBLE: "ImageDouble",
});

export const HotspotTypes = Object.freeze({
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  THREE_D: "3d",
  PORTAL: "portal",
  AUDIO: "audio",
});

export const PortalTypes = Object.freeze({
  WALL: "Wall",
  FLOOR: "Floor",
  ROOF: "Roof",
});

export const VideoTypes = Object.freeze({
  DMS: "Dms",
  YOUTUBE: "Youtube",
});

export const AudioTypes = Object.freeze({
  DMS: "Dms",
  RSS: "Rss",
});
