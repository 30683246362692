import React, {useRef} from "react";
import {useThree} from "@react-three/fiber";
import {OrbitControls, PerspectiveCamera} from "@react-three/drei";

import {useThreeSixtyState} from "../App/ThreeSixtyContext";
import {animated} from "@react-spring/three";

const AnimatedOrbitControls = animated(OrbitControls);
const AnimatedPerspectiveCamera = animated(PerspectiveCamera);

export const CameraControls = ({
  rotateSpeed,
  dampingFactor,
  enableDamping,
  enablePan,
}) => {
  const { cameraControlsEnabled } = useThreeSixtyState();
  const controls = useRef();
  const { camera, gl } = useThree();


  return (
    <>
      <AnimatedOrbitControls
        ref={controls}
        rotateSpeed={rotateSpeed}
        dampingFactor={dampingFactor}
        enableDamping={enableDamping}
        enablePan={enablePan}
        enableZoom={false}
        target={[0, 0, 0.001]}
        args={[camera, gl.domElement]}
        enabled={cameraControlsEnabled}
      />
      <AnimatedPerspectiveCamera makeDefault far={100} near={0.1} fov={80} />
    </>
  );
};
