import {Text} from "@react-three/drei";
import React, {useRef} from "react";
import {Vector3} from "three";
import {useFrame} from "@react-three/fiber";

export const PortalLabel = ({scale, showLabel, children}) => {
    const text = useRef()

    useFrame(
        () => {
            if(text.current) {
                text.current.lookAt(new Vector3(0, 0, 0))
            }

        },
    )
    if (showLabel) {
        return (
            <Text
                ref={text}
                fontSize={Math.max(0.1 * scale, 0.05)}
                position={[0, 0, 0]}
                maxWidth={1 * scale}
                textAlign={"center"}
                anchorX={"center"}
                anchorY={scale / 4}
                outlineBlur={"15%"}
                outlineColor={"rgba(0,0,0, 0.75)"}
            >
                {children}
            </Text>
        )
    } else {
        return null
    }

}