import React from "react";
import {DoubleSide} from "three";
import {PortalTypes} from "../helpers/enums";

export const PortalHelper = ({show, portalType}) => {
    const getRotation = (type) => {
        switch (type) {
            case PortalTypes.FLOOR:
                return [Math.PI / 2, Math.PI, Math.PI / 2];
            case PortalTypes.ROOF:
                return [Math.PI / 2, Math.PI, Math.PI / 2];
            case PortalTypes.WALL:
                return [0, 0, 0];
            default:
                return undefined;
        }
    }

    if (show) {
        return (
            <mesh
                name="PortalHelper"
                visible={false}
                rotation={getRotation(portalType)}
            >
                <planeGeometry args={[100, 100]}/>
                <meshBasicMaterial
                    transparent={true}
                    opacity={0}
                    side={DoubleSide}
                />

            </mesh>
        );
    } else {
        return null
    }


};
