import {useThreeSixtyProps} from "../App/ThreeSixtyContext";

export const RenderIfAdminMode = ({children}) => {
    const {adminMode} = useThreeSixtyProps()

    if(adminMode) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return null
    }
}