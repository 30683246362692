export const PortalMesh = ({position, portalRef, name, onClick, children}) => {
    return (
        <mesh
            ref={portalRef}
            name={name}
            position={position}
            onPointerUp={onClick}
            onTouchEnd={onClick}
        >
            {children}
        </mesh>
    )
}