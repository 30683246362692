import React from "react";
import {styled, ToggleButton, ToggleButtonGroup, Tooltip,} from "@mui/material";
import {VolumeUp, FormatAlignLeftOutlined, MyLocation, PhotoLibrary, VideoLibraryOutlined,} from "@mui/icons-material";
import {
  ADD_HOTSPOT_TYPE,
  useThreeSixtyDispatch,
  useThreeSixtyState,
  useThreeSixtyTranslation,
} from "../App/ThreeSixtyContext";
import {createUseStyles} from "react-jss";
import {CursorTypes, HotspotTypes} from "../helpers/enums";
import {setCursor} from "../helpers";

const useStyles = createUseStyles({
  addHotspotBar: {
    position: "absolute",
    bottom: "56px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);",
    borderRadius: "4px",
    padding: "8px 12px",
  },
});

const StyledToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#1B8751",
  },
});

export const AddHotspotBar = () => {
  const t = useThreeSixtyTranslation();
  const { addHotspotType } = useThreeSixtyState();
  const dispatch = useThreeSixtyDispatch();

  const add = (_e, type) => {
    if (addHotspotType !== type) {
      setCursor(CursorTypes.CROSSHAIR);
      dispatch({ type: ADD_HOTSPOT_TYPE, addHotspotType: type });
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.addHotspotBar}>
      <ToggleButtonGroup
        value={addHotspotType}
        exclusive
        onChange={add}
        aria-label="text alignment"
      >
        <StyledToggleButton
          value={HotspotTypes.TEXT}
          aria-label="left aligned"
          sx={{ margin: "0 4px", border: "none" }}
        >
          <Tooltip title={t("addHotspotTextButton", "Legg til tekstboks")}>
            <FormatAlignLeftOutlined />
          </Tooltip>
        </StyledToggleButton>

        <StyledToggleButton
          value={HotspotTypes.IMAGE}
          aria-label="centered"
          sx={{ margin: "0 4px", border: "none" }}
        >
          <Tooltip title={t("addHotspotImageButton", "Legg til bildeboks")}>
            <PhotoLibrary />
          </Tooltip>
        </StyledToggleButton>

        <StyledToggleButton
          value={HotspotTypes.VIDEO}
          aria-label="centered"
          sx={{ margin: "0 4px", border: "none" }}
        >
          <Tooltip title={t("addHotspotVideoButton", "Legg til videoboks")}>
            <VideoLibraryOutlined />
          </Tooltip>
        </StyledToggleButton>
        <StyledToggleButton
          value={HotspotTypes.AUDIO}
          aria-label="centered"
          sx={{ margin: "0 4px", border: "none" }}
        >
          <Tooltip title={t("addHotspotAudioButton", "Legg til Lyd")}>
            <VolumeUp />
          </Tooltip>
        </StyledToggleButton>
        <StyledToggleButton
          value={HotspotTypes.PORTAL}
          aria-label="centered"
          sx={{ margin: "0 4px", border: "none" }}
        >
          <Tooltip title={t("addHotspotPortalButton", "Legg til portal")}>
            <MyLocation />
          </Tooltip>
        </StyledToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
