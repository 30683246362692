import {getDmsUrl} from "@ekultur/dms-url-generator";
import {useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

const isMobile = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(navigator.userAgent || navigator.vendor || window.opera)
const getDimension = availableDimensions => {
    if(isMobile) {
        return availableDimensions.includes('4096x2048') ? '4096x2048' : '2048'
    } else {
        return availableDimensions.includes('8192x4096') ? '8192x4096' : availableDimensions.includes('4096x2048') ? '4096x2048' : '2048'
    }
}
export const useBitmapUrl = dmsIds => {
    const [urls, setUrls] = useState([])


    useDeepCompareEffect(
        () => {
            Promise.all(
                dmsIds.map(dmsId => fetch(`https://mm.dimu.org/metadata/${dmsId}`)
                    .then(r => r.json())
                    .then(json => {
                        return {
                            dmsId: dmsId,
                            dimension: getDimension(json.additionalDimensions ? json.additionalDimensions : [])
                        }
                    })
                    .catch(() => {
                        return {
                            dmsId: dmsId,
                            dimension: getDimension([])
                        }
                    })
                )
            ).then(values => {
                const valuesWithUrls = values.map(value => ({
                    ...value,
                    url: `${getDmsUrl(value.dmsId, "01")}?dimension=${value.dimension}`
                }))
                setUrls(valuesWithUrls)
            })

        }, [dmsIds]
    )

    return urls
}