import React from "react";
import {a} from "@react-spring/three";
import {DoubleSide} from "three";
import {fragmentShader, vertexShader} from "./CrossFadeMaterial";

export const SphereComponent = ({ fromTexture, toTexture, opacity}) => {

  return (
    <>
      <sphereGeometry attach="geometry" args={[10, 60, 40]} />
      <a.shaderMaterial
        attach="material"
        args={[
          {
            uniforms: {
              mixFactor: { type: "f", value: 0 },
              fromTex: { type: "t", value: fromTexture },
              toTex: { type: "t", value: toTexture },
            },
            vertexShader,
            fragmentShader,
          },
        ]}
        uniforms-mixFactor-value={opacity}
        side={DoubleSide}
      />
    </>
  )
}
