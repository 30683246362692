export const demo4 = {
    about: {
        exhibition: {
            name: "Besøk Gamle Hvam museum",
            description:
                "Dykk ned i Linderud gårds unike historie, menneskene som bodde her og overgangen fra ruvende storgård til sammensmeltning med drabantbyen",
            url: null,
        },
        museum: {
            name: "MiA",
            url: "https://beta.virtueltmuseum.no/museum/74",
        },
        imageUrl: null,
    },
    domes: [
        {
            "hotspots": [
                {
                    "domeId": 43,
                    "iconId": "arrow-in-circle",
                    "iconRotation": 96,
                    "id": 1,
                    "label": "",
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.7301236403998352,
                        "y": -0.6,
                        "z": 0.13165210275130357
                    },
                    "showLabel": false,
                    "size": 1.1,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "iconId": "circle",
                    "iconRotation": 164,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 7.01856295165037,
                        "y": -0.5999999999999999,
                        "z": 0.20173357030830807
                    },
                    "size": 0.6,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBtFnvm6vj"
                    },
                    "id": 3,
                    "order": 3,
                    "position": {
                        "x": 7.295410320355025,
                        "y": -0.7770208697621451,
                        "z": -6.78422859637548
                    },
                    "showLabel": true,
                    "title": "En eldre Svalgangsbygning:",
                    "type": "image"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "6AIDhG-PpNE"
                    },
                    "id": 4,
                    "order": 4,
                    "position": {
                        "x": 8.611109512810515,
                        "y": 5.02180307291696,
                        "z": 0.5548818489082223
                    },
                    "showLabel": true,
                    "title": "\"Svalgangsbygningen\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "xXAfzsaI0YU"
                    },
                    "id": 5,
                    "order": 5,
                    "position": {
                        "x": -8.538125581732853,
                        "y": -1.2575609974025763,
                        "z": 5.028896379219711
                    },
                    "showLabel": true,
                    "title": "\"Elis tre\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "bUNS9bSHXRM"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": -8.122703025269951,
                        "y": -0.5019992098766778,
                        "z": -5.791591248974826
                    },
                    "showLabel": true,
                    "title": "Arnstein Arneberg",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "nN8x6pHkqQo"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": -3.5035730851725324,
                        "y": -0.5542226749348154,
                        "z": -9.328581700045058
                    },
                    "showLabel": true,
                    "title": "\"Bondeopprøret\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "lx6o95UbIbY"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 1.04280192969056,
                        "y": 1.6783792632905528,
                        "z": -9.798197609514506
                    },
                    "showLabel": true,
                    "title": "\"Pengesekkene\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 20,
            "image": "019EBsk7pWqip",
            "modified": true,
            "order": 1,
            "title": "Gamle Hvam Fasade.jpg",
            "viewDirection": {
                "x": 9.951431138154625,
                "y": 0.8206087674214974,
                "z": 0.2572004244709323
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 20,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0194390143484624,
                        "y": -0.5999999999999998,
                        "z": 1.4622247452636867
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "iconId": "arrow-in-circle",
                    "iconRotation": 35,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5437743647768891,
                        "y": -0.6000000000000002,
                        "z": -0.7515220448270383
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4755386568917497,
                        "y": -0.6000000000000005,
                        "z": -2.150384361689685
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuZtf"
                    },
                    "id": 4,
                    "order": 4,
                    "position": {
                        "x": 1.3509367626352815,
                        "y": -3.9099655763613517,
                        "z": -9.088422592439343
                    },
                    "type": "image",
                    "whiteBackground": false
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "xXAfzsaI0YU"
                    },
                    "id": 5,
                    "order": 5,
                    "position": {
                        "x": -2.8181704352124193,
                        "y": -1.118686772419465,
                        "z": 9.510695030013785
                    },
                    "showLabel": true,
                    "title": "\"Elis tre\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "bUNS9bSHXRM"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": -8.209792238142423,
                        "y": -1.1581441509066595,
                        "z": 5.55606054248717
                    },
                    "showLabel": true,
                    "title": "\"Arnstein Arneberg\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "nN8x6pHkqQo"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": -9.929000289556553,
                        "y": -0.6748366253798014,
                        "z": 0.8488320312650002
                    },
                    "showLabel": true,
                    "title": "\"Bondeopprøret\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": ""
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -9.217584212005773,
                        "y": 1.2630066778270677,
                        "z": -3.6097197079725385
                    },
                    "showLabel": true,
                    "title": "\"Pengesekkene\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "6AIDhG-PpNE"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -3.527363279342258,
                        "y": -7.906177728889582,
                        "z": 4.986141968260145
                    },
                    "showLabel": true,
                    "title": "\"Svalgangsbygningen\"",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 43,
            "image": "019EBskETycqf",
            "modified": true,
            "order": 21,
            "title": "1.jpg",
            "viewDirection": {
                "x": 6.42282764864914,
                "y": -1.0487122894928962,
                "z": -7.569075800877136
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 43,
                    "iconId": "arrow-in-circle",
                    "iconRotation": 273,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3459373394478906,
                        "y": -0.6000000000000001,
                        "z": -0.2753931619371277
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "iconRotation": 96,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.378044467874833,
                        "y": -0.6000000000000001,
                        "z": -0.5626811444711721
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.10789275943595061,
                        "y": -0.5999999999999998,
                        "z": 1.0563474897393519
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8267908973157057,
                        "y": -0.6,
                        "z": -0.029605150153937526
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6627222621721197,
                        "y": -0.6000000000000003,
                        "z": -1.1220612931916498
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 25,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.4491940101136875,
                        "y": -0.6000000000000001,
                        "z": 0.003269393809578268
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.19305862064390145,
                        "y": -0.5999999999999996,
                        "z": 2.023067125393197
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9913659261356227,
                        "y": -0.6000000000000004,
                        "z": -2.2296468217051233
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuZtd"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": 8.533328047892336,
                        "y": -3.6775063757775834,
                        "z": -3.6682981927618936
                    },
                    "type": "image",
                    "whiteBackground": false
                },
                {
                    "content": {
                        "image": "019EBskEZuZy1"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 7.422115137110925,
                        "y": -1.353225547499312,
                        "z": -6.547116665899965
                    },
                    "type": "image",
                    "whiteBackground": false
                },
                {
                    "content": {
                        "image": "019EBskEZuaMB"
                    },
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": 1.2875603820853885,
                        "y": -2.2483651125029893,
                        "z": 9.644798732486398
                    },
                    "type": "image",
                    "whiteBackground": false
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "Jsj3a_R3Xww"
                    },
                    "highlight": false,
                    "id": 13,
                    "order": 13,
                    "position": {
                        "x": 7.191991774958941,
                        "y": -5.604019943620913,
                        "z": 4.083101680328641
                    },
                    "showLabel": true,
                    "title": "Varme",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "oIDRQVEKpWY"
                    },
                    "highlight": false,
                    "id": 14,
                    "order": 14,
                    "position": {
                        "x": 5.019461325027229,
                        "y": -3.7159768558553363,
                        "z": 7.789925014252806
                    },
                    "showLabel": true,
                    "title": "Matlaging",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "image": "019EBuEzdgZBx"
                    },
                    "id": 15,
                    "order": 15,
                    "position": {
                        "x": -8.64747010585919,
                        "y": -2.034313194744071,
                        "z": -4.548643274446795
                    },
                    "type": "image",
                    "whiteBackground": false
                }
            ],
            "id": 21,
            "image": "019EBskETycv6",
            "modified": true,
            "order": 22,
            "title": "2.jpg",
            "viewDirection": {
                "x": 9.916246605926132,
                "y": -1.0580234206556414,
                "z": 0.3923839093427376
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 27,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8208815452396984,
                        "y": -0.6,
                        "z": -0.013250174892914683
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0104830431440195,
                        "y": -0.5999999999999999,
                        "z": 0.3062549836574487
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1008614160789596,
                        "y": -0.6,
                        "z": -0.7041264779156062
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.113832974619316,
                        "y": -0.6000000000000001,
                        "z": -0.43929824725668065
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.354576363159236,
                        "y": -0.6000000000000001,
                        "z": -0.6058118187511619
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.7975610350952762,
                        "y": -0.6,
                        "z": 0.4792393627653506
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0221074631016567,
                        "y": -0.6000000000000001,
                        "z": -0.5503833341492468
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuZy5"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 5.1230773090641035,
                        "y": -6.3911667751765435,
                        "z": -5.7272585883003835
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM6"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -8.13528868587359,
                        "y": -2.3084388630063,
                        "z": -5.311249312715362
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM7"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -9.003163707128502,
                        "y": -0.7293825737412253,
                        "z": -4.263965055521182
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "Jsj3a_R3Xww"
                    },
                    "highlight": true,
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": -4.842655557972458,
                        "y": -5.451863555688672,
                        "z": -6.827269533017983
                    },
                    "showLabel": true,
                    "title": "Varme",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "oIDRQVEKpWY"
                    },
                    "highlight": true,
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": 0.7323046649041256,
                        "y": -5.676530244995519,
                        "z": -8.186310552192086
                    },
                    "showLabel": true,
                    "title": "Matlaging",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 22,
            "image": "019EBskETycv8",
            "modified": true,
            "order": 23,
            "title": "3.jpg",
            "viewDirection": {
                "x": -1.2515834118603946,
                "y": -0.4754288258691081,
                "z": -9.893657893719633
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 25,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7283418298773657,
                        "y": -0.6,
                        "z": -0.19799337980633488
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5690055209727405,
                        "y": -0.6000000000000003,
                        "z": -1.0657408433748252
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.8314527285656875,
                        "y": -0.6000000000000003,
                        "z": -1.8561898217884012
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1263177960807877,
                        "y": -0.5999999999999999,
                        "z": 0.3124091113980713
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "iconId": "arrow-in-circle",
                    "iconRotation": 254,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.206123714662148,
                        "y": -0.6,
                        "z": 0.5381944977941369
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.130869114276574,
                        "y": -0.5999999999999998,
                        "z": 0.9346013428395168
                    },
                    "showLabel": false,
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5179334348500388,
                        "y": -0.5999999999999996,
                        "z": 1.1840871014445844
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM6"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 2.187823761139175,
                        "y": -6.457593879114898,
                        "z": -7.305351368394808
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM7"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": 0.8711637057667998,
                        "y": -0.4579263210494148,
                        "z": -9.936352368360964
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "oIDRQVEKpWY"
                    },
                    "highlight": true,
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": 0.9796634337978487,
                        "y": -4.516983256446821,
                        "z": 8.864287612394362
                    },
                    "showLabel": true,
                    "title": "Matlaging",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": ""
                    },
                    "highlight": true,
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": 1.4282308743956325,
                        "y": -7.4890142034513785,
                        "z": 6.460342506842844
                    },
                    "showLabel": true,
                    "title": "Varme",
                    "type": "video",
                    "videoType": "Dms",
                    "whiteBackground": true
                },
                {
                    "domeId": 27,
                    "id": 13,
                    "order": 13,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.5373957057239032,
                        "y": -0.5999999999999994,
                        "z": 2.306302374701314
                    },
                    "size": 0.3,
                    "type": "portal"
                }
            ],
            "id": 23,
            "image": "019EBskETycmK",
            "modified": true,
            "order": 24,
            "title": "4.jpg",
            "viewDirection": {
                "x": 9.48734437607096,
                "y": -0.9706925019212346,
                "z": -2.972901031322355
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 23,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9669011537128008,
                        "y": -0.5999999999999999,
                        "z": 0.3920573829117998
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.059687272064546,
                        "y": -0.6000000000000002,
                        "z": -0.444731053134006
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0969748200955682,
                        "y": -0.6000000000000002,
                        "z": -0.7372354119827385
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9042536331335781,
                        "y": -0.6,
                        "z": -0.046215408953545305
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.9553786452815567,
                        "y": -0.5999999999999999,
                        "z": 0.2526063746425661
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.3769434460088505,
                        "y": -0.6,
                        "z": -0.5600274345994793
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -4.386907529568323,
                        "y": -0.6000000000000001,
                        "z": -0.9089483778035032
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM7"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 2.584184014501775,
                        "y": -1.2699349261552457,
                        "z": 9.554884188313938
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM6"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -6.1407677450667375,
                        "y": -3.018069159852762,
                        "z": 7.274473029168053
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "oIDRQVEKpWY"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -9.796485932140628,
                        "y": -0.36051756985514866,
                        "z": 1.8939238094244883
                    },
                    "showLabel": true,
                    "title": "Matlaging",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 24,
            "image": "019EBskETycgs",
            "modified": true,
            "order": 25,
            "title": "5.jpg",
            "viewDirection": {
                "x": 3.144210002020849,
                "y": -0.2592361365207868,
                "z": 9.47892568613364
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 23,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9408471284044193,
                        "y": -0.6,
                        "z": 0.026374222484557723
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.134200959521019,
                        "y": -0.6,
                        "z": 0.0967962770570417
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 43,
                    "iconId": "arrow-in-circle",
                    "iconRotation": 267,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.655563692639512,
                        "y": -0.6,
                        "z": 0.16892135264028577
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "domeId": 20,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.738231104771929,
                        "y": -0.6,
                        "z": 0.22329220811750894
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 26,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.06877601116952109,
                        "y": -0.6000000000000001,
                        "z": -0.7368023348517245
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZua3R"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": -5.688618865644833,
                        "y": -5.535007717531343,
                        "z": 6.060455690526902
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGf"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": -6.370033672581389,
                        "y": -2.8509901740431247,
                        "z": 7.149367692158958
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Denne vogga kommer fra gården Funni. Den er dekorert med initialene GASF, som står for Gulbrand Andersen Funden, og årstallet 1777. "
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 3.9406729507889797,
                        "y": -6.722699920988871,
                        "z": -6.244072226236054
                    },
                    "type": "text"
                }
            ],
            "id": 25,
            "image": "019EBskETyHti",
            "modified": true,
            "order": 26,
            "title": "6.jpg",
            "viewDirection": {
                "x": 9.930254526573998,
                "y": -0.8796827248854536,
                "z": -0.5278614406621494
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 25,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7061977161573164,
                        "y": -0.6000000000000002,
                        "z": -0.20799270481517546
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZua3R"
                    },
                    "id": 2,
                    "order": 2,
                    "position": {
                        "x": -8.506983199151293,
                        "y": -2.85772186064181,
                        "z": -4.368419858840807
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGf"
                    },
                    "id": 3,
                    "order": 3,
                    "position": {
                        "x": -8.796375588491928,
                        "y": -1.2053065677873913,
                        "z": -4.555505130774892
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Denne vogga kommer fra gården Funni. Den er dekorert med initialene GASF, som står for Gulbrand Andersen Funden, og årstallet 1777. "
                    },
                    "id": 4,
                    "order": 4,
                    "position": {
                        "x": -0.6922308416313511,
                        "y": -8.186913700328844,
                        "z": 5.686935033847821
                    },
                    "type": "text"
                }
            ],
            "id": 26,
            "image": "019EBskETycv5",
            "modified": true,
            "order": 27,
            "title": "7.jpg",
            "viewDirection": {
                "x": -0.3729286033221251,
                "y": -1.1807443783944347,
                "z": 9.902741233384116
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 28,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8271907658530325,
                        "y": -0.6000000000000001,
                        "z": -0.7571355696584448
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.153164704008238,
                        "y": -0.5999999999999999,
                        "z": 0.4643562527363611
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.3429259189701712,
                        "y": -0.6,
                        "z": -0.6383043510141363
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0957297169194007,
                        "y": -0.5999999999999999,
                        "z": 0.05166163888236771
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.0695488019125765,
                        "y": -0.6000000000000001,
                        "z": -0.802090115140025
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7739523190107196,
                        "y": -0.6,
                        "z": -0.06667607066346823
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.1984200443793975,
                        "y": -0.6,
                        "z": 0.30347773106141185
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.5825117322974918,
                        "y": -0.6000000000000001,
                        "z": -0.783187783030799
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 9,
                    "order": 9,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.9758925228193362,
                        "y": -0.6,
                        "z": -0.2782774318810419
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 10,
                    "order": 10,
                    "portalType": "Floor",
                    "position": {
                        "x": -5.9087764458513385,
                        "y": -0.6,
                        "z": -0.1777725133885013
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGm"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": 8.05235942911669,
                        "y": -2.183357637457917,
                        "z": 5.484568436303167
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Denne døra, som er kamuflert som et skap, leder ned til den ene hvelvkjelleren under huset. ",
                        "image": "019EBtFnvm6vp"
                    },
                    "id": 13,
                    "order": 13,
                    "position": {
                        "x": -3.846015440517873,
                        "y": -4.931476682473153,
                        "z": 7.7808743379301495
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Dette er et melkeskap fra andre halvdel av 1700-tallet. Det kommer opprinnelig fra gåren Funni. Her ble melka satt til syrning før man skulle lage ost, smør eller rømme. Skapet er dekorert med kardinaldydene Tro, Håp, Kjærlighet og Tålmodighet. \nPå skapet står det: \nTroen du ey Slippe maa, Vil du himlens glæde faa. Haab paa Gud hvor det vil gaa, Haabet ey beskjemmes maa. Beflitte dig paae kierlighed, Vi du naae himmelens herlighed. Taalmodig du stædse tee, I all din Sorig og Lidelse.\n"
                    },
                    "id": 14,
                    "order": 14,
                    "position": {
                        "x": 8.681232663035049,
                        "y": -1.3664374887165072,
                        "z": -4.739535819697288
                    },
                    "type": "text"
                }
            ],
            "id": 27,
            "image": "019EBskETycgq",
            "modified": true,
            "order": 28,
            "title": "8.jpg",
            "viewDirection": {
                "x": -0.48972673598785443,
                "y": -0.9033824626465929,
                "z": -9.9294480285348
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 27,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7140808750691875,
                        "y": -0.6000000000000002,
                        "z": -0.9415980432055454
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.22551312974168583,
                        "y": -0.5999999999999999,
                        "z": 0.6052625233178104
                    },
                    "size": 0.1,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.3873733486203474,
                        "y": -0.5999999999999999,
                        "z": 0.2868940854695282
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7888275896490311,
                        "y": -0.5999999999999996,
                        "z": 1.5678678528870662
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.10267440648551714,
                        "y": -0.5999999999999995,
                        "z": 1.6212087950373295
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8501483665762093,
                        "y": -0.6,
                        "z": -0.013822731114624124
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9734678644539598,
                        "y": -0.6000000000000006,
                        "z": -2.514140684466582
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaCH"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -7.823725993037961,
                        "y": -0.9385747076069046,
                        "z": 6.129045914063343
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Dette er et melkeskap fra andre halvdel av 1700-tallet. Det kommer opprinnelig fra gåren Funni. Her ble melka satt til syrning før man skulle lage ost, smør eller rømme. Skapet er dekorert med kardinaldydene Tro, Håp, Kjærlighet og Tålmodighet. \nPå skapet står det: \nTroen du ey Slippe maa, Vil du himlens glæde faa. Haab paa Gud hvor det vil gaa, Haabet ey beskjemmes maa. Beflitte dig paae kierlighed, Vi du naae himmelens herlighed. Taalmodig du stædse tee, I all din Sorig og Lidelse.\n"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 2.591213917183418,
                        "y": -3.165596457907241,
                        "z": 9.109485653708768
                    },
                    "type": "text"
                },
                {
                    "content": {
                        "description": "Denne døra, som er kamuflert som et skap, leder ned til den ene hvelvkjelleren under huset. ",
                        "image": "019EBtFnvm6vp"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": -6.633369779910016,
                        "y": -1.826727997667262,
                        "z": -7.241801495303306
                    },
                    "type": "image"
                }
            ],
            "id": 28,
            "image": "019EBskETycv3",
            "modified": true,
            "order": 29,
            "title": "9.jpg",
            "viewDirection": {
                "x": 9.901824228030044,
                "y": -0.9929192795140172,
                "z": -0.814610005628246
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 27,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.25833899606154903,
                        "y": -0.5999999999999996,
                        "z": 1.2367303125042903
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1726436716534794,
                        "y": -0.5999999999999999,
                        "z": 0.6475333264722312
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 30,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.1177843576678874,
                        "y": -0.5999999999999999,
                        "z": -0.023926875484191097
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6758252672123578,
                        "y": -0.6000000000000004,
                        "z": -1.1889759838072067
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.601998321035897,
                        "y": -0.6000000000000001,
                        "z": -0.8399401022799226
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.33259950844050284,
                        "y": -0.5999999999999994,
                        "z": 2.243272188417307
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.3895349892100191,
                        "y": -0.5999999999999991,
                        "z": 4.275872025459941
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 34,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.2892733685950017,
                        "y": -0.5999999999999998,
                        "z": 0.8696638169137534
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGm"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": 7.580009811528623,
                        "y": -5.064205052606509,
                        "z": -4.076729196799373
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZua3W"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 1.9145043104622386,
                        "y": -1.3848075724031608,
                        "z": -9.704552622011336
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Dette er et melkeskap fra andre halvdel av 1700-tallet. Det kommer opprinnelig fra gåren Funni. Her ble melka satt til syrning før man skulle lage ost, smør eller rømme. Skapet er dekorert med kardinaldydene Tro, Håp, Kjærlighet og Tålmodighet. \nPå skapet står det: \nTroen du ey Slippe maa, Vil du himlens glæde faa. Haab paa Gud hvor det vil gaa, Haabet ey beskjemmes maa. Beflitte dig paae kierlighed, Vi du naae himmelens herlighed. Taalmodig du stædse tee, I all din Sorig og Lidelse.\n"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": -9.815082507965476,
                        "y": -1.2992503207826531,
                        "z": -1.2812895752505407
                    },
                    "type": "text"
                }
            ],
            "id": 29,
            "image": "019EBskETycgt",
            "modified": true,
            "order": 30,
            "title": "10.jpg",
            "viewDirection": {
                "x": -9.555092152156272,
                "y": -0.6031587261904857,
                "z": 2.8325547619620783
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 27,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7295097238590065,
                        "y": -0.6000000000000003,
                        "z": -1.9088484939701724
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.929613476536947,
                        "y": -0.6000000000000001,
                        "z": -0.17604479545704835
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.2310630147854289,
                        "y": -0.6000000000000002,
                        "z": -0.6930551030450053
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7827176307085827,
                        "y": -0.5999999999999995,
                        "z": 1.9184101565417937
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.5154990690003374,
                        "y": -0.5999999999999998,
                        "z": 1.0256815243294695
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9065345458985973,
                        "y": -0.6000000000000008,
                        "z": -3.647136607931998
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.7123742362553316,
                        "y": -0.6000000000000015,
                        "z": -6.527405116241567
                    },
                    "size": 0.5,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGm"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -9.958780029965295,
                        "y": 0.24158591337136495,
                        "z": -0.6054080445775525
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZua3W"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -8.418167289314452,
                        "y": 1.0142019784998886,
                        "z": 5.26642874869381
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Dette er et melkeskap fra andre halvdel av 1700-tallet. Det kommer opprinnelig fra gåren Funni. Her ble melka satt til syrning før man skulle lage ost, smør eller rømme. Skapet er dekorert med kardinaldydene Tro, Håp, Kjærlighet og Tålmodighet. \nPå skapet står det: \nTroen du ey Slippe maa, Vil du himlens glæde faa. Haab paa Gud hvor det vil gaa, Haabet ey beskjemmes maa. Beflitte dig paae kierlighed, Vi du naae himmelens herlighed. Taalmodig du stædse tee, I all din Sorig og Lidelse.\n"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 7.975832533300196,
                        "y": -3.4690931121648974,
                        "z": 4.90019014031821
                    },
                    "type": "text"
                }
            ],
            "id": 30,
            "image": "019EBskETycqn",
            "modified": true,
            "order": 31,
            "title": "11.jpg",
            "viewDirection": {
                "x": 9.511442731033679,
                "y": -0.7905202432658194,
                "z": -2.9652440121350208
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 30,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0966247526991517,
                        "y": -0.6000000000000002,
                        "z": -0.5882679028537704
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.1697941763791944,
                        "y": -0.6000000000000001,
                        "z": -0.04968724794037086
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.4377746671117007,
                        "y": -0.6000000000000002,
                        "z": -0.7608214312945425
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.051053830796892,
                        "y": -0.6000000000000001,
                        "z": -0.16344875505120504
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.7232560237069716,
                        "y": -0.6,
                        "z": -0.09964378236339914
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 32,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.2343933572188436,
                        "y": -0.6000000000000001,
                        "z": -0.9153194985922688
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.20249082062385282,
                        "y": -0.6000000000000003,
                        "z": -1.670737850354342
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZua3W"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -3.8730967793572915,
                        "y": -1.1494325834929922,
                        "z": 9.130862535489463
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Dette er et melkeskap fra andre halvdel av 1700-tallet. Det kommer opprinnelig fra gåren Funni. Her ble melka satt til syrning før man skulle lage ost, smør eller rømme. Skapet er dekorert med kardinaldydene Tro, Håp, Kjærlighet og Tålmodighet. \nPå skapet står det: \nTroen du ey Slippe maa, Vil du himlens glæde faa. Haab paa Gud hvor det vil gaa, Haabet ey beskjemmes maa. Beflitte dig paae kierlighed, Vi du naae himmelens herlighed. Taalmodig du stædse tee, I all din Sorig og Lidelse.\n"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -6.667192639723664,
                        "y": -1.8417818658471192,
                        "z": -7.203749719363671
                    },
                    "type": "text"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGm"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -8.751033475902782,
                        "y": -1.2201168678819903,
                        "z": 4.640638395383894
                    },
                    "type": "image"
                }
            ],
            "id": 31,
            "image": "019EBskETycv7",
            "modified": true,
            "order": 32,
            "title": "12.jpg",
            "viewDirection": {
                "x": -1.187303521560036,
                "y": -0.7726810361770077,
                "z": -9.89203041370949
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 30,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.22263970289917664,
                        "y": -0.6000000000000002,
                        "z": -0.9351975735858186
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 31,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.8574703495621471,
                        "y": -0.5999999999999999,
                        "z": 0.3808022647913179
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 29,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.294914719249895,
                        "y": -0.6000000000000003,
                        "z": -0.8455995820200718
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.17221935924841203,
                        "y": -0.6000000000000003,
                        "z": -1.520658734973298
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.8657923624087139,
                        "y": -0.6000000000000003,
                        "z": -1.89518500992699
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.0190522059967149,
                        "y": -0.6000000000000004,
                        "z": -2.575798757546094
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.259642042222002,
                        "y": -0.6000000000000008,
                        "z": -3.091761335286862
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 33,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9989147861968679,
                        "y": -0.6,
                        "z": -0.02854985006619799
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZua3W"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -9.883896146631697,
                        "y": -1.3901126469358478,
                        "z": 0.25967606547819755
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGm"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -8.751653557745152,
                        "y": -1.6047299587278887,
                        "z": -4.532131826033169
                    },
                    "type": "image"
                }
            ],
            "id": 32,
            "image": "019EBskETycmG",
            "modified": true,
            "order": 33,
            "title": "13.jpg",
            "viewDirection": {
                "x": 9.936773480297417,
                "y": -0.3462238643811011,
                "z": -0.9468715650805051
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 32,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.17275231948091369,
                        "y": -0.5999999999999998,
                        "z": 0.9113851513225272
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "description": "Denne linheklen brukes når man skal skille de lange linfibrene fra det korte stryet. Heklingen er det siste steget i beredingen av lin. Det var vanlig på de store gårdene på Romerike å dyrke lin til eget bruk. Denne heklen er fra 1831. Den er marmorert, og har gullbladdekor i hjørnene.  "
                    },
                    "id": 2,
                    "order": 2,
                    "position": {
                        "x": 7.294806873530906,
                        "y": -0.9188586311874462,
                        "z": -6.761968578396708
                    },
                    "type": "text"
                }
            ],
            "id": 33,
            "image": "019EBskETycqg",
            "modified": true,
            "order": 34,
            "title": "14.jpg",
            "viewDirection": {
                "x": -4.832189122768982,
                "y": -0.5782643894897996,
                "z": -8.720293540022325
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 35,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.03219490080577858,
                        "y": -0.6000000000000003,
                        "z": -1.6877322809011779
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 28,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6667500791074431,
                        "y": -0.6,
                        "z": -0.006539566931256319
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.9959692150620343,
                        "y": -0.6000000000000001,
                        "z": -0.30745231189911615
                    },
                    "size": 0.3,
                    "type": "portal"
                }
            ],
            "id": 34,
            "image": "019EBskETycvC",
            "modified": true,
            "order": 35,
            "title": "15.jpg",
            "viewDirection": {
                "x": 9.137321464723334,
                "y": -0.9992580336365223,
                "z": -3.9058005140624887
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 34,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.07260202588492809,
                        "y": -0.6000000000000001,
                        "z": -1.1194075356595206
                    },
                    "size": 0.3,
                    "type": "portal"
                }
            ],
            "id": 35,
            "image": "019EBskETycvA",
            "modified": true,
            "order": 36,
            "title": "16.jpg",
            "viewDirection": {
                "x": -4.631617628971815,
                "y": -1.116583001849304,
                "z": 8.769363891274688
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 37,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.6292406559583051,
                        "y": -0.6000000000000003,
                        "z": -0.9128388081653496
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.190515167516518,
                        "y": -0.5999999999999999,
                        "z": 0.1500880947091483
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.180515757513644,
                        "y": -0.6,
                        "z": -0.42484868781163077
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6220460962525428,
                        "y": -0.6,
                        "z": -0.010090464564383712
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -2.596761866106117,
                        "y": -0.6,
                        "z": 0.049504137466910576
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 23,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.6242450371589943,
                        "y": -0.5999999999999999,
                        "z": 0.41737523867408527
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.948204898856817,
                        "y": -0.6000000000000001,
                        "z": -0.43754526994435106
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 27,
                    "id": 8,
                    "order": 8,
                    "portalType": "Floor",
                    "position": {
                        "x": -3.6766818289670904,
                        "y": -0.5999999999999999,
                        "z": 0.14389340791402438
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGo"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": 3.1544801686691772,
                        "y": -4.026973423272937,
                        "z": 8.572941393495796
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaCQ"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 2.6837196796677287,
                        "y": -6.965783567981797,
                        "z": 6.637680494318225
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGi"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": 5.258191047033861,
                        "y": -3.3679836873098634,
                        "z": -7.787759538453923
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM5"
                    },
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": 6.134396567339001,
                        "y": 0.13136988949123007,
                        "z": -7.875792128895959
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaCP"
                    },
                    "id": 13,
                    "order": 13,
                    "position": {
                        "x": -0.5473822435971443,
                        "y": -6.421005095982726,
                        "z": -7.633295879037009
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "1xIRMxbdynE"
                    },
                    "highlight": true,
                    "id": 14,
                    "order": 14,
                    "position": {
                        "x": 8.756641162750993,
                        "y": 0.8670955315166836,
                        "z": -4.721541171137825
                    },
                    "showLabel": true,
                    "title": "Belysning",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 36,
            "image": "019EBskETycmM",
            "modified": true,
            "order": 37,
            "title": "17.jpg",
            "viewDirection": {
                "x": 1.9501226119175152,
                "y": -0.6330670544094397,
                "z": 9.77675770555426
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 36,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.7922174726407754,
                        "y": -0.6000000000000002,
                        "z": -0.6129930994337993
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.472535798621116,
                        "y": -0.6000000000000002,
                        "z": -1.1901072513628546
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.07493683107600513,
                        "y": -0.6000000000000003,
                        "z": -1.549651224619013
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 40,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.01984378705402212,
                        "y": -0.6000000000000005,
                        "z": -2.064106240195513
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGi"
                    },
                    "id": 5,
                    "order": 5,
                    "position": {
                        "x": -5.846638007502358,
                        "y": -8.10346352426296,
                        "z": -0.21598249243441847
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZua7u"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": 8.427631738800311,
                        "y": -5.367735630275844,
                        "z": 0.06463360471578605
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZua7q"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": 6.160435342459911,
                        "y": -3.296938207812106,
                        "z": -7.134396148305168
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGh"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -4.549091664443554,
                        "y": -2.9236858246447675,
                        "z": -8.394075303691993
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM5"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -9.986965220442185,
                        "y": 0.15385864555528622,
                        "z": -0.13337099180067646
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Denne vakre lysestaken i smijern er merket med årstallet 1607 og er med det museets eldste gjenstand. I Sverige er den kjent som «Tuppstaken», men hos oss er den kjent som en symbolstake. Første eier var Erland Berg (1580-1660) og staken tilhørte familien Berg i Vestre Udnes før den ble gitt til museet en gang mellom 1915 og 1930. \n"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -5.079066803673129,
                        "y": -2.5238126157419747,
                        "z": -8.216317699234942
                    },
                    "type": "text"
                },
                {
                    "content": {
                        "description": "Stakens form viser til flere kristne symboler: hjertet er symbol for Guds kjærlighet, trappetrinnene er Jakobsstigen, taggene er Kristi tornekrone og foten symboliserer Treenigheten. Øverst på stanga har det vært en hane for årvåkenhet og de hengende stjernene skal symbolisere de 12 apostlene. Den observante leser vil se at det på vår lysestake er hele 14 stjerner, så her har nok smeden glemt sin katekisme i arbeidsprosessen. På lignende staker er det ti hull for de ti bud, her er det derimot atskillig flere."
                    },
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": -3.91117897409877,
                        "y": -2.9076007389545837,
                        "z": -8.724192477402527
                    },
                    "type": "text"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "1xIRMxbdynE"
                    },
                    "highlight": false,
                    "id": 13,
                    "order": 13,
                    "position": {
                        "x": -9.118914458450348,
                        "y": 2.4657574775214663,
                        "z": -3.237321867262259
                    },
                    "showLabel": true,
                    "title": "Belysning",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "vE2p3yH3n4M"
                    },
                    "highlight": true,
                    "id": 14,
                    "order": 14,
                    "position": {
                        "x": 9.597721538210413,
                        "y": -2.6394345960106502,
                        "z": 0.76256385317655
                    },
                    "showLabel": true,
                    "title": "Luksus",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 37,
            "image": "019EBskETycqk",
            "modified": true,
            "order": 38,
            "title": "18.jpg",
            "viewDirection": {
                "x": 4.33780946892447,
                "y": -0.5071305812176135,
                "z": -8.975325230238946
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 37,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.6512032938473968,
                        "y": -0.5999999999999999,
                        "z": 0.5315176009083209
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 39,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.115266949919583,
                        "y": -0.6000000000000002,
                        "z": -0.8900371818000642
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 2.6252488455429495,
                        "y": -0.6,
                        "z": -0.5612110836583041
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 3.9632307119123094,
                        "y": -0.6000000000000001,
                        "z": -0.6696358884639192
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGj"
                    },
                    "id": 5,
                    "order": 5,
                    "position": {
                        "x": -7.907147882766633,
                        "y": -6.1071363519943,
                        "z": 0.04329972391861192
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZua7w"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": -1.05406665244818,
                        "y": -5.7006936394355,
                        "z": -8.132526545626156
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM5"
                    },
                    "id": 7,
                    "order": 7,
                    "position": {
                        "x": 4.876803773837933,
                        "y": -1.2441189557294587,
                        "z": 8.627517185860562
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGi"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": 8.174109318441328,
                        "y": -3.480812774306025,
                        "z": 4.56051052002112
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZua7q"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": 9.056135452897134,
                        "y": -1.7697929570171227,
                        "z": -3.817044500061903
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaCP"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": 9.79762759938242,
                        "y": -1.7398888442178742,
                        "z": 0.8460187319123762
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGh"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": 9.460347084405152,
                        "y": -3.15682397325407,
                        "z": -0.4890112353221099
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "1xIRMxbdynE"
                    },
                    "highlight": true,
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": 0.4121941714942241,
                        "y": 3.3734257072451634,
                        "z": 9.384464450552763
                    },
                    "showLabel": true,
                    "title": "Belysning",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "vE2p3yH3n4M"
                    },
                    "id": 13,
                    "order": 13,
                    "position": {
                        "x": 9.86166783288958,
                        "y": 1.3467509845074863,
                        "z": 0.781744575498214
                    },
                    "showLabel": true,
                    "title": "Luksus",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 38,
            "image": "019EBskETycmJ",
            "modified": true,
            "order": 39,
            "title": "19.jpg",
            "viewDirection": {
                "x": 2.460229471245155,
                "y": -0.5910488279144647,
                "z": -9.655348296338376
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 40,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.8096540376122029,
                        "y": -0.6000000000000001,
                        "z": -0.09082951407273906
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 36,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.30901194280002164,
                        "y": -0.5999999999999996,
                        "z": 1.28959336596787
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 37,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.2388787228339386,
                        "y": -0.5999999999999998,
                        "z": 0.5665372436432103
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7173955670763494,
                        "y": -0.6000000000000002,
                        "z": -0.9260446722005427
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 24,
                    "id": 5,
                    "order": 5,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.39348135745695506,
                        "y": -0.5999999999999995,
                        "z": 2.040940688085998
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 21,
                    "id": 6,
                    "order": 6,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.9152696913214544,
                        "y": -0.5999999999999993,
                        "z": 3.276607591108141
                    },
                    "size": 0.3,
                    "type": "portal"
                },
                {
                    "domeId": 22,
                    "id": 7,
                    "order": 7,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.6536800741363955,
                        "y": -0.599999999999999,
                        "z": 4.056299706945842
                    },
                    "size": 0.4,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGh"
                    },
                    "id": 8,
                    "order": 8,
                    "position": {
                        "x": -8.799223550019635,
                        "y": -4.362674100190374,
                        "z": -1.8445822214918883
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGj"
                    },
                    "id": 9,
                    "order": 9,
                    "position": {
                        "x": -4.6463597960753305,
                        "y": -2.269808295271583,
                        "z": -8.545180359998263
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaCP"
                    },
                    "id": 10,
                    "order": 10,
                    "position": {
                        "x": -5.969351355205784,
                        "y": -1.9203134440773775,
                        "z": 7.76688499476338
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaM5"
                    },
                    "id": 11,
                    "order": 11,
                    "position": {
                        "x": -9.732359774599747,
                        "y": 0.6734432277874434,
                        "z": -2.1603282121726495
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGo"
                    },
                    "id": 12,
                    "order": 12,
                    "position": {
                        "x": 0.8395402564761063,
                        "y": -3.901863771086873,
                        "z": 9.160207504094318
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "1xIRMxbdynE"
                    },
                    "id": 13,
                    "order": 13,
                    "position": {
                        "x": -8.056063732169394,
                        "y": 1.93241233340691,
                        "z": -5.570388387856806
                    },
                    "showLabel": true,
                    "title": "Belysning",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                },
                {
                    "content": {
                        "video": "",
                        "youtube": "vE2p3yH3n4M"
                    },
                    "id": 14,
                    "order": 14,
                    "position": {
                        "x": -5.409303888648903,
                        "y": 1.2692034276039048,
                        "z": 8.28944534939724
                    },
                    "showLabel": true,
                    "title": "Luksus",
                    "type": "video",
                    "videoType": "Youtube",
                    "whiteBackground": true
                }
            ],
            "id": 39,
            "image": "019EBskETyHth",
            "modified": true,
            "order": 40,
            "title": "20.jpg",
            "viewDirection": {
                "x": 9.919239708936667,
                "y": -0.7798081542339551,
                "z": -0.9563781323991534
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 39,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.7413866574060659,
                        "y": -0.6000000000000001,
                        "z": -0.05949428407070585
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 38,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -1.4718446780882815,
                        "y": -0.6,
                        "z": -0.738646297936381
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 41,
                    "id": 3,
                    "order": 3,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.21292053140232858,
                        "y": -0.6000000000000002,
                        "z": -1.0995784909236823
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 42,
                    "id": 4,
                    "order": 4,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.14070022957864856,
                        "y": -0.5999999999999999,
                        "z": 0.7354700488620087
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGn"
                    },
                    "id": 5,
                    "order": 5,
                    "position": {
                        "x": 4.15963754895181,
                        "y": -6.558109641851356,
                        "z": 6.283039297762115
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Denne himmelsengen er fra tidlig 1800-tall og kommer fra gården Funni i Nes. Rundt 1850 ble den sjablongmalt av Christopher Gulbrandsen med akantusborder, knekkede bånd, urne med hanker, roser, tulipaner og andre blomster i limfarger. Lokalt heter denne typen seng loftsseng, og den kan trekkes ut og bli større.",
                        "image": "019EBuF6yL8hM"
                    },
                    "id": 6,
                    "order": 6,
                    "position": {
                        "x": -1.9792663540693642,
                        "y": 0.4259155693112956,
                        "z": -9.780371040797208
                    },
                    "type": "image"
                }
            ],
            "id": 40,
            "image": "019EBskETycvB",
            "modified": true,
            "order": 41,
            "title": "21.jpg",
            "viewDirection": {
                "x": 9.982353680903266,
                "y": -0.33882630252118995,
                "z": 0.08269468655237966
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 40,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.0570998172182258,
                        "y": -0.6000000000000001,
                        "z": -0.2322386422771855
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 42,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.8857678124652026,
                        "y": -0.6000000000000001,
                        "z": -0.41110170943767244
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "description": "Denne himmelsengen er fra tidlig 1800-tall og kommer fra gården Funni i Nes. Rundt 1850 ble den sjablongmalt av Christopher Gulbrandsen med akantusborder, knekkede bånd, urne med hanker, roser, tulipaner og andre blomster i limfarger. Lokalt heter denne typen seng loftsseng, og den kan trekkes ut og bli større.",
                        "image": "019EBuF6yL8hM"
                    },
                    "id": 3,
                    "order": 3,
                    "position": {
                        "x": 8.316659704697175,
                        "y": 1.3337728611989745,
                        "z": 5.353384799014236
                    },
                    "type": "image"
                }
            ],
            "id": 41,
            "image": "019EBskETycqi",
            "modified": true,
            "order": 42,
            "title": "22.jpg",
            "viewDirection": {
                "x": -2.2622709811810346,
                "y": 1.0817058792441083,
                "z": -9.664172996797507
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 40,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.679736697479409,
                        "y": -0.5999999999999999,
                        "z": 0.32516067403289317
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "domeId": 42,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": 1.596858965279847,
                        "y": -0.5999999999999998,
                        "z": 0.8149306266109955
                    },
                    "size": 0.2,
                    "type": "portal"
                },
                {
                    "content": {
                        "image": "019EBskEZuaGn"
                    },
                    "id": 3,
                    "order": 3,
                    "position": {
                        "x": 0.5901961489379786,
                        "y": -8.730506049114455,
                        "z": 4.820896340963351
                    },
                    "type": "image"
                },
                {
                    "content": {
                        "description": "Denne himmelsengen er fra tidlig 1800-tall og kommer fra gården Funni i Nes. Rundt 1850 ble den sjablongmalt av Christopher Gulbrandsen med akantusborder, knekkede bånd, urne med hanker, roser, tulipaner og andre blomster i limfarger. Lokalt heter denne typen seng loftsseng, og den kan trekkes ut og bli større.",
                        "image": "019EBuF6yL8hM"
                    },
                    "id": 5,
                    "order": 5,
                    "position": {
                        "x": 9.652350090917917,
                        "y": 0.16155136339153225,
                        "z": 2.536530019559234
                    },
                    "type": "image"
                }
            ],
            "id": 42,
            "image": "019EBskEVUHEn",
            "modified": true,
            "order": 43,
            "title": "23.jpg",
            "viewDirection": {
                "x": -5.610314511826502,
                "y": -0.7168443972689594,
                "z": 8.230374227460183
            }
        }
    ],
    settings: {
        "colors": [
            {
                "key": 0,
                "value": null
            },
            {
                "key": 1,
                "value": null
            },
            {
                "key": 2,
                "value": null
            },
            {
                "key": 3,
                "value": null
            }
        ]
    }
}