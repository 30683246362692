import {PLAYER_OPENED, useAudioDispatch} from "./audioContext";
import {useRssFeedAsJson} from "./useRssFeedAsJson";
import {useEffect} from "react";

export const useAudio = ({rss, audio, audioType}) => {
    const dispatch = useAudioDispatch()

    const rssJson = useRssFeedAsJson(rss)

    useEffect(() => {
        if (audioType && audio) {
            dispatch({
                type: PLAYER_OPENED,
                currentEpisode: {},
                audioSrc: audio,
                feedData: null,
                isPodcast: false
            })
        }
    }, [audio, audioType])

    useEffect(() => {
        if (rssJson) {
            dispatch({
                type: PLAYER_OPENED,
                currentEpisode: rssJson.entries[0],
                audioSrc: rssJson.entries[0]?.links[0]?.href,
                feedData: rssJson,
                isPodcast: true
            })
        }
    }, [rssJson])

}