import React, {useState} from "react";
import {Sphere} from "./Sphere";
import {useThreeSixtyState} from "../App/ThreeSixtyContext";
import {useTextureLoad} from "../Canvas/useTextureLoad";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useTexturePreload} from "../Canvas/useTexturePreload";
import {useZoom} from "../gestures/useZoom";
import {findDomeImage} from "./dome";

export const Dome = ({data, onChange, adminMode}) => {
    const {activeDomeId, previousDomeId} = useThreeSixtyState();
    const activeDome = data.domes.find((d) => d.id === activeDomeId);
    const isFirst = activeDomeId === previousDomeId;
    const prevDome = data.domes.find((d) => d.id === previousDomeId);
    const [imageIdsSeen, setImageIdsSeen] = useState([])
    const [futureImageIds, setFutureImageIds] = useState([])
    const loadedTextures = useTextureLoad(imageIdsSeen)
    const preloadedTextures = useTexturePreload(futureImageIds)
    const zoomBind = useZoom({
        adminMode: adminMode,
        resetZoom: activeDomeId
    })

    useDeepCompareEffect(
        () => {
            if (activeDome && data?.domes) {
                const neighbourDomeIds = activeDome.hotspots.map(hotspot => hotspot.domeId)

                const neighbourDomeImages = data.domes
                    .filter(dome => neighbourDomeIds.includes(dome.id))
                    .map(findDomeImage)

                if(![...futureImageIds, ...neighbourDomeImages].includes(findDomeImage(activeDome))) {
                    setImageIdsSeen(prevImageIds => (
                        [
                            ...new Set([
                                ...prevImageIds,
                                findDomeImage(activeDome),
                            ])
                        ]
                    ))
                }

                setFutureImageIds(neighbourDomeImages)
            }
        }, [data, activeDome]
    )

    return (
        <group {...zoomBind()}>
            {activeDome && Object.keys(loadedTextures).length > 0 && (
                <Sphere
                    activeDome={activeDome}
                    adminMode={adminMode}
                    data={data}
                    onChange={onChange}
                    isFirst={isFirst}
                    prevDome={prevDome ? prevDome : activeDome}
                    textures={{
                        ...preloadedTextures,
                        ...loadedTextures
                    }}
                />
            )}
        </group>
    );
};
