import {Hotspots} from "./Hotspots";
import React from "react";
import {useThreeSixtyProps, useThreeSixtyState} from "../App/ThreeSixtyContext";
import {DomeSettings} from "./EditDome/DomeSettings";

export const DomeAndHotspotSettings = () => {
    const { activeDomeId } = useThreeSixtyState()
    const {data} = useThreeSixtyProps()

    const dome = data?.domes?.find((d) => d.id === activeDomeId)

    if(dome) {
        return (
            <>
                <DomeSettings dome={dome} data={data} />
                <Hotspots dome={dome} />
            </>
        )
    } else {
        return null
    }
}