import {alpha, IconButton} from "@mui/material";
import {MyLocation} from "@mui/icons-material";
import {HOTSPOT_DESELECTED, HOTSPOT_SELECTED, useMapDispatch, useMapState} from "./mapContext";

export const MapAdminHotspot = ({hotspotId, hotspotX, hotspotY, sizeInRem}) => {
    const mapDispatch = useMapDispatch()
    const {activeHotspotId} = useMapState()

    const handleClick = () => {
        mapDispatch({
            type: HOTSPOT_SELECTED,
            hotspotId: hotspotId
        })
    }

    const handleDragStart = (e) => {
        if (activeHotspotId !== hotspotId) {
            mapDispatch({
                type: HOTSPOT_DESELECTED
            })
        }
        e.dataTransfer.setData("text/plain", hotspotId);
        e.dataTransfer.effectAllowed = 'move'
    }
    
    return (
        <IconButton
            onClick={handleClick}
            draggable={true}
            onDragStart={handleDragStart}
            sx={{
                position: "absolute",
                left: `${hotspotX}%`,
                top: `${hotspotY}%`,
                transform: 'translate(-50%, -50%)',
                border: "white 1px solid",
                backgroundColor: activeHotspotId === hotspotId ? theme => alpha(theme.palette.primary.light, 0.7) : "rgba(0, 0, 0, 0.7)",
                "&:hover": {
                    backgroundColor: theme => alpha(theme.palette.primary.main, 0.7)
                }
            }}
        >
            <MyLocation
                sx={{
                    fontSize: `${sizeInRem}rem`,
                    color: "white"
                }}
            />
        </IconButton>
    )
}