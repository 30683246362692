import {Map} from '@mui/icons-material'
import {colors as vmColors} from "@ekultur/vm";
import {Fab, Typography} from "@mui/material";
import React from "react";
import {
    SET_ACTIVE_MAP,
    useThreeSixtyDispatch,
    useThreeSixtyProps,
    useThreeSixtyTranslation
} from "../App/ThreeSixtyContext";
import {Add} from "@mui/icons-material";
import {addMaps} from "../App/service";
export const NoMapsPlaceHolder = () => {
    const t = useThreeSixtyTranslation()
    const {onChange, data, onMediaSelect} = useThreeSixtyProps()
    const dispatch = useThreeSixtyDispatch()

    const handleAddMapImage = () => {
        const highestDomeId =0
        if (onMediaSelect) {
            onMediaSelect(
                "image",
                (images) => {
                    const newMaps = images.map(({dmsId, alt}, i) => ({
                        id: highestDomeId ? highestDomeId + 1 + i : 1,
                        order: highestDomeId ? highestDomeId + 1 + i : 1,
                        image: {
                            dmsId: dmsId
                        },
                        title: alt,
                        hotspots: [],
                        iconSize: 50
                    }));
                    onChange(addMaps(data, newMaps));
                },
                "multiple"
            );
        } else {
            onChange(
                addMaps(data, [
                    {
                        id: highestDomeId ? highestDomeId + 1 : 1,
                        order: highestDomeId ? highestDomeId + 1 : 1,
                        image: {
                            dmsId: "019EGGiCQnqnk"
                        },
                        hotspots: [],
                        title: `Kart ${highestDomeId + 1}`,
                        iconSize: 50
                    },
                ])
            );
            console.warn("onMediaSelect is undefined");
        }

        if(highestDomeId === 0) {
            dispatch({
                type: SET_ACTIVE_MAP,
                mapId: 1
            })
        }
    }

    return (
        <>
            <Map
                sx={{
                    fontSize: "110px !important",
                    color: vmColors.coolGray400
                }}
            />
            <Typography variant="h5" sx={{marginTop: "30px"}}>
                {t("emptyMapsTitle", "Lag kart for din 360-opplevelse")}
            </Typography>
            <Typography
                variant="subtitle2"
                sx={{
                    margin: "9px 0 26px",
                    color: "#00000099",
                    textAlign: "center",
                }}
            >
                {t(
                    "emptyMapsText",
                    "Last opp for eksempel en plantegning eller et flyfoto for å lage et interaktivt kart"
                )}
            </Typography>
            <Fab
                variant="extended"
                size="large"
                color="primary"
                onClick={handleAddMapImage}
            >
                <Add/>
                {t("emptyMapsButtonText", "Legg til kartbilde")}
            </Fab>
        </>
    )
}