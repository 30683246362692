import {SET_ACTIVE_MAP, useThreeSixtyDispatch, useThreeSixtyProps} from "../App/ThreeSixtyContext";
import {Button} from "../AppBar/Button";
import {Map} from "@mui/icons-material";
import {useState} from "react";
import {MapPopper} from "./MapPopper";

export const MapButton = () => {
    const {data} = useThreeSixtyProps()
    const [anchorEl, setAnchorEl] = useState(null)

    const dispatch = useThreeSixtyDispatch()
    const handleClick = event => setAnchorEl(anchorEl ? null : event.currentTarget)
    const handleClose = () =>   setAnchorEl(null)
    const open = Boolean(anchorEl)

    const handleShowMap = () => {
        dispatch({
            type: SET_ACTIVE_MAP,
            mapId: data.maps[0].id
        })
    }

    if (data?.maps?.length > 1) {
        return (
            <>
                <Button
                    icon={<Map/>}
                    text={"Kart"}
                    onClick={handleClick}
                    active={open}
                />
                <MapPopper
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                />
            </>
        )
    } else if(data?.maps?.length === 1) {
        return (
            <Button
                icon={<Map/>}
                text={"Kart"}
                onClick={handleShowMap}
                active={open}
            />
        )
    } else {
        return null
    }
}