import {MapHotspot} from "./MapHotspot";

export const MapHotspots = ({iconSize, hotspots}) => {
    return hotspots.map(hotspot => (
        <MapHotspot
            key={hotspot.id}
            hotspot={hotspot}
            iconSize={iconSize}
        />
    ))

}