import React, {useEffect, useState} from "react";
import {createUseStyles} from "react-jss";
import {CurrentEpisode} from "./CurrentEpisode";
import {PlayerControls} from "./PlayerControls";
import {IconButton} from "@mui/material";
import {X} from "react-feather";
import {EpisodeControls} from "./EpisodeControls";
import {
    EPISODE_CHANGED,
    PAUSE_TRIGGERED,
    PLAY_TRIGGERED,
    PLAYER_CLOSED,
    useAudioDispatch,
    useAudioState
} from "./audioContext";

const useStyles = createUseStyles({
    controller: {
        display: "flex",
        width: "100%",
        backgroundColor: "white",
        zIndex: "10000000",
        position: "fixed",
        bottom: 0,
        height: "52px",
    },
});

export const Controller = ({
                               audioRef,
                               showControls,
                               setCurrentTime,
                               ready
                           }) => {
    const classes = useStyles();
    const [volume, setVolume] = useState(0.5);
    const [currentIndex, setCurrentIndex] = useState(0);
    const dispatch = useAudioDispatch()
    const {
        playing,
        currentEpisode,
        feedData
    } = useAudioState()

    const handlePlay = () => dispatch({type: PLAY_TRIGGERED})

    const handlePause = () => dispatch({type: PAUSE_TRIGGERED})

    useEffect(() => {
        if (ready) {
            if (showControls) {
                handlePlay()
            } else {
                handlePause()
            }
        }
    }, [showControls, ready]);

    const handleClose = () => dispatch({type: PLAYER_CLOSED})


    const handleChangeEpisode = episode => dispatch({
        type: EPISODE_CHANGED,
        currentEpisode: episode
    })

    const handleVolumeChange = (vol) => {
        audioRef.current.volume = vol;
        setVolume(vol);
    };

    const handleTimeChange = (time) => {
        const newTime = audioRef.current.duration * time;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const changeEpisode = (episode, i) => {
        const isSameEpisode = currentEpisode === episode;
        if (playing && isSameEpisode) {
            handlePause()
        } else {
            if (!isSameEpisode) {
                handleChangeEpisode(episode);
                setCurrentIndex(i);
                audioRef.current.src = episode?.links[0]?.href;
            }
            handlePlay()
        }
    };

    const previousEpisode = () => {
        changeEpisode(feedData?.entries[currentIndex - 1], currentIndex - 1);
    };

    const nextEpisode = () => {
        changeEpisode(feedData?.entries[currentIndex + 1], currentIndex + 1);
    };

    const skipForward15Seconds = () => {
        const newTime = audioRef.current.currentTime + 15;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const skipBackwards15Seconds = () => {
        const newTime = audioRef.current.currentTime - 15;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    return showControls ? (
        <div className={classes.controller}>
            <CurrentEpisode feed={feedData?.feed} currentEpisode={currentEpisode}/>
            <EpisodeControls
                items={feedData?.entries}
                image={feedData?.feed.image?.href}
                onEpisodeChange={changeEpisode}
            />
            <PlayerControls
                audioElement={audioRef.current}
                currentIndex={currentIndex}
                episodesCount={feedData?.entries.length}
                volume={volume}
                currentTime={audioRef.current.currentTime}
                episodeDuration={audioRef.current.duration}
                onVolumeChange={handleVolumeChange}
                onChangeTime={handleTimeChange}
                onPlay={handlePlay}
                onPause={handlePause}
                onNextEpisode={nextEpisode}
                onPreviousEpisode={previousEpisode}
                onSkipForward15Seconds={skipForward15Seconds}
                onSkipBackwards15Seconds={skipBackwards15Seconds}
            />
            <IconButton
                onClick={handleClose}
                sx={{
                    width: 42,
                    height: 42,
                    display: "flex",
                    alignItems: "center",
                    margin: "5px 0px",
                }}
            >
                <X/>
            </IconButton>
        </div>
    ) : null;
};
