import {useActiveMap} from "./useActiveMap";
import {Box, Dialog, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import {Close} from "@mui/icons-material";
import {MAP_DESELECTED, useThreeSixtyDispatch} from "../App/ThreeSixtyContext";
import {SelectedMap} from "./SelectedMap";

export const MapDialog = () => {
    const activeMap = useActiveMap()
    const dispatch = useThreeSixtyDispatch()

    const handleClose = () =>  dispatch({type: MAP_DESELECTED})

    return (
        <Dialog
            open={Boolean(activeMap)}
            fullScreen={true}
            sx={{
                "& .MuiDialog-paperFullScreen": {
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    color: "white"
                }
            }}
        >
            <DialogTitle>
                {activeMap?.title}
            </DialogTitle>
            <DialogContent>
                <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <SelectedMap />
                </Stack>

            </DialogContent>
            <IconButton
                onClick={handleClose}
                sx={{
                    color: "white",
                    position: "absolute",
                    top: 12,
                    right: 24
                }}
            >
                <Close />
            </IconButton>
        </Dialog>
    )
}