import React from "react";
import {Box, ButtonBase, Divider, Typography} from "@mui/material";
import {Grid} from '@mui/system'
import {Cover} from "./Cover";
import {useAudioState} from "./audioContext";
import {CoverImage} from "./CoverImage";


export const Episode = ({episode, isLast, onEpisodeChange}) => {
    const {content, title} = episode
    const {currentEpisode} = useAudioState()

    const getContent = () => {
        if (content[0]?.value) {
            const div = document.createElement("div");
            div.innerHTML = content[0].value;
            return div.textContent || div.innerText || "";
        } else {
            return "";
        }
    };

    const divider = isLast ? null : <Divider/>

    const handleClick = () => onEpisodeChange(episode)

    const isCurrentEpisode = currentEpisode?.id === episode.id

    console.debug({episode})

    return (
        <>
            <ButtonBase
                onClick={handleClick}
                sx={{
                    backgroundColor: isCurrentEpisode ? "rgba(63, 81, 181, 0.08)" : "#ffffff00",
                    padding: 1.5
                }}
                component={Box}
            >
                <Grid
                    container={true}
                    columnSpacing={1}
                >
                    <Grid>
                        <Cover
                            isCurrentEpisode={isCurrentEpisode}
                        >
                            <CoverImage
                                imageSrc={episode.image?.href}
                                alt={episode.title}
                                isCurrentEpisode={isCurrentEpisode}
                            />
                        </Cover>
                    </Grid>
                    <Grid
                        size={{
                            xs: 9
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: "18px",
                                width: "100%"
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "16px",
                                WebkitLineClamp: 3,
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {getContent()}
                        </Typography>
                    </Grid>
                </Grid>
            </ButtonBase>
            {divider}
        </>
    );
};
