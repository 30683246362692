import React, {useEffect, useRef, useState} from "react";
import Hls from "hls.js";

export const VideoPlayer = ({
                                videoUrl,
                                onLoad,
                                onLoadedMetadata,
                                onPlay,
                                muted = false,
                                controls = false,
                                loop = false,
                                className = "",
                            }) => {
    const playerRef = useRef();
    const [playlist, setPlaylist] = useState(null);

    class fLoader extends Hls.DefaultConfig.loader {
        constructor(config) {
            super({...config});
            const load = this.load.bind(this);
            this.load = (context, config, callbacks) => {
                if ("manifest" !== context.type) {
                    const newUrl = new URL(playlist);
                    load(
                        {
                            ...context,
                            url: `${context.url}${newUrl.search}`,
                        },
                        config,
                        callbacks
                    );
                } else {
                    load(context, config, callbacks);
                }
            };
        }
    }

    useEffect(() => {
        setPlaylist(videoUrl);
    }, [videoUrl]);

    useEffect(() => {
        if (null !== playerRef && playlist && Hls.isSupported()) {
            const video = playerRef?.current;
            const hls = new Hls({
                loader: fLoader,
                debug: false,
                progressive: true,
                forceKeyFrameOnDiscontinuity: false,
            });
            hls.loadSource(playlist);
            hls.attachMedia(video);
            video.controls = controls;
            video.loop = loop;
            video.muted = muted;
        }
    }, [playlist, playerRef, controls, muted, loop]);

    return (
        <video
            ref={playerRef}
            className={className}
            onLoad={onLoad}
            onLoadedMetadata={onLoadedMetadata}
            onPlay={onPlay}
        />
    );
};
