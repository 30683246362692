import React from "react";
import {createUseStyles} from "react-jss";
import {Tooltip, Typography} from "@mui/material";

const useStyles = createUseStyles({
    currentEpisode: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "0 18px",
        maxWidth: "184px",
    },
    feedImage: {
        marginRight: "10px",
        height: "28px",
        objectFit: "cover",
        borderRadius: "2px"
    },
    feedInfo: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "150px",
    },
    feedDescription: {
        width: "150px",
        height: "10px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
});

export const CurrentEpisode = ({currentEpisode, feed}) => {
    const classes = useStyles();
    return (
        <div className={classes.currentEpisode}>
            <img
                className={classes.feedImage}
                src={currentEpisode?.image?.href || feed?.image?.href}
                alt={feed?.title}
            />
            <div className={classes.feedInfo}>
                <Typography
                    variant="sub"
                    sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        lineHeight: "12px",
                        width: "100%",
                        height: "100%",
                        margin: "0",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                    }}
                >
                    {currentEpisode?.title}
                </Typography>
                <Tooltip
                    title={currentEpisode?.summary}
                    arrow={true}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 28],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Typography
                        variant="sub"
                        sx={{
                            fontSize: "0.6875rem",
                            fontWeight: "normal",
                            lineHeight: "10px",
                        }}
                    >
                        <div
                            className={classes.feedDescription}
                            dangerouslySetInnerHTML={{__html: currentEpisode?.summary}}
                        />
                    </Typography>
                </Tooltip>
            </div>
        </div>
    );
};
