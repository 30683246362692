import {SidebarContainer} from "./SidebarContainer";
import {AddButton} from "./AddButton";
import React, {useCallback, useState} from "react";
import {
    SET_ACTIVE_MAP,
    useThreeSixtyDispatch,
    useThreeSixtyProps,
    useThreeSixtyTranslation
} from "../App/ThreeSixtyContext";
import {addMaps} from "../App/service";
import useDeepCompareEffect from "use-deep-compare-effect";
import {MapThumbnail} from "./MapThumbnail";
import {useDragDropHandlers} from "./useDragDropHandlers";

export const Maps = () => {
    const t = useThreeSixtyTranslation()
    const {onMediaSelect, data, onChange, adminMode} = useThreeSixtyProps()
    const dispatch = useThreeSixtyDispatch()
    const maps = data?.maps || [];
    const [mapsInternal, setMapsInternal] = useState([])

    const handleChange = updatedMaps => {
        onChange({
            ...data,
            maps: updatedMaps
        })
    }

    const {
        moveHandler,
        dropHandler,
        resetHandler
    } = useDragDropHandlers({
        internalState: mapsInternal,
        setInternalState: setMapsInternal,
        currentState: maps,
        onChange: handleChange
    })

    const handleAddMap = () => {
        const highestDomeId = Math.max(...maps.map(d => d.id), 0)
        if (onMediaSelect) {
            onMediaSelect(
                "image",
                (images) => {
                    const newMaps = images.map(({dmsId, alt}, i) => ({
                        id: highestDomeId ? highestDomeId + 1 + i : 1,
                        order: highestDomeId ? highestDomeId + 1 + i : 1,
                        image: {
                            dmsId: dmsId
                        },
                        title: alt,
                        hotspots: [],
                        iconSize: 50
                    }));
                    onChange(addMaps(data, newMaps));
                },
                "multiple"
            );
        } else {
            onChange(
                addMaps(data, [
                    {
                        id: highestDomeId ? highestDomeId + 1 : 1,
                        order: highestDomeId ? highestDomeId + 1 : 1,
                        image: {
                            dmsId: "019EGGiCQnqnk"
                        },
                        hotspots: [],
                        title: `Kart ${highestDomeId + 1}`,
                        iconSize: 50
                    },
                ])
            );
            console.warn("onMediaSelect is undefined");
        }

        if(highestDomeId === 0) {
            dispatch({
                type: SET_ACTIVE_MAP,
                mapId: 1
            })
        }
    }

    useDeepCompareEffect(() => {
        setMapsInternal(maps.sort((a, b) => a.order - b.order));
    }, [maps])


    return (
        <SidebarContainer>
            {maps.map((map, _idx) => (
                <MapThumbnail
                    index={_idx}
                    key={map.id}
                    map={map}
                    moveCard={moveHandler}
                    dropCard={dropHandler}
                    resetCards={resetHandler}
                    adminMode={adminMode}
                    count={mapsInternal.length}
                />
            ))}
            <AddButton
                onClick={handleAddMap}
                show={adminMode}
            >
                {t('addMapButtonText', "Kartbilde")}
            </AddButton>
        </SidebarContainer>
    )
}