import {Button, Tooltip} from "@mui/material";
import {colors as vmColors} from "@ekultur/vm";
import {ContentCopy} from "@mui/icons-material";
import React from "react";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";
import {addMap, updateMapOrder} from "../App/service";

export const CopyMapButton = ({map, count, index, onClick}) => {
    const t = useThreeSixtyTranslation()
    const {onChange, data} = useThreeSixtyProps()

    const handleCopy = () => {
        const updatedData = addMap(data, {
            ...map,
            id: count + 1,
            order: count + 1,
        });
        onChange(updateMapOrder(updatedData, count, index))
        onClick()
    }

    return (
        <Tooltip
            title={t("copyMapTooltip", "Kopier kart")}
            placement="right"
        >
            <Button
                onClick={handleCopy}
                sx={{color: vmColors.black}}
                startIcon={<ContentCopy />}
            >
                {t("copyMapButtonText", "Kopier kart")}
            </Button>
        </Tooltip>
    )
}