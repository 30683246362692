import React, {useRef, useState} from "react";
import {useThreeSixtyProps} from "../App/ThreeSixtyContext";
import {updateMap} from "../App/service";
import {useActiveMap} from "../map/useActiveMap";
import {HOTSPOT_SELECTED, useMapDispatch} from "../map/mapContext";

export const MapImage = ({mediaId, alt, onClick}) => {
    const {data, onChange, adminMode} = useThreeSixtyProps()
    const dispatch = useMapDispatch()
    const activeMap = useActiveMap()
    const imageRef = useRef(null)
    const [error, setError] = useState(false)
    const imageUrl = `https://ems.dimu.org/image/${mediaId}`
    const src3072 = `${imageUrl}?dimension=3072 3072w`
    const src2048 = `${imageUrl}?dimension=2048x2048 2048w`
    const src1024 = `${imageUrl}?dimension=1024x1024 1024w`
    const src512 = `${imageUrl}?dimension=512 512w`
    const src1200 = `${imageUrl}?dimension=1200x1200`
    const srcMax = `${imageUrl}?dimension=max`

    const handleImageError = () => {
        setError(true)
    }

    const handleImageClick = (event) => {
        const image = imageRef.current
        const { clientX, clientY } = event.nativeEvent;

        // Get image dimensions
        const imageWidth = image.clientWidth;
        const imageHeight = image.clientHeight;

        // Calculate click position in percentages
        const clickX = (clientX - image.getBoundingClientRect().left) / imageWidth * 100;
        const clickY = (clientY - image.getBoundingClientRect().top) / imageHeight * 100;
        onClick({x: clickX, y: clickY})

    }

    const handleDrop = e => {
        const id = e.dataTransfer.getData('text/plain')
        const image = imageRef.current
        const {clientX, clientY} = e.nativeEvent
        const imageWidth = image.clientWidth;
        const imageHeight = image.clientHeight;

        // Calculate click position in percentages
        const dropX = (clientX - image.getBoundingClientRect().left) / imageWidth * 100;
        const dropY = (clientY - image.getBoundingClientRect().top) / imageHeight * 100;

        onChange(
            updateMap(
                data,
                activeMap.id,
                {
                    ...activeMap,
                    hotspots: [
                        ...activeMap.hotspots.filter(h => h.id !== id),
                        {
                            ...activeMap.hotspots.find(h => h.id === id),
                            x: dropX,
                            y: dropY
                        }
                    ]
                }
            )
        )
        dispatch({
            type: HOTSPOT_SELECTED,
            hotspotId: id
        })
    }

    if(error) {
        return (
            <img
                src={srcMax}
                alt={alt}
            />
        )
    } else {
        return (
            <img
                ref={imageRef}
                srcSet={`${src3072}, ${src2048}, ${src1024}, ${src512}`}
                src={src1200}
                alt={alt}
                onError={handleImageError}
                onClick={adminMode ? handleImageClick : null}
                onDrop={adminMode ? handleDrop : null}
            />
        )
    }

}