import {useEffect, useState} from "react";

export const useRssFeedAsJson = (url) => {
    const [rssJson, setRssJson] = useState(null)

    useEffect(
        () => {
            if(url) {
                const host = window._env_ ? window._env_.REACT_APP_API_GATEWAY : process.env.REACT_APP_API_GATEWAY
                fetch(`${host}/vm-public/rss/?url=${encodeURIComponent(url)}`)
                    .then(res => res.json())
                    .then(json => setRssJson(json))
            }
        }, [url]
    )

    return rssJson
}
