import React from "react";
import { createUseStyles } from "react-jss";
import { Alpha, Checkboard, Hue } from "react-color/lib/components/common";
import { Pointer } from "./Pointer";
const useStyles = createUseStyles({
  controls: { padding: "1rem", display: "flex" },
  color: { width: "32px" },
  swatch: {
    marginTop: "6px",
    width: "16px",
    height: "16px",
    borderRadius: "8px",
    position: "relative",
    overflow: "hidden",
  },
  currentColor: {
    absolute: "0px 0px 0px 0px",
    borderRadius: "8px",
    boxShadow: "inset 0 0 0 1px rgba(0,0,0,.1)",
    background: ({ rgb }) => `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
    zIndex: "2",
    height: "100%",
  },
  pickers: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  huePicker: {
    position: "relative",
    width: "100%",
    height: "10px",
    marginBottom: "8px",
  },
  alphaPicker: { position: "relative", width: "100%", height: "10px" },
});

export const ColorControls = ({ rgb, renderers, hsl, onChange }) => {
  const classes = useStyles({ rgb });
  return (
    <div className={classes.controls}>
      <div className={classes.color}>
        <div className={classes.swatch}>
          <div className={classes.currentColor} />
          <Checkboard renderers={renderers} />
        </div>
      </div>
      <div className={classes.pickers}>
        <div className={classes.huePicker}>
          <Hue
            hsl={hsl}
            pointer={Pointer}
            onChange={onChange}
            direction={"horizontal"}
          />
        </div>
        <div className={classes.alphaPicker}>
          <Alpha
            pointer={Pointer}
            rgb={rgb}
            hsl={hsl}
            renderers={renderers}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
