import {DragWrapper} from "../components/DragWrapper";
import React from "react";
import {createUseStyles} from "react-jss";
import {HotspotTypes} from "../helpers/enums";
import {AlignLeft, Image, Volume2, Youtube} from "react-feather";

const useStyles = createUseStyles({
    annotation: {
        position: "relative",
        borderRadius: "50%",
        width: ({highlighted}) =>  highlighted ? 100 : 60,
        height: ({highlighted}) =>  highlighted ? 100 : 60,
        marginTop: "-50%",
        marginLeft: "-50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
        color: ({blackIcon}) => blackIcon ? "#0C0B0F" : "#F0EDE6",
        backgroundColor: ({blackIcon}) => blackIcon ? "rgba(255, 255, 255, 0.9)" : "rgba(12, 11, 15, 0.9)",
        "&:hover": {
            backgroundColor: ({blackIcon}) => blackIcon ? "rgba(255, 255, 255, 1)" : "rgba(12, 11, 15, 1)",
            cursor: "pointer",
            "& svg": {
                transform: "scale(1.3)"
            }
        },
        "& svg": {
            position: "absolute",
        },
        zIndex: 0
    },
})

export const AnnotationComponent = ({onPointerDown, onPointerUp, onPointerMove, onTouchEnd, highlight, blackIcon, isDragging, type}) => {
    const classes = useStyles({
        highlighted: highlight,
        blackIcon: blackIcon
    })

    const iconSize = highlight ? 60 : 36
    const icons = {
        [HotspotTypes.TEXT]: <AlignLeft  size={iconSize}/>,
        [HotspotTypes.IMAGE]: <Image size={iconSize} />,
        [HotspotTypes.VIDEO]: <Youtube size={iconSize} />,
        [HotspotTypes.AUDIO]: <Volume2 size={iconSize} />
    }

    return (
        <div
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onPointerMove={onPointerMove}
            onTouchEnd={onTouchEnd}
            className={classes.annotation}
        >
            {isDragging && <DragWrapper />}
            {icons[type]}
        </div>
    )
}