import React from "react";
import { createUseStyles } from "react-jss";
import { VideoLibraryOutlined } from "@mui/icons-material";
import { getDmsUrl } from "@ekultur/dms-url-generator";

import {
  useThreeSixtyProps,
  useThreeSixtyTranslation,
} from "../../App/ThreeSixtyContext";
import { Upload } from "../../components/Upload";

const useStyles = createUseStyles({
  previewVideo: {
    width: "100%",
  },
});

export const DmsTab = ({ content, updateContent }) => {
  const { onMediaSelect } = useThreeSixtyProps();
  const t = useThreeSixtyTranslation();

  const setVideo = () => {
    if (onMediaSelect) {
      onMediaSelect("video", (dmsId) => {
        updateContent("video", dmsId);
      });
    } else {
      updateContent("video", "013AitqNDu7M");
      console.warn("onMediaSelect is undefined");
    }
  };

  const classes = useStyles();
  return (
    <Upload
      file={content?.video}
      Icon={VideoLibraryOutlined}
      type="video"
      buttonTooltip={t("uploadVideo", "Velg Video")}
      onClick={setVideo}
    >
      <img
        className={classes.previewVideo}
        src={getDmsUrl(content?.video)}
        alt="preview"
      />
    </Upload>
  );
};
