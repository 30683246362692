import { useThreeSixtyState } from "../App/ThreeSixtyContext";
import { useEffect, useState } from "react";
import { getDmsUrl } from "@ekultur/dms-url-generator";

export const useDmsImage = ({ id, dimension = false }) => {
  const { dmsIndex } = useThreeSixtyState();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (id) {
      const getImageDimension = (generatedUrl) => {
        return `${generatedUrl}?dimension=${
          dimension ? dimension : "1200x1200"
        }`;
      };
      setUrl(getImageDimension(getDmsUrl(id, dmsIndex)));
    }
  }, [id, dimension]);

  return url;
};
