import React from "react";
import {HotspotTypes} from "../helpers/enums";
import {ImageModal} from "../Hotspots/ImageModal";
import {VideoModal} from "../Hotspots/VideoModal";
import {useThreeSixtyProps, useThreeSixtyState} from "../App/ThreeSixtyContext";
import {AudioModal} from "../Hotspots/AudioModal";
import {TextBox} from "../Hotspots/TextBox";
import {AudioPlayer} from "../audio/AudioPlayer";

export const HotspotModal = () => {
    const {activeDomeId, modalHotspot} = useThreeSixtyState();
    const {data} = useThreeSixtyProps()
    const activeDome = data?.domes?.find((d) => d.id === activeDomeId);
    const activeHotspot = activeDome?.hotspots?.find((h) => h.id === modalHotspot);

    const hotspotByType = {
        [HotspotTypes.IMAGE]: <ImageModal annotation={activeHotspot}/>,
        [HotspotTypes.VIDEO]: <VideoModal annotation={activeHotspot}/>,
        [HotspotTypes.AUDIO]: <AudioModal annotation={activeHotspot} />,
        [HotspotTypes.TEXT]: <TextBox annotation={activeHotspot}/>
    }

    return (
        <>
            {activeHotspot && hotspotByType[activeHotspot.type]}
            <AudioPlayer/>
        </>
    )
};
