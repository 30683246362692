import React from "react";
import { createUseStyles } from "react-jss";
import { EditableInput } from "react-color/lib/components/common";
import { isValidHex } from "react-color/lib/helpers/color";
const useStyles = createUseStyles({
  field: {
    paddingLeft: "6px",
    width: "100%",
  },
  alpha: {
    paddingLeft: "6px",
    width: "100%",
  },
});

export const HexFields = ({ style, hex, onChange }) => {
  const handleChange = (data, e) => {
    isValidHex(data.hex) && onChange({ hex: data.hex, source: "hex" }, e);
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.field}>
        <EditableInput
          style={style}
          label="hex"
          value={hex}
          onChange={handleChange}
        />
      </div>
      {/*<div className={classes.alpha}>
        <EditableInput
          style={style}
          label="alpha"
          value={hex}
          onChange={handleChange}
        />
      </div>*/}
    </>
  );
};
