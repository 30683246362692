import {useActiveMap} from "./useActiveMap";
import React, {useState} from "react";
import {useThreeSixtyTranslation} from "../App/ThreeSixtyContext";
import {ExpandMore} from "@mui/icons-material";
import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography} from "@mui/material";
import {MapHotspotSettings} from "./MapHotspotSettings";

export const MapHotspotsSettings = () => {
    const [isExpanded, setIsExpanded] = useState(true)
    const t = useThreeSixtyTranslation()
    const activeMap = useActiveMap()

    const handleToggleAccordion = () => {
        setIsExpanded(p => !p)
    }

    return (
        <Accordion
            disableGutters={true}
            elevation={0}
            square={true}
            expanded={isExpanded}
            onChange={handleToggleAccordion}
            sx={{
                backgroundColor: "grey.100"
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    {t("mapHotspotsTitle", "Hotspots")}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    sx={{
                        backgroundColor: "grey.200",
                        p: 2,
                        display: activeMap?.hotspots?.length ? "none" : "flex",
                        justifyContent: "center"
                    }}
                >
                    <Typography>
                        {t('noMapHotspotsPlaceholder', "Ingen hotspots lagt til enda")}
                    </Typography>
                </Box>
                <Stack
                    spacing={1}
                >
                    {activeMap?.hotspots?.sort((a, b) => a.order - b.order)
                        .map((hotspot) => (
                            <MapHotspotSettings
                                key={hotspot.id}
                                hotspot={hotspot}
                            />
                        ))}
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}