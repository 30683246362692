import React, {useCallback, useEffect, useRef, useState} from "react";
import {Frustum, Matrix4} from "three";
import {a, useSpring} from "@react-spring/three";

import {getHotspotAddPosition, isDome, isObject, setCursor,} from "../helpers";
import {Hotspots} from "../Hotspots/Hotspots";
import {Floor} from "./Floor";
import {Wall} from "./Wall";
import {ViewDirectionHelper} from "./ViewDirectionHelper";
import {
    ADD_HOTSPOT_TYPE,
    SET_ACTIVE_DOME,
    SET_ACTIVE_HOTSPOT,
    useThreeSixtyDispatch,
    useThreeSixtyState,
} from "../App/ThreeSixtyContext";
import {addHotspotByType} from "../App/service";
import {SphereComponent} from "./SphereComponent";
import {CursorTypes, HotspotTypes, PortalTypes} from "../helpers/enums";
import {useThree} from "@react-three/fiber";
import {findDomeImage} from "./dome";
import {useDomeRotation} from "./useDomeRotation";

const duration = 300;

export const Sphere = ({activeDome, prevDome, data, onChange, adminMode, textures}) => {
    const ref = useRef(null);
    const {camera} = useThree();
    const [pointerState, setPointerState] = useState({
        pointerDown: false,
        pointerMove: false
    })
    const {id, hotspots, viewDirection} = activeDome;
    const rotationY = useDomeRotation({
        activeDome: activeDome,
        prevDome: prevDome
    })
    const {
        addHotspotType,
    } = useThreeSixtyState();
    const dispatch = useThreeSixtyDispatch();
    const [{opacity}, springApi] = useSpring(
        () => ({
            from: {opacity: 0},
            to: {opacity: 1},
            reset: true,
            config: {
                duration: duration,
            },
        }),
        []
    );

    useEffect(() => {
        springApi.start({opacity: 1, reset: true});
    }, [id, springApi]);

    const onAddHotspotByType = (e) => {
        if (addHotspotType) {
            const position = getHotspotAddPosition(e, addHotspotType, ref);
            if (position) {
                setCursor(CursorTypes.DEFAULT);
                const updatedWithNewHotspot = addHotspotByType(
                    data,
                    id,
                    addHotspotType,
                    {
                        x: position.x,
                        y: position.y,
                        z: position.z,
                    }
                )

                onChange(updatedWithNewHotspot)
                dispatch({type: ADD_HOTSPOT_TYPE, addHotspotType: undefined});
                dispatch({
                    type: SET_ACTIVE_HOTSPOT,
                    hotspotId: Math.max(...updatedWithNewHotspot.domes.find(d => d.id === id).hotspots.map(h => h.id))
                })
            }
        }
    };

    const onUp = useCallback(
        e => {
            if (!adminMode && !pointerState.pointerMove && isDome(e.object)) {
                const surfaceIntersected = e.intersections.find((i) =>
                    isObject(PortalTypes.FLOOR, i.object) || isObject(PortalTypes.WALL, i.object)
                )
                const isSurfaceFirst = e?.distance - 0.5 > surfaceIntersected?.distance;
                if (surfaceIntersected && isSurfaceFirst) {
                    const mousePosition = ref.current.worldToLocal(surfaceIntersected.point.clone());

                    const frustum = new Frustum()
                    const matrix = new Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
                    frustum.setFromProjectionMatrix(matrix)

                    const hotspotDistance = hotspots.filter(hotspot => hotspot.type === HotspotTypes.PORTAL)
                        .filter(hotspot => frustum.containsPoint(ref.current.localToWorld(hotspot.position.clone())))
                        .map(hotspot => ({
                            id: hotspot.id,
                            ...hotspot,
                            distance: mousePosition.distanceTo(hotspot.position),
                            domeId: hotspot.domeId
                        }))
                        .sort((a, b) => a.distance - b.distance)
                    if(hotspotDistance.length > 0 && hotspotDistance[0].distance < 15) {
                        onPortalClick(hotspotDistance[0].domeId)
                    }
                }
            }
            setPointerState({
                pointerDown: false,
                pointerMove: false
            })
        }, [pointerState.pointerMove]
    )

    const onDown = () => setPointerState({
        pointerDown: true,
        pointerMove: false
    })

    const onMove = () => {
        if(pointerState.pointerDown) {
            setPointerState({
                pointerDown: true,
                pointerMove: true
            })
        }
    }


    const onPortalClick = (domeId) => {
        dispatch({type: SET_ACTIVE_DOME, domeId});
    };

    const adminComponents = () => {
        if (adminMode) {
            return (
                <group>
                    <Floor/>
                    <Wall/>
                    <ViewDirectionHelper
                        ref={ref}
                        domeId={id}
                        viewDirection={viewDirection}
                        data={data}
                        onChange={onChange}
                    />
                </group>
            );
        } else {
            return (
                <group>
                    <Floor/>
                    <Wall/>
                </group>
            );
        }
    };

    const sphereComponent = () => {
        if (prevDome && activeDome) {
            return (
                <>
                    <SphereComponent
                        opacity={opacity}
                        fromTexture={textures[findDomeImage(prevDome)]}
                        toTexture={textures[findDomeImage(activeDome)]}
                    />
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <a.mesh
                name={`Dome-${id}`}
                ref={ref}
                rotation-y={rotationY}
                onClick={onAddHotspotByType}
                onPointerUp={onUp}
                onPointerDown={onDown}
                onPointerMove={onMove}
            >
                {sphereComponent()}
                {adminComponents()}
                <Hotspots
                    ref={ref}
                    dome={activeDome}
                    hotspots={hotspots}
                    data={data}
                    onChange={onChange}
                    adminMode={adminMode}
                    opacity={opacity}
                    onPortalClick={onPortalClick}
                />
            </a.mesh>
        </>
    );
};
