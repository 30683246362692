import {AppBarContainer} from "./AppBarContainer";
import {Button} from "./Button";
import {
    SET_SHOW_SIDEPANEL,
    useThreeSixtyDispatch,
    useThreeSixtyState,
    useThreeSixtyTranslation,
} from "../App/ThreeSixtyContext";
import {CollectionsBookmark} from "@mui/icons-material";
import {MapButton} from "../map/MapButton";

export const AppBar = ({about, onClose}) => {
    const {sidePanel} = useThreeSixtyState();
    const t = useThreeSixtyTranslation()

    const dispatch = useThreeSixtyDispatch();

    const toggleShowSidePanel = (event) => {
        event.preventDefault();
        dispatch({type: SET_SHOW_SIDEPANEL, sidePanel: !sidePanel});
    };

    return (
        <AppBarContainer onClose={onClose} {...about}>
            <MapButton />
            <Button
                text={t('sidePanelButtonText', "Liste")}
                icon={<CollectionsBookmark/>}
                onClick={toggleShowSidePanel}
                active={sidePanel}
            />
        </AppBarContainer>
    )

}
