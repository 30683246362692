export const demo2 = {
    settings: {
        colors: [],
    },
    about: {
        exhibition: {
            name: "Linderud Gård",
            description:
                "Dykk ned i Linderud gårds unike historie, menneskene som bodde her og overgangen fra ruvende storgård til sammensmeltning med drabantbyen",
            url: null,
        },
        museum: {
            name: "MiA",
            url: "https://beta.virtueltmuseum.no/museum/74",
        },
        imageUrl: null,
    },
    domes: [
        {
            id: 1,
            order: 1,
            title: "Image 1",
            image: "013AitwxjPKA", //"013AmsgPUoQG",
            viewDirection: {
                x: 9.70721928074764,
                y: -0.030834791589063063,
                z: 2.3515718820729945,
            },
            hotspots: [
                {
                    id: 1,
                    type: "portal",
                    domeId: 2,
                    position: {
                        x: 5.051207419821959,
                        y: -0.5999999999999996,
                        z: 1.2460173106743186,
                    },
                    size: 0.5,
                    portalType: "Floor",
                },
            ],
        },
        {
            id: 2,
            order: 2,
            title: "Image 2",
            image: "013AitwxjieF",
            viewDirection: {
                x: 9.976490453126612,
                y: -0.23559015248815685,
                z: 0.27196792964721633,
            },
            hotspots: [
                {
                    id: 1,
                    type: "text",
                    position: {
                        x: 8.178706544876093,
                        y: 0.6807250896293192,
                        z: 5.692477868354941,
                    },
                    title: "Om Linderud",
                    width: 350,
                    content: {
                        description:
                            "Linderud gård er i dag et museum åpent for publikum med omvisninger, arrangementer og konserter både i hovedbygning og hage. Navnet Linderud oppstod antakelig i tidlig middelalder. Rud betyr rydning. Etter Svartedauden i 1349 lå Linderud øde. På 1500-tallet var det gårdsdrift igjen og gården ble overtatt av kirken. Den var del av det kirkegodset som kongen senere overtok ved reformasjonen i 1536. På denne tiden ble gården drevet av leilendinger. De første eierne av Linderud var Mogens Lauritsøn 1646-1726 og Anne Bentsdatter 1654–1697 som bygslet Linderud fra 1671 og kjøpte gården i 1679.",
                    },
                },
                {
                    id: 2,
                    type: "portal",
                    domeId: 1,
                    position: {
                        x: -3.685996481035791,
                        y: -0.6,
                        z: -0.24287146850237581,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
                {
                    id: 3,
                    type: "portal",
                    domeId: 3,
                    position: {
                        x: 2.4399302529546474,
                        y: -0.6,
                        z: 0.21488590390460455,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
            ],
        },
        {
            id: 3,
            order: 3,
            title: "Image 3",
            image: "013AitwxjieH",
            viewDirection: {
                x: 5.0382733224092,
                y: -0.043739133950379004,
                z: 8.633353169869638,
            },
            hotspots: [
                {
                    id: 1,
                    type: "portal",
                    domeId: 2,
                    position: {
                        x: -1.5711068248765663,
                        y: -0.6000000000000005,
                        z: -2.151952070796165,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
                {
                    id: 2,
                    type: "portal",
                    domeId: 4,
                    position: {
                        x: 1.322160836242331,
                        y: -0.6000000000000002,
                        z: -1.0606899123986349,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
            ],
        },
        {
            id: 4,
            order: 4,
            title: "Image 4",
            image: "013AitwxjieL",
            viewDirection: {
                x: 2.837340589234415,
                y: 0.7551694429573969,
                z: 9.547635006902206,
            },
            hotspots: [
                {
                    id: 1,
                    type: "text",
                    position: {
                        x: 9.93534258599453,
                        y: -0.4910308991434634,
                        z: 0.8656130204348598,
                    },
                    width: 180,
                    content: {
                        description:
                            "Gallauniformen til statsråd Christian Pierre Mathiesen.",
                    },
                },
                {
                    id: 2,
                    type: "text",
                    position: {
                        x: 6.486442518872379,
                        y: -0.5498839042886007,
                        z: -7.569581284377586,
                    },
                    title: "Lorem 1",
                    width: 235,
                    content: {
                        description: "Gulvur signert John Meredith 1750.",
                    },
                },
                {
                    id: 3,
                    type: "portal",
                    domeId: 3,
                    position: {
                        x: -0.8844827600672377,
                        y: -0.6000000000000001,
                        z: -0.2382149790704656,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
                {
                    id: 4,
                    type: "portal",
                    domeId: 5,
                    position: {
                        x: 2.0731942374453967,
                        y: -0.6000000000000002,
                        z: -1.3468222093231257,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
            ],
        },
        {
            id: 5,
            order: 5,
            title: "Image 5",
            image: "013Aitwxjiic",
            viewDirection: {
                x: 3.2353556422565375,
                y: 0.2558903290474496,
                z: 9.444078746871357,
            },
            hotspots: [
                {
                    id: 1,
                    type: "image",
                    position: {
                        x: -0.2012660967598099,
                        y: -0.3406044035452444,
                        z: 9.976069764801013,
                    },
                    width: 300,
                    content: {
                        image: "0138Yu3iUe3A",
                    },
                },
                {
                    id: 2,
                    type: "image",
                    position: {
                        x: 9.834658777259534,
                        y: -0.47337471359409106,
                        z: -1.624603567320994,
                    },
                    title: "Lorem 1",
                    width: 300,
                    content: {
                        image: "0138Yu3iUe7R",
                    },
                },
                {
                    id: 3,
                    type: "image",
                    position: {
                        x: 4.691027130405993,
                        y: -1.4214403747568018,
                        z: -8.698652648019824,
                    },
                    width: 400,
                    content: {
                        image: "0138Yu3iUe7S",
                    },
                },
                {
                    id: 4,
                    type: "portal",
                    domeId: 4,
                    position: {
                        x: -1.9410802020183457,
                        y: -0.5999999999999998,
                        z: 1.0055541769145364,
                    },
                    size: 0.4,
                    portalType: "Floor",
                },
            ],
        },
    ],
    maps: [
        {
            "id": 1,
            "order": 1,
            "image": {
                "dmsId": "019EGGiCQnqnk"
            },
            "hotspots": [
                {
                    "x": 23.275000000000002,
                    "y": 55.9106529209622,
                    "title": "Image 1",
                    "id": "98a5d746-1369-4c23-949c-7ab1a4fb292c",
                    "destination": 2,
                    "label": "Gå hit",
                    "order": 1
                },
                {
                    "x": 76.36849132176235,
                    "y": 46.68864678899082,
                    "title": "Image 1",
                    "id": "4f209134-5e9a-4254-acfd-40f66ea432bb",
                    "destination": 3,
                    "label": "Her er det fint",
                    "order": 4
                }
            ],
            "title": "Kart 1",
            "iconSize": 4
        },
        {
            "id": 2,
            "order": 2,
            "image": {
                "dmsId": "019EGGiCQnqnk"
            },
            "hotspots": [
                {
                    "x": 23.275000000000002,
                    "y": 55.9106529209622,
                    "title": "Image 1",
                    "id": "98a5d746-1369-4c23-949c-7ab1a4fb292c",
                    "destination": 2,
                    "label": "Gå hit",
                    "order": 1
                },
                {
                    "x": 76.36849132176235,
                    "y": 46.68864678899082,
                    "title": "Image 1",
                    "id": "4f209134-5e9a-4254-acfd-40f66ea432bb",
                    "destination": 3,
                    "label": "Her er det fint",
                    "order": 4
                }
            ],
            "title": "Kart over uteområdet",
            "iconSize": 4
        }
    ]
}