import React from "react";
import {createUseStyles} from "react-jss";
import {IconButton, Tooltip} from "@mui/material";
import {placeholderBackground} from "../helpers/misc";
import {useDmsImage} from "../media/useDmsImage";

const useStyles = createUseStyles({
  uploadImage: {
    top: "2px",
    left: "calc(100% - 52px)",
    cursor: "pointer",
  },
  placeholder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#A6A6A661",
    height: "150px",
    backgroundRepeat: "repeat",
    backgroundSize: "auto",
  },
  preview: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#A6A6A661",
    height: "150px",
    backgroundRepeat: "repeat",
    backgroundSize: "auto",
  },
});

export const Upload = ({
  Icon,
  file,
  onClick,
  buttonTooltip,
  type = "image",
  children,
}) => {

  const classes = useStyles();
  const typeImageUrl = useDmsImage({
    id: placeholderBackground(type),
  })


  return (
    <label className={classes.uploadImage} htmlFor="upload" onClick={onClick}>
      {!file ? (
        <div
          className={classes.placeholder}
          style={{
            backgroundImage: `url(${typeImageUrl})`,
          }}
        >
          <Tooltip title={buttonTooltip}>
            <IconButton
              aria-label={buttonTooltip}
              component="span"
              sx={{
                backgroundColor: "#FAFAFA",
              }}
            >
              {Icon && <Icon />}
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <div className={classes.preview}>
          {children}
          <Tooltip title={buttonTooltip}>
            <IconButton
              aria-label={buttonTooltip}
              component="span"
              sx={{
                backgroundColor: "#FAFAFA",
                position: "absolute",
              }}
            >
              {Icon && <Icon />}
            </IconButton>
          </Tooltip>
        </div>
      )}
    </label>
  );
};
