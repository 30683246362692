import {useImageBitmapLoader} from "./useImageBitmapLoader";
import {useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {CanvasTexture, EquirectangularReflectionMapping, LinearFilter} from "three";
import {useBitmapUrl} from "../media/useBitmapUrl";

export const useTexturePreload = imageIds => {
    const [loadedImages, setLoadedImages] = useState({})
    const loader = useImageBitmapLoader()
    const newImages = imageIds.filter(imageId => !loadedImages[imageId]);
    const bitmapUrls = useBitmapUrl(newImages)

    useDeepCompareEffect(
        () => {
            return () => {
                for(const imageId of imageIds) {
                    loadedImages[imageId]?.dispose()
                }
            }
        }, [imageIds]
    )

    useDeepCompareEffect(
        () => {
            if(loader) {
                bitmapUrls.forEach(bitmapUrl => {
                    loader.load(
                        bitmapUrl.url,
                        imageBitmap => {
                            let texture = new CanvasTexture(imageBitmap)
                            texture.minFilter = LinearFilter
                            texture.mapping = EquirectangularReflectionMapping
                            setLoadedImages(prevLoaded => ({
                                ...prevLoaded,
                                [bitmapUrl.dmsId]: texture
                            }))
                        }
                    )
                })

            }
        }, [newImages, loader]
    )

    return loadedImages
}