import React from "react";
import {createUseStyles} from "react-jss";
import {Drawer} from "@mui/material";
import {useThreeSixtyProps, useThreeSixtyState,} from "../App/ThreeSixtyContext";
import {SidebarTabs} from "./SidebarTabs";
import {SidebarContent} from "./SidebarContent";
import {RenderIfAdminMode} from "../Admin/RenderIfAdminMode";

const useStyles = createUseStyles({
    sidebar: {
        width: "240px",
        overflowX: "hidden",
        overflowY: "auto",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#757575",
        height: "100%",
    },
});

export const Sidebar = ({isAdminSidePanel}) => {
    const {adminMode, data} = useThreeSixtyProps()
    const {sidePanel} = useThreeSixtyState();

    const isOpen = adminMode ? isAdminSidePanel : sidePanel;


    const classes = useStyles();
    return (
        <Drawer
            anchor="left"
            hideBackdrop={true}
            variant="persistent"
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                flexShrink: 0,
                width: !isOpen ? "0" : "240px",
                transition: "width 150ms ease-in",
                [`& .MuiDrawer-paper`]: {
                    position: "initial",
                    boxSizing: "border-box",
                    overflowX: "hidden",
                },
            }}
            open={isOpen}
            transitionDuration={150}
        >
            <RenderIfAdminMode>
                <SidebarTabs/>
            </RenderIfAdminMode>
            <div className={classes.sidebar}>
                <SidebarContent
                    domes={data?.domes}
                />
            </div>
        </Drawer>
    );
};
