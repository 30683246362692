import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  logoGray: {
    position: "absolute",
    zIndex: "1",
    width: "100%",
    height: "initial",
    opacity: 0.2,
  },
});

export const LogoGray = () => {
  const classes = useStyles();
  const black = "#0C0B0F";
  return (
    <svg
      width="83"
      height="90"
      viewBox="0 0 83 90"
      fill="none"
      className={classes.logoGray}
    >
      <path
        d="M27.293 32.9141L38.3192 38.2371C40.5141 39.2967 43.0724 39.2979 45.2683 38.2404L56.0039 33.0703"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.293 32.9141L46.315 37.731C43.5501 39.0658 41.793 41.8652 41.793 44.9354V54.7289"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.79297 37.9141V26.2569C2.79297 23.2799 4.44611 20.549 7.08388 19.1687L16.0508 14.4766"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.01 27.9582L8.07364 24.0287C5.87616 22.9406 5.84127 19.819 8.01388 18.6821L16.0508 14.4766"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2131 28.5121L8.51876 24.3656C5.86416 23.0996 2.79688 25.035 2.79688 27.976V37.915"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.793 81.4141L38.076 86.9229C40.4023 88.1691 43.1935 88.1878 45.5364 86.973L55.293 81.9141"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8125 52.4063L2.99576 62.6534C3.04743 65.5428 6.05209 67.4257 8.67485 66.2121L12.375 64.5"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.2383 53.4141L80.055 63.6612C80.0033 66.5507 76.9987 68.4335 74.3759 67.2199L70.6758 65.5078"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.543 72.1641L41.543 82.6691C41.543 85.4887 44.3531 87.3506 46.8834 86.1066C51.0111 84.0774 55.2188 81.7891 55.2188 81.7891"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.5352 72.1641L41.5352 82.3175C41.5352 85.1371 38.7241 87.0014 36.1898 85.7656C32.0229 83.7337 27.75 81.4375 27.75 81.4375"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.79297 52.4141L3.03259 64.3217C3.08981 67.1655 4.65279 69.7652 7.13768 71.1495L14.793 75.4141"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.293 36.9141V26.2877C80.293 23.2945 78.622 20.5516 75.9621 19.1788L64.793 13.4141"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.293 53.4141V64.0452C80.293 67.0359 78.6248 69.7769 75.9684 71.1509L65.793 76.4141"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2802 8.91406L45.4693 3.31728C43.1636 2.12364 40.4221 2.12315 38.116 3.31596L27.293 8.91406"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.793 28.4141L74.5066 24.2293C77.162 22.954 80.2383 24.8892 80.2383 27.835V36.8516"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.7915 18.2919L41.7915 8.46618C41.7915 5.55202 44.8078 3.61596 47.4573 4.82952L56.25 8.85696"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.7944 18.2919L41.7944 8.46618C41.7944 5.55202 38.7781 3.61596 36.1287 4.82952L27.3359 8.85696"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.1117 28.2955L74.9013 24.0303C77.1243 22.9517 77.1632 19.7987 74.9676 18.6655L64.793 13.4141"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.293 32.9141L37.271 37.731C40.0359 39.0658 41.793 41.8652 41.793 44.9354V54.7289"
        stroke={`${black}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9994 47.9752L27.3433 46.0517L22.5341 60.7138L17.6967 40.9764L13.8438 38.9492L20.4529 64.9577L24.4465 67.0589L30.9994 47.9752Z"
        fill={`${black}`}
      />
      <path
        d="M70.9097 57.9931L71.2493 37.1095L66.8772 39.7107L61.9221 56.8367L56.9934 44.8946L52.3828 46.9414V68.8019L55.695 66.8313V51.0449L60.5442 63.638L63.0615 62.1404L67.9371 45.3391L67.5974 59.9228L70.9097 57.9931Z"
        fill={`${black}`}
      />
    </svg>
  );
};
