import React from 'react';
import {createUseStyles} from 'react-jss';
import {Button} from './Button';
import * as Icon from 'react-feather';
import {DmLogo} from "../components/icons/DmLogo";

const useStyles = createUseStyles({
  appBarContainer: {
    height: 64,
    flex: '0 0 auto',
    background: '#F6F5F8',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#0C0B0F',
    fontSize: 14,
    cursor: 'default',
    zIndex: 2,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 10px',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > a': {
      margin: '0 10px',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '& > a': {
        textDecoration: 'none',
        color: 'inherit',
        '&:first-of-type': {
          fontWeight: 'bold',
        },
      },
      '@media (max-width: 700px)': {
        display: 'none',
      },
    },
  },
});

export const AppBarContainer = ({ exhibition, museum, onClose, children }) => {
  const styles = useStyles();

  return (
    <div className={styles.appBarContainer}>
      <div className={styles.logo}>
        <a href="https://digitaltmuseum.no/">
          <DmLogo />
        </a>
        <div>
          {exhibition?.name && <a href={exhibition.url}>{exhibition.name}</a>}
          {museum?.name && <a href={museum.url}>{museum.name}</a>}
        </div>
      </div>
      <div className={styles.buttons}>
        {children}
        {onClose && (
          <Button text="Avslutt" icon={<Icon.LogOut />} onClick={onClose} />
        )}
      </div>
    </div>
  );
};
