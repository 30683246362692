import React from "react";
import {
  DeleteOutline,
  ExpandMore,
  PhotoLibraryOutlined,
} from "@mui/icons-material";
import { createUseStyles } from "react-jss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

import {
  useThreeSixtyProps,
  useThreeSixtyTranslation,
} from "../../App/ThreeSixtyContext";
import { updateHotspot } from "../../App/service";
import { Upload } from "../../components/Upload";
import { TextField } from "../../components/TextField";
import { useDmsImage } from "../../media/useDmsImage";
import {HighlightHotspotCheckbox} from "../hotspot/HighlightHotspotCheckbox";
import {WhiteHotspotCheckbox} from "../hotspot/WhiteHotspotCheckbox";

const useStyles = createUseStyles({
  icon: { marginRight: "5px" },
  previewImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

export const EditImage = ({
  hotspot,
  dome,
  expanded,
  onExpandChange,
  onDelete,
}) => {
  const { id, content, title, showLabel, highlight, whiteBackground } = hotspot;
  const t = useThreeSixtyTranslation();
  const { onChange, data, onMediaSelect } = useThreeSixtyProps();
  const classes = useStyles();
  const previewImageUrl = useDmsImage({
    id: content?.image,
  });

  const update = (key, value) => {
    onChange(
      updateHotspot(data, dome.id, hotspot.id, {
        [key]: value,
      })
    );
  };
  const updateContent = (key, value) => {
    update("content", { ...hotspot.content, [key]: value });
  };

  const setImage = () => {
    if (onMediaSelect) {
      onMediaSelect("image", (dmsId) => {
        updateContent("image", dmsId);
      });
    } else {
      updateContent("image", "013Aitiph5MJ");
      console.warn("onMediaSelect is undefined");
    }
  };

  return (
    <Accordion
      disableGutters
      elevation={5}
      square
      sx={{ display: "flex", flexDirection: "column" }}
      expanded={expanded === id.toString()}
      onChange={onExpandChange(id.toString())}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <PhotoLibraryOutlined className={classes.icon} />
        <Typography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "85px",
            overflow: "hidden",
          }}
        >
          {hotspot?.title || "-No title-"}
        </Typography>
        <Typography sx={{ color: "#00000099", marginLeft: "5px" }}>
          {t("interactionType.image", "Bilde")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
        <HighlightHotspotCheckbox
            value={highlight}
            onChange={checked => update('highlight', checked)}
        />
        <WhiteHotspotCheckbox
            value={whiteBackground}
            onChange={checked => update('whiteBackground', checked)}
        />
        <TextField
          id="label-textfield"
          label={t("labelLabel", "Label")}
          variant="filled"
          value={title}
          onChange={(value) => update("title", value)}
          sx={{ margin: "10px 0" }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showLabel || false}
                onChange={(e) => update("showLabel", e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("showLabelLabel", "Vis label")}
          />
        </FormGroup>
        <TextField
          id="description-textfield"
          label={t("descriptionLabel", "Beskrivelse")}
          variant="filled"
          sx={{ margin: "10px 0" }}
          multiline
          minRows={4}
          maxRows={6}
          value={content?.description}
          onChange={(value) => updateContent("description", value)}
        />
        <Upload
          file={content?.image}
          Icon={PhotoLibraryOutlined}
          type="image"
          buttonTooltip={t("uploadImage", "Velg bilde")}
          onClick={setImage}
        >
          <img
            className={classes.previewImage}
            src={previewImageUrl}
            alt="preview"
          />
        </Upload>
        <Button
          sx={{ marginTop: "20px" }}
          variant="outlined"
          color="error"
          onClick={onDelete}
        >
          <DeleteOutline />
          {t("deleteImageHotspot", "Slett Hotspot")}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
