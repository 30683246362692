import useDeepCompareEffect from "use-deep-compare-effect";
import {kitFetch} from "@ekultur/fetch";
import {useImagesWithoutAdditionalDimensions} from "./useImagesWithoutAdditionalDimensions";

const isHighRes = (width, height) => width >= 8192 && height >= 4096

const buildAdditionalDimensions = (width, height) => {
    if(isHighRes(width, height)) {
        return "4096x2048, 8192x4096"
    } else {
        return "4096x2048"
    }
}

export const useAddAdditionalDimensions = dmsIds => {
    const dmsIdsWithoutDimensions = useImagesWithoutAdditionalDimensions(dmsIds)
    useDeepCompareEffect(
        () => {
            dmsIdsWithoutDimensions.forEach(dmsId => {
                fetch(
                    `https://mm.dimu.org/image/${dmsId}`,
                    {
                        method: "HEAD"
                    }
                )
                    .then(response => {
                        const [width, height] = response.headers.get("Image-Property-dimension").split('x')
                        const additionalDimensions = buildAdditionalDimensions(width, height)
                        kitFetch(
                            `/vm/dms/additional-dimensions/${dmsId}/`,
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    additionalDimensions: additionalDimensions
                                })
                            }
                        )
                            .then(() => console.debug(`Added dimensions for ${dmsId}`))
                            .catch(() => console.debug(`Error adding dimensions for ${dmsId}`))
                    })
            })
        }, [dmsIdsWithoutDimensions]
    )
}