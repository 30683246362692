import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";
import {Box, Snackbar, Stack} from "@mui/material";
import {MapImage} from "../image/MapImage";
import {updateMap} from "../App/service";
import {MapHotspots} from "./MapHotspots";
import {PlaceHolderContainer} from "../Admin/PlaceHolderContainer";
import {NoMapsPlaceHolder} from "./NoMapsPlaceHolder";
import {useActiveMap} from "./useActiveMap";
import {HOTSPOT_SELECTED, useMapDispatch} from "./mapContext";
import {useEffect, useState} from "react";

export const SelectedMap = () => {
    const {data, onChange, adminMode} = useThreeSixtyProps()
    const [showHelp, setShowHelp] = useState(false)
    const dispatch = useMapDispatch()
    const activeMap = useActiveMap()
    const t = useThreeSixtyTranslation()

    useEffect(() => {
        setShowHelp(adminMode)
    }, [adminMode]);

    if(!data?.maps?.length) {
        return (
            <PlaceHolderContainer>
                <NoMapsPlaceHolder />
            </PlaceHolderContainer>
        )
    }

    if(!activeMap) {
        return null
    }

    const handleHideHelp = () => setShowHelp(false)

    const handleAddHotspot = ({x, y}) => {
        const nextOrder = Math.max(...activeMap.hotspots.map(h => h.order), 0) + 1

        const newId = crypto.randomUUID();
        onChange(
            updateMap(
                data,
                activeMap.id,
                {
                    ...activeMap,
                    hotspots: [
                        ...activeMap.hotspots,
                        {
                            x: x,
                            y: y,
                            title: t('noTitlePlaceholder', "Uten tittel"),
                            id: newId,
                            destination: null,
                            label: t('noTitlePlaceholder', "Uten tittel"),
                            order: nextOrder
                        }
                    ]
                }
            )
        )

        dispatch({
            type: HOTSPOT_SELECTED,
            hotspotId: newId
        })
    }

    return (
        <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
                width: "100%",
                p: adminMode ? 4 : 0,
                flex: "1 1 auto",
                "img": {
                    width: "100%",
                    objectFit: "contain",
                    backgroundColor: "white",
                    cursor: adminMode ? "crosshair" : "default"
                }
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    width: "100%"
                }}
            >
                <MapImage
                    mediaId={activeMap.image.dmsId}
                    alt={activeMap.title}
                    onClick={handleAddHotspot}
                />
                <MapHotspots
                    hotspots={activeMap.hotspots}
                    iconSize={activeMap.iconSize}
                />
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={showHelp}
                autoHideDuration={5000}
                onClose={handleHideHelp}
                message={t('mapHotspotHelp', "Klikk i bildet for å legge til hotspot")}
                ContentProps={{
                    sx: {
                        justifyContent: "center",
                    }
                }}
            />
        </Stack>
    )
}