import React from "react";
import { createUseStyles } from "react-jss";
import { isValidHex } from "react-color/lib/helpers/color";
const useStyles = createUseStyles({
  templateColors: {
    display: "flex",
    padding: "1rem",
    justifyContent: "space-around",
  },
  colorCircle: {
    borderRadius: "50%",
    width: "20px",
    height: "20px",
  },
});

export const TemplateColors = ({ templateColors, onChange }) => {
  const handleChange = (hex, e) => {
    isValidHex(hex) && onChange({ hex, source: "hex" }, e);
  };
  const classes = useStyles();
  return (
    <div className={classes.templateColors}>
      {templateColors.map(({ key, value }) => (
        <div
          key={key}
          className={classes.colorCircle}
          style={{ backgroundColor: value }}
          onClick={(e) => handleChange(value, e)}
        />
      ))}
    </div>
  );
};
