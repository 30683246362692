import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    placeLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh"
    }
});


export const LogoPlacer = ({children}) => {
    const classes = useStyles()

    return (
        <div
            className={classes.placeLogo}
        >
            {children}
        </div>
    )
}