import {Button, Tooltip} from "@mui/material";
import {colors as vmColors} from "@ekultur/vm";
import {SwapHoriz} from "@mui/icons-material";
import React from "react";
import {updateDome, updateMap} from "../App/service";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../App/ThreeSixtyContext";

export const SwapMapImageButton = ({map, onClick}) => {
    const t = useThreeSixtyTranslation()
    const {onChange, data, onMediaSelect} = useThreeSixtyProps()

    const handleSwap = () => {
        if (onMediaSelect) {
            onMediaSelect(
                "image",
                image => {
                    onChange(
                        updateMap(
                            data,
                            map.id,
                            {
                                ...map,
                                image: {
                                    dmsId: image.dmsId
                                }
                            }
                        )
                    )
                },
                "single"
            );
        } else {
            onChange(
                updateMap(
                    data,
                    map.id,
                    {
                        ...map,
                        image: {
                            dmsId: '013AitwxjPKA'
                        }
                    }
                )
            )
            console.warn("onMediaSelect is undefined");
        }
        onClick()
    }
    return (
        <Tooltip
            title={t("swapMapTooltip", "Bytt kartbilde")}
            placement="right"
        >
            <Button
                onClick={handleSwap}
                sx={{color: vmColors.black}}
                startIcon={<SwapHoriz />}
            >
                {t("swapMapButtonText", "Bytt kartbilde")}
            </Button>
        </Tooltip>
    )
}