import {ArrowDown, ArrowDownCircle, Circle, CornerUpLeft, CornerUpRight} from "react-feather";
import React from "react";

export const iconOptions = [
    {
        id: 'circle',
        Icon: Circle,
        label: "Sirkel",
        dmsId: '019EBvjQtSn7D'
    },
    {
        id: 'arrow-in-circle',
        Icon: ArrowDownCircle,
        label: 'Pil i sirkel',
        dmsId: '019EBvFYna28d'
    },
    {
        id: 'left-corner',
        Icon: CornerUpLeft,
        label: 'Hjørne-venstre',
        dmsId: '019EBvFYnY16B'
    },
    {
        id: 'right-corner',
        Icon: CornerUpRight,
        label: "Hjørne-høyre",
        dmsId: '019EBvFYnYLa4'
    },
    {
        id: 'arrow',
        Icon: ArrowDown,
        label: "Pil",
        dmsId: '019EBxErEtiFZ'
    }
]