import {useTabState} from "./tabContext";
import {Domes} from "./Domes";
import React from "react";
import {Maps} from "./Maps";

export const SidebarContent = ({domes}) => {
    const {tab} = useTabState()


    if(0 === tab) {
        return (
            <Domes domes={domes} />
        )
    } else if(1 === tab) {
        return (
            <Maps />
        )
    } else {
        throw new Error(`Unknown tab: ${tab}`)
    }
}