import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px',
  },
  list: {
    padding: '8px 0',
    margin: 0,
    listStyle: 'none',
    '& > li': {
      padding: '12px 16px',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
});

export const DemoLinks = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div>
        <h2>Demo'er</h2>
        <ul className={styles.list}>
          <li>
            <Link to="/360/1">360 1</Link>
          </li>
          <li>
            <Link to="/360/2">360 2</Link>
          </li>
          <li>
            <Link to="/360/4">360 4</Link>
          </li>
          <li>
            <Link to="/360/5">360 5</Link>
          </li>
          <li>
            <Link to="/360/6">360 6</Link>
          </li>
        </ul>
      </div>
      <div>
        <h2>Admin Demo'er</h2>
        <ul className={styles.list}>
          <li>
            <Link to="/admin/1">Admin 1</Link>
          </li>
          <li>
            <Link to="/admin/2">Admin 2</Link>
          </li>
          <li>
            <Link to="/admin/3">Admin 3</Link>
          </li>
          <li>
            <Link to="/admin/4">Admin Empty</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
