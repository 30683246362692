export const demo6 = {
    "domes": [
        {
            "hotspots": [
                {
                    "domeId": 2,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.007469012303374273,
                        "y": -0.5999999999999996,
                        "z": 1.2328496388770986
                    },
                    "size": 0.6,
                    "type": "portal",
                    "showLabel": true,
                    'label': 'gult rom'
                }
            ],
            "id": 1,
            "image": "019EBxEjfvFCS",
            "order": 1,
            "title": "green-room.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 1,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.012059298762301513,
                        "y": -0.6000000000000002,
                        "z": -1.0144157408867147
                    },
                    "size": 0.6,
                    "type": "portal",
                    "showLabel": true,
                    'label': 'grønt rom'
                },
                {
                    "domeId": 3,
                    "id": 2,
                    "order": 2,
                    "portalType": "Floor",
                    "position": {
                        "x": -0.00017323582366694202,
                        "y": -0.5999999999999998,
                        "z": 1.0854932488105364
                    },
                    "size": 0.6,
                    "type": "portal",
                    "showLabel": true,
                    'label': 'rødt rom'
                }
            ],
            "id": 2,
            "image": "019EBxEjfvFCT",
            "order": 2,
            "title": "yellow-room.jpg",
            "viewDirection": {
                "x": 0,
                "y": 0,
                "z": 9.9
            }
        },
        {
            "hotspots": [
                {
                    "domeId": 2,
                    "id": 1,
                    "order": 1,
                    "portalType": "Floor",
                    "position": {
                        "x": 0.9600659687596627,
                        "y": -0.5999999999999999,
                        "z": 0.4478663461669486
                    },
                    // "position": {
                    //     "x": 6.635069503758204,
                    //     "y": -4.881971296256246,
                    //     "z": 5.641217534120419
                    // },
                    "showLabel": true,
                    'label': 'gult rom',
                    "size": 0.6,
                    "type": "portal"
                }
            ],
            "id": 3,
            "image": "019EBxEjfvFCU",
            "order": 3,
            "title": "red-room-north-offset.jpg",
            "viewDirection": {
                "x": 6.635069503758204,
                "y": -4.881971296256246,
                "z": 5.641217534120419
            },
            // "viewDirection": {
            //     "x": 0.9600659687596627,
            //     "y": -0.5999999999999999,
            //     "z": 0.4478663461669486
            // }
        }
    ],
    "settings": {
        "colors": [
            {
                "key": 0,
                "value": null
            },
            {
                "key": 1,
                "value": null
            },
            {
                "key": 2,
                "value": null
            },
            {
                "key": 3,
                "value": null
            }
        ]
    }
}