import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Demo360 } from './Demo360';
import { DemoLinks } from './DemoLinks';
import { DemoAdmin } from './DemoAdmin';

export const Demo = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DemoLinks />} />
        <Route path="360/:id" element={<Demo360 />} />
        <Route path="admin/:id" element={<DemoAdmin />} />
      </Routes>
    </BrowserRouter>
  );
};
