import {createContext, useContext, useReducer} from "react";

export const PLAYER_OPENED = 'playerOpened'
export const PLAYER_CLOSED = 'playerClosed'
export const PLAY_TRIGGERED = 'playTriggered'
export const PAUSE_TRIGGERED = 'pauseTriggered'
export const EPISODE_CHANGED = 'episodeChanged'

const AudioStateContext = createContext(undefined)
const AudioDispatchContext = createContext(undefined)

const initialState = {
    feedData: null,
    currentEpisode: null,
    audioSrc: undefined,
    show: false,
    playing: false,
    isPodcast: false
}

const audioReducer = (state, action) => {
    console.dir(action, {depth: 1})
    switch (action.type) {
        case PLAYER_OPENED:
            return {
                ...state,
                show: true,
                feedData: action.feedData,
                currentEpisode: action.currentEpisode,
                audioSrc: action.audioSrc,
                isPodcast: action.isPodcast,
                playing: false
            }
        case PLAYER_CLOSED:
            return {
                ...state,
                show: false,
                playing: false,
            }
        case PLAY_TRIGGERED:
            return {
                ...state,
                playing: true,
                show: true
            }
        case PAUSE_TRIGGERED:
            return {
                ...state,
                playing: false
            }
        case EPISODE_CHANGED:
            return {
                ...state,
                currentEpisode: action.currentEpisode,
                playing: true
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const AudioProvider = ({children}) => {
    const [state, dispatch] = useReducer(audioReducer, initialState, undefined)

    return (
        <AudioStateContext.Provider value={state}>
            <AudioDispatchContext.Provider value={dispatch}>
                {children}
            </AudioDispatchContext.Provider>
        </AudioStateContext.Provider>
    )
}

export const useAudioState = () => {
    const context = useContext(AudioStateContext)
    if (context === undefined) {
        throw new Error('useAudioState must be used within a AudioProvider')
    } else {
        return context
    }
}

export const useAudioDispatch = () => {
    const context = useContext(AudioDispatchContext)
    if (context === undefined) {
        throw new Error('useAudioDispatch must be used within a AudioProvider')
    } else {
        return context
    }
}