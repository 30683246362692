import React from "react";
import {createUseStyles} from "react-jss";

import {AppBar} from "../AppBar/AppBar";
import {useThreeSixtyProps, useThreeSixtyState,} from "../App/ThreeSixtyContext";
import {Sidebar} from "../Sidebar/Sidebar";
import {useCheckDuplicateDomeIds} from "../Dome/useCheckDuplicateDomeIds";
import {useInitialize} from "../Dome/useInitialize";
import {RenderUnlessAdminMode} from "../Admin/RenderUnlessAdminMode";
import {TabContent} from "../App/TabContent";
import {MapDialog} from "../map/MapDialog";
import {SettingsBar} from "../Admin/SettingsBar";
import {RenderIfAdminMode} from "../Admin/RenderIfAdminMode";

const useStyles = createUseStyles({
    page: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "#ACACACCA",
    },
    threeSixtyView: {
        width: "100dvw",
        maxWidth: "100dvw",
        height: ({adminMode}) => (adminMode ? "100%" : "calc(100% - 64px)"),
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
})

export const ThreeSixty = ({onClose}) => {
    const {data, adminMode, sidePanel} = useThreeSixtyProps();
    const {activeDomeId} = useThreeSixtyState();
    const classes = useStyles({adminMode});
    useCheckDuplicateDomeIds(data?.domes)
    useInitialize(data?.domes)

    if (activeDomeId === null) {
        return null
    }

    return (
        <div className={classes.page}>
            <RenderUnlessAdminMode>
                <AppBar about={data?.about} onClose={onClose}/>
                <MapDialog/>
            </RenderUnlessAdminMode>
            <div className={classes.threeSixtyView}>
                <Sidebar isAdminSidePanel={sidePanel}/>
                <TabContent/>
                <RenderIfAdminMode>
                    <SettingsBar/>
                </RenderIfAdminMode>

            </div>
        </div>
    );
};
