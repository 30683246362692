import React from "react";

export const ThreeSixtyIcon = () => {
  return (
    <svg width="91" height="60" viewBox="0 0 91 60" fill="none">
      <path
        d="M45.4997 0.791626C20.4297 0.791626 0.0830078 10.965 0.0830078 23.5C0.0830078 33.6733 13.4355 42.257 31.8747 45.1637V59.8333L50.0413 41.6666L31.8747 23.5V35.8987C17.5684 33.3554 9.16634 27.2695 9.16634 23.5C9.16634 18.6858 22.973 9.87496 45.4997 9.87496C68.0263 9.87496 81.833 18.6858 81.833 23.5C81.833 26.8154 75.2022 32.0837 63.6663 34.9904V44.3008C79.6984 40.8037 90.9163 32.8104 90.9163 23.5C90.9163 10.965 70.5697 0.791626 45.4997 0.791626Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};
