import React, { useState } from "react";
import { useParams } from "react-router";
import { App } from "../App/App";

export const DemoAdmin = () => {
  const { id } = useParams();
  const [data, setData] = useState(scenes[id]);
  const [sidePanel, setSidePanel] = useState(true);
  return (
    <div style={{ height: "100%" }}>
      <button
        style={{ position: "absolute", bottom: 0, right: 0 }}
        onClick={() => setSidePanel(!sidePanel)}
      >
        Sidepanel
      </button>
      <App
        adminMode={true}
        data={data}
        sidePanel={sidePanel}
        onChange={(newData) => {
          console.log(newData);
          setData(newData);
        }}
        onFeedback={{
          snackbarError: message => console.error(message),
          snackbarWarning: message => console.warn(message),
          snackbarInfo: message => console.info(message),
          snackbarSuccess: message => console.debug(message)
        }}
      />
    </div>
  );
};

const scenes = {
  1: {
    settings: {
      colors: [],
    },
    domes: [
      {
        id: 1,
        order: 1,
        title: "Image 1",
        image: "019EGGiCQnW9x",
        viewDirection: {
          x: -1.705109656498648,
          y: -0.09019278301109437,
          z: -9.837750268049565,
        },
        hotspots: [
          {
            id: 1,
            type: "text",
            position: {
              x: 1.1166668659985184,
              y: -0.6445823268706286,
              z: -9.908306965501843,
            },
            title: "Lorem 1",
            width: 450,
            content: {
              image: "0136NvASPz4a",
              description: "Lorem ipsum dolor sit amet",
            },
          },
          {
            id: 2,
            type: "portal",
            domeId: 2,
            position: {
              x: -1.0788633278356654,
              y: -0.6000000000000006,
              z: -2.9797499102632976,
            },
            size: 0.4,
            portalType: "Floor",
          },
          {
            id: 3,
            type: "image",
            position: {
              x: 3.1234338113933195,
              y: -4.21098108260259,
              z: -8.494119580696431,
            },
            title: "Lorem 1",
            width: 450,
            content: {
              image: "0136NvASPz4a",
              description: "Lorem ipsum dolor sit amet",
            },
          },
          {
            id: 4,
            type: "video",
            videoType: "Dms",
            position: {
              x: 3.456556915962922,
              y: 2.1775814831895195,
              z: -9.10774785811625,
            },
            title: "Lorem 1",
            width: 450,
            content: {
              video: "013AitqNDu7M",
              description: "Lorem ipsum dolor sit amet",
            },
          },
          {
            audioType: "Rss",
            content: {
              audio: "",
              rss: "https://feeds.buzzsprout.com/1913076.rss",
            },
            id: 5,
            order: 5,
            position: {
              x: -0.8082662786939512,
              y: -1.298561907491472,
              z: -9.86598145060044,
            },
            type: "audio",
          },
        ],
      },
      {
        id: 2,
        order: 2,
        title: "Image 2",
        image: "019EGGiCQnW9y",
        viewDirection: {
          x: 2.9739330277608778, //x: -3.4524185257564532,
          y: 1.3462326323651723, //y: -0.12761934572560937,
          z: 9.441115101626384, //z: -9.36614712798078
        },
        hotspots: [
          {
            id: 1,
            type: "text",
            position: {
              x: -17.5,
              y: -2,
              z: -10.5,
            },
            title: "Lorem ipsum dolor sit amet",
            content: {
              image: "019EGGiCQnW9y",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ullamcorper vehicula nibh, ac semper magna convallis molestie.",
            },
          },
          {
            id: 2,
            type: "text",
            position: {
              x: 13,
              y: -2,
              z: -15,
            },
            title: "Lorem ipsum dolor sit amet",
            content: {
              image: "0136NvASPz92",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
            },
          },
          {
            id: 3,
            type: "portal",
            domeId: 1,
            position: {
              x: -1.0520915772329529,
              y: -0.6000000000000008,
              z: -2.9025677682864273,
            },
            size: 0.4,
            portalType: "Floor",
          },
        ],
      },
    ],
  },
  2: {
    settings: {
      colors: [],
    },
    about: {
      exhibition: {
        name: "Linderud Gård",
        description:
          "Dykk ned i Linderud gårds unike historie, menneskene som bodde her og overgangen fra ruvende storgård til sammensmeltning med drabantbyen",
        url: null,
      },
      museum: {
        name: "MiA",
        url: "https://beta.virtueltmuseum.no/museum/74",
      },
      imageUrl: null,
    },
    domes: [
      {
        id: 1,
        order: 1,
        title: "Image 1",
        image: "013AitwxjPKA",
        viewDirection: {
          x: 9.70721928074764,
          y: -0.030834791589063063,
          z: 2.3515718820729945,
        },
        hotspots: [
          {
            id: 1,
            order: 1,
            type: "portal",
            domeId: 2,
            position: {
              x: 5.051207419821959,
              y: -0.5999999999999996,
              z: 1.2460173106743186,
            },
            size: 0.4,
            portalType: "Floor",
            label: "Neste rom",
          },
        ],
      },
      {
        id: 2,
        order: 2,
        title: "Image 2",
        image: "013AitwxjieF",
        viewDirection: {
          x: 9.976490453126612,
          y: -0.23559015248815685,
          z: 0.27196792964721633,
        },
        hotspots: [
          {
            id: 1,
            type: "text",
            position: {
              x: 8.178706544876093,
              y: 0.6807250896293192,
              z: 5.692477868354941,
            },
            title: "Om Linderud",
            width: 350,
            content: {
              description:
                "Linderud gård er i dag et museum åpent for publikum med omvisninger, arrangementer og konserter både i hovedbygning og hage. Navnet Linderud oppstod antakelig i tidlig middelalder. Rud betyr rydning. Etter Svartedauden i 1349 lå Linderud øde. På 1500-tallet var det gårdsdrift igjen og gården ble overtatt av kirken. Den var del av det kirkegodset som kongen senere overtok ved reformasjonen i 1536. På denne tiden ble gården drevet av leilendinger. De første eierne av Linderud var Mogens Lauritsøn 1646-1726 og Anne Bentsdatter 1654–1697 som bygslet Linderud fra 1671 og kjøpte gården i 1679.",
            },
          },
          {
            id: 2,
            type: "portal",
            domeId: 1,
            position: {
              x: -3.685996481035791,
              y: -0.6,
              z: -0.24287146850237581,
            },
            size: 0.4,
            portalType: "Floor",
          },
          {
            id: 3,
            type: "portal",
            domeId: 3,
            position: {
              x: 2.4399302529546474,
              y: -0.6,
              z: 0.21488590390460455,
            },
            size: 0.4,
            portalType: "Floor",
          },
        ],
      },
      {
        id: 3,
        order: 3,
        title: "Image 3",
        image: "013AitwxjieH",
        viewDirection: {
          x: 5.0382733224092,
          y: -0.043739133950379004,
          z: 8.633353169869638,
        },
        hotspots: [
          {
            id: 1,
            type: "portal",
            domeId: 2,
            position: {
              x: -1.5711068248765663,
              y: -0.6000000000000005,
              z: -2.151952070796165,
            },
            size: 0.4,
            portalType: "Floor",
          },
          {
            id: 2,
            type: "portal",
            domeId: 4,
            position: {
              x: 1.322160836242331,
              y: -0.6000000000000002,
              z: -1.0606899123986349,
            },
            size: 0.4,
            portalType: "Floor",
          },
        ],
      },
      {
        id: 4,
        order: 4,
        title: "Image 4",
        image: "013AitwxjieL",
        viewDirection: {
          x: 2.837340589234415,
          y: 0.7551694429573969,
          z: 9.547635006902206,
        },
        hotspots: [
          {
            id: 1,
            type: "text",
            position: {
              x: 9.93534258599453,
              y: -0.4910308991434634,
              z: 0.8656130204348598,
            },
            width: 180,
            content: {
              description:
                "Gallauniformen til statsråd Christian Pierre Mathiesen.",
            },
          },
          {
            id: 2,
            type: "text",
            position: {
              x: 6.486442518872379,
              y: -0.5498839042886007,
              z: -7.569581284377586,
            },
            title: "Lorem 1",
            width: 235,
            content: {
              description: "Gulvur signert John Meredith 1750.",
            },
          },
          {
            id: 3,
            type: "portal",
            domeId: 3,
            position: {
              x: -0.8844827600672377,
              y: -0.6000000000000001,
              z: -0.2382149790704656,
            },
            size: 0.4,
            portalType: "Floor",
          },
          {
            id: 4,
            type: "portal",
            domeId: 5,
            position: {
              x: 2.0731942374453967,
              y: -0.6000000000000002,
              z: -1.3468222093231257,
            },
            size: 0.4,
            portalType: "Floor",
          },
        ],
      },
      {
        id: 5,
        order: 5,
        title: "Image 5",
        image: "013Aitwxjiic",
        viewDirection: {
          x: 3.2353556422565375,
          y: 0.2558903290474496,
          z: 9.444078746871357,
        },
        hotspots: [
          {
            id: 1,
            type: "image",
            position: {
              x: -0.2012660967598099,
              y: -0.3406044035452444,
              z: 9.976069764801013,
            },
            width: 300,
            content: {
              image: "0138Yu3iUe3A",
            },
          },
          {
            id: 2,
            type: "image",
            position: {
              x: 9.834658777259534,
              y: -0.47337471359409106,
              z: -1.624603567320994,
            },
            title: "Lorem 1",
            width: 300,
            content: {
              image: "0138Yu3iUe7R",
            },
          },
          {
            id: 3,
            type: "image",
            position: {
              x: 4.691027130405993,
              y: -1.4214403747568018,
              z: -8.698652648019824,
            },
            width: 400,
            content: {
              image: "0138Yu3iUe7S",
            },
          },
          {
            id: 4,
            type: "portal",
            domeId: 4,
            position: {
              x: -1.9410802020183457,
              y: -0.5999999999999998,
              z: 1.0055541769145364,
            },
            size: 0.4,
            portalType: "Floor",
          },
        ],
      },
    ],
  },
  3: {
    domes: [
      {
        hotspots: [
          {
            domeId: 1,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 0.36011794571019273,
              y: -0.6000000000000008,
              z: -3.4606753600882194,
            },
            size: 0.4,
            type: "portal",
          },
          {
            domeId: 3,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: 3.29546844916227,
              y: -0.6,
              z: 0.08888050679337317,
            },
            size: 0.4,
            type: "portal",
          },
        ],
        id: 1,
        image: "013AmsgPUoUj",
        modified: true,
        order: 2,
        title: "Inngang.jpg",
        viewDirection: {
          x: 9.992554660326208,
          y: 0.13167970026668394,
          z: -0.04334565517701632,
        },
      },
      {
        hotspots: [
          {
            domeId: 1,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 0.36011794571019273,
              y: -0.6000000000000008,
              z: -3.4606753600882194,
            },
            size: 0.4,
            type: "portal",
          },
          {
            domeId: 3,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: 3.29546844916227,
              y: -0.6,
              z: 0.08888050679337317,
            },
            size: 0.4,
            type: "portal",
          },
        ],
        id: 2,
        image: "013AmsgPUoUk",
        modified: true,
        order: 1,
        title: "Inngang_2.jpg",
        viewDirection: {
          x: 9.992554660326208,
          y: 0.13167970026668394,
          z: -0.04334565517701632,
        },
      },
      {
        hotspots: [
          {
            domeId: 1,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: -2.0289239145628444,
              y: -0.6,
              z: 0.14005887262519257,
            },
            size: 0.3,
            type: "portal",
          },
          {
            domeId: 4,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: 0.9476421996426362,
              y: -0.6000000000000001,
              z: -0.38015507489170236,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 9,
            id: 3,
            order: 3,
            portalType: "Floor",
            position: {
              x: 1.4589229875172112,
              y: -0.6000000000000002,
              z: -0.8074862506912805,
            },
            size: 0.1,
            type: "portal",
          },
        ],
        id: 3,
        image: "013AmsgPUoZ2",
        modified: true,
        order: 3,
        title: "Gang_1_1.jpg",
        viewDirection: {
          x: 9.891806188563287,
          y: -0.962416487981555,
          z: -1.0513743298749936,
        },
      },
      {
        hotspots: [
          {
            domeId: 6,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 1.4255090028828894,
              y: -0.5999999999999998,
              z: 0.6373656167319953,
            },
            size: 0.2,
            type: "portal",
          },
          {
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: -0.18290903616096643,
              y: -0.5999999999999998,
              z: 0.9288678194267528,
            },
            size: 0,
            type: "portal",
          },
          {
            domeId: 3,
            id: 3,
            order: 3,
            portalType: "Floor",
            position: {
              x: -0.9993339334890874,
              y: -0.5999999999999999,
              z: 0.34974380487473866,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 10,
            id: 4,
            order: 4,
            portalType: "Floor",
            position: {
              x: -0.189439173502889,
              y: -0.5999999999999999,
              z: 0.9496773738207149,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 5,
            id: 5,
            order: 5,
            portalType: "Floor",
            position: {
              x: 0.7727398426632879,
              y: -0.6,
              z: 0.2962034325794528,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 9,
            id: 6,
            order: 6,
            portalType: "Floor",
            position: {
              x: 0.5877732146221595,
              y: -0.6000000000000001,
              z: -0.19206601492834327,
            },
            size: 0.1,
            type: "portal",
          },
        ],
        id: 4,
        image: "013AmsgPUoZ4",
        modified: true,
        order: 4,
        title: "Rom1_5.jpg",
        viewDirection: {
          x: 6.90784925274314,
          y: -0.6137089394087537,
          z: 7.1808515842807354,
        },
      },
      {
        hotspots: [
          {
            domeId: 11,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 0.16194097842503277,
              y: -0.6000000000000001,
              z: -0.7476876138015184,
            },
            size: 0.2,
            type: "portal",
          },
        ],
        id: 9,
        image: "013AmsgPUodU",
        modified: true,
        order: 5,
        title: "Rom1_6.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [
          {
            domeId: 6,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 0.7033018103982012,
              y: -0.6,
              z: 0.22737510677274278,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 9,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: -0.32238824017206413,
              y: -0.6000000000000001,
              z: -0.6375267600258668,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 4,
            id: 3,
            order: 3,
            portalType: "Floor",
            position: {
              x: -0.8970906359940075,
              y: -0.6,
              z: -0.21523972202779573,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 3,
            id: 4,
            order: 4,
            portalType: "Floor",
            position: {
              x: -1.7180692692588684,
              y: -0.5999999999999999,
              z: 0.024084197602063453,
            },
            size: 0.1,
            type: "portal",
          },
        ],
        id: 5,
        image: "013AmsgPUoZ8",
        modified: true,
        order: 6,
        title: "Rom1_2.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [
          {
            domeId: 6,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 0.7683614109306468,
              y: -0.6,
              z: 0.6565761002599203,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 3,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: -1.5656387409577406,
              y: -0.6,
              z: 0.21549198926962657,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 9,
            id: 3,
            order: 3,
            portalType: "Floor",
            position: {
              x: -0.11936649613299684,
              y: -0.6000000000000001,
              z: -0.39038437738592374,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 4,
            id: 4,
            order: 4,
            portalType: "Floor",
            position: {
              x: -0.7889854986169587,
              y: -0.6,
              z: -0.016232431152421917,
            },
            size: 0.1,
            type: "portal",
          },
        ],
        id: 7,
        image: "013AmsgPUodR",
        modified: true,
        order: 7,
        title: "Rom1_4.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [
          {
            domeId: 4,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: -1.4829292709379205,
              y: -0.6000000000000001,
              z: -0.480987008271275,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 9,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: -1.0069788266528341,
              y: -0.6000000000000002,
              z: -0.8324627322853474,
            },
            size: 0.1,
            type: "portal",
          },
          {
            domeId: 7,
            id: 3,
            order: 3,
            portalType: "Floor",
            position: {
              x: -0.7965429602656271,
              y: -0.6,
              z: -0.34564058518620244,
            },
            size: 0.1,
            type: "portal",
          },
        ],
        id: 6,
        image: "013AmsgPUodT",
        modified: true,
        order: 8,
        title: "Rom1_3.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [
          {
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 0.12816171326292192,
              y: -0.6000000000000002,
              z: -0.9008628818571329,
            },
            size: 0.2,
            type: "portal",
          },
        ],
        id: 10,
        image: "013AmsgPUoZ3",
        modified: true,
        order: 9,
        title: "Rom1_1.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 8,
        image: "013AmsgPUodS",
        modified: true,
        order: 10,
        title: "Rom1_7.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 11,
        image: "013AmsnuVxn7",
        order: 11,
        title: "Rom2_1.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 12,
        image: "013AmsnuVxvv",
        order: 12,
        title: "Rom2_2.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 13,
        image: "013AmsnuVxrZ",
        order: 13,
        title: "Rom2_3.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 14,
        image: "013AmsnuVxvw",
        order: 14,
        title: "Rom2_4.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 15,
        image: "013AmsnuVxn9",
        order: 15,
        title: "Rom2_5.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 16,
        image: "013AmsnuVxvt",
        order: 16,
        title: "Rom2_6.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 17,
        image: "013AmsnuVxvx",
        order: 17,
        title: "Rom2_7.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 18,
        image: "013AmsnuVxnB",
        order: 18,
        title: "Rom2_8.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 19,
        image: "013AmsnuVxrc",
        order: 19,
        title: "Rom2_9.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 20,
        image: "013AmsnuVxrb",
        order: 20,
        title: "Rom2_10.jpg",
        viewDirection: {
          x: 0,
          y: 0,
          z: 9.9,
        },
      },
      {
        hotspots: [],
        id: 21,
        image: "013AmsnuVxvs",
        order: 21,
        title: "Rom2_11.jpg",
        viewDirection: {
          x: 9.851296458699828,
          y: -1.4369130472567413,
          z: 0.7760204059527211,
        },
      },
      {
        hotspots: [
          {
            domeId: 23,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: 0.463826097432751,
              y: -0.6,
              z: 0.05818600057319627,
            },
            size: 0.2,
            type: "portal",
          },
          {
            domeId: 25,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: 0.8959166085047015,
              y: -0.6,
              z: 0.07815217440191068,
            },
            size: 0.2,
            type: "portal",
          },
        ],
        id: 22,
        image: "013AmsnuVxw2",
        order: 22,
        title: "Bad_1.jpg",
        viewDirection: {
          x: 9.945733256032254,
          y: -0.642460761158295,
          z: -0.5538200290672077,
        },
      },
      {
        hotspots: [
          {
            domeId: 24,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: -0.14401035486752287,
              y: -0.5999999999999999,
              z: 0.4343873234279265,
            },
            size: 0.2,
            type: "portal",
          },
          {
            domeId: 22,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: -0.520298868574897,
              y: -0.5999999999999999,
              z: -0.12532374067600044,
            },
            size: 0.2,
            type: "portal",
          },
          {
            domeId: 25,
            id: 3,
            order: 3,
            portalType: "Floor",
            position: {
              x: 0.5552568656828002,
              y: -0.6,
              z: 0.05600613179923525,
            },
            size: 0.2,
            type: "portal",
          },
        ],
        id: 23,
        image: "013AmsnuVy1J",
        order: 23,
        title: "Bad_2.jpg",
        viewDirection: {
          x: -1.7906162600516984,
          y: -1.0369618453918545,
          z: 9.765800756707474,
        },
      },
      {
        hotspots: [
          {
            domeId: 23,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: -0.4190194357056401,
              y: -0.6000000000000002,
              z: -0.11049919874976052,
            },
            size: 0.2,
            type: "portal",
          },
        ],
        id: 24,
        image: "013AmsnuVxw1",
        order: 24,
        title: "Bad_3.jpg",
        viewDirection: {
          x: 4.754180006360289,
          y: -0.9550164649063582,
          z: 8.728347789136823,
        },
      },
      {
        hotspots: [
          {
            domeId: 23,
            id: 1,
            order: 1,
            portalType: "Floor",
            position: {
              x: -0.010737054774375976,
              y: -0.6000000000000001,
              z: -0.5954644130073624,
            },
            size: 0.2,
            type: "portal",
          },
          {
            domeId: 22,
            id: 2,
            order: 2,
            portalType: "Floor",
            position: {
              x: 0.05505696282995454,
              y: -0.6000000000000002,
              z: -1.1709686801042203,
            },
            size: 0.2,
            type: "portal",
          },
        ],
        id: 25,
        image: "013AmsnuVy1H",
        order: 25,
        title: "Bad_4.jpg",
        viewDirection: {
          x: 5.55120624887221,
          y: -2.1154011333686022,
          z: 8.025179649098545,
        },
      },
    ],
    settings: {
      colors: [
        {
          key: 0,
          value: null,
        },
        {
          key: 1,
          value: null,
        },
        {
          key: 2,
          value: null,
        },
        {
          key: 3,
          value: null,
        },
      ],
    },
  },
  4: {
    settings: {
      colors: [],
    },
  },
};
